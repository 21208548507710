<template>
  <div class="tab-wrap">
    <div class="loading-wrap" v-show="showLoading">
      <a-spin size="large" />
    </div>
    <div v-show="!showLoading">
      <div class="classHour-top">
        <div>
          <a-radio-group default-value="CHINESE" @change="changeSubject">
            <a-radio-button value="CHINESE">中文</a-radio-button>
            <a-radio-button value="MATH">数学</a-radio-button>
            <a-radio-button value="ENGLISH">英文</a-radio-button>
          </a-radio-group>
        </div>
        <div>
          <a-button type="primary" style="margin-right: 10px" ghost @click="editStudyPlan"> 学习规划 </a-button>
          <a-button type="primary" style="margin-right: 10px" ghost @click="goHistoryPlan"> 历史规划 </a-button>
          <a-button type="primary" ghost @click="editDataFun"> 编辑 </a-button>
        </div>
      </div>
      <div v-if="showData" v-show="subject === 'CHINESE' && !showEditData">
        <div class="sub-title">当前学情</div>
        <a-descriptions class="descriptions-wrap" title="中文-课程进度">
          <a-descriptions-item label="主修课班型">
            {{ studentDetail.stuProfile.situationExpectData.CHINESE.currentSchedule.classTypeLabel || '-' }}
          </a-descriptions-item>
          <!-- <a-descriptions-item label="周消耗课时">
            <span>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>周消耗课时=过去28天已完成课时/4</span>
                </template>
                <i class="explain"></i>
              </a-tooltip>
            </span>
            {{ studentDetail.stuProfile.situationExpectData.CHINESE.currentSchedule.weekCourseUnite || '-' }}
          </a-descriptions-item> -->
          <a-descriptions-item label="主修课班级名称">
            {{ studentDetail.stuProfile.situationExpectData.CHINESE.currentSchedule.className || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="上课时长">
            {{ studentDetail.stuProfile.situationExpectData.CHINESE.currentSchedule.duration || '-' }} min
          </a-descriptions-item>
          <a-descriptions-item label="当前级别">
            {{ studentDetail.stuProfile.situationExpectData.CHINESE.currentSchedule.sectionData.level1 || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="最近完成的主修课">
            <span>{{ studentDetail.stuProfile.situationExpectData.CHINESE.currentSchedule.scheduleName || '-' }}</span>
            <span v-if="studentDetail.stuProfile.situationExpectData.CHINESE.currentSchedule.sectionNameStudent">{{
              `(${studentDetail.stuProfile.situationExpectData.CHINESE.currentSchedule.sectionNameStudent})`
            }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="Cognia进度">
            <a-icon style="color: #04cb94; margin-top: 4px" type="file-text" @click="handleOpenCognia" />
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions class="descriptions-wrap" title="中文-教学期望">
          <a-descriptions-item label="听说">
            {{ studentDetail.stuProfile.situationExpectData.CHINESE.listeningSpeaking || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="读">
            {{ studentDetail.stuProfile.situationExpectData.CHINESE.reading || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="写">
            {{ studentDetail.stuProfile.situationExpectData.CHINESE.writing || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="课程进度">
            {{ studentDetail.stuProfile.situationExpectData.CHINESE.courseProcess || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="作业难度">
            {{ studentDetail.stuProfile.situationExpectData.CHINESE.homeworkDifficulty || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="作业量">
            {{ studentDetail.stuProfile.situationExpectData.CHINESE.homeworkVolume || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="其他/特殊需求">
            {{ studentDetail.stuProfile.situationExpectData.CHINESE.specialNeeds || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="作业偏好">
            {{
              (studentDetail.stuProfile.situationExpectData.CHINESE.homeworkPrefer &&
                initOptionsType(
                  studentDetail.stuProfile.situationExpectData.CHINESE.homeworkPrefer,
                  homeworkPreferArr,
                )) ||
              '-'
            }}
          </a-descriptions-item>
          <a-descriptions-item label="代课偏好">
            {{
              initOptionsType(
                studentDetail.stuProfile.situationExpectData.CHINESE.substitutePrefer || 'ACCEPT_SUBSTITUTE_TEACHING',
                substitutePreferArr,
              ) || '-'
            }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions class="descriptions-wrap" title="中文-其他学情">
          <a-descriptions-item label="授课语言">
            {{ studentDetail.stuProfile.situationExpectData.CHINESE.teachingLanguage || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="教学互动">
            {{ studentDetail.stuProfile.situationExpectData.CHINESE.interaction || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="作业完成度">
            {{ studentDetail.stuProfile.situationExpectData.CHINESE.homewordCompletion || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="行为习惯">
            {{ studentDetail.stuProfile.situationExpectData.CHINESE.behaviorHabit || '-' }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions class="descriptions-wrap" title="中文-排课信息">
          <a-descriptions-item label="入学时间（北京时间）">
            {{
              (studentDetail.data.entranceDate &&
                $moment(studentDetail.data.entranceDate).format('YYYY年MM月DD日 HH:mm')) ||
              '-'
            }}
          </a-descriptions-item>
          <a-descriptions-item label="首课时间（北京时间）">
            {{
              (studentDetail.data.firstLessonDate_CHINESE &&
                $moment(studentDetail.data.firstLessonDate_CHINESE).format('YYYY年MM月DD日 HH:mm')) ||
              '-'
            }}
          </a-descriptions-item>
          <a-descriptions-item label="补升权益">
            {{ studentDetail.data.levelUpText_CHINESE || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="阅读课（北京时间）">
            {{ studentDetail.data.readingClass || '-' }}
          </a-descriptions-item>
        </a-descriptions>
        <div class="sub-title">入学学情</div>
        <a-descriptions class="descriptions-wrap" title="中文-体验课信息">
          <a-descriptions-item
            :label="`体验课名称${index + 1}`"
            :key="index"
            v-for="(item, index) in studentDetail.stuProfile.situationExpectData.CHINESE.trialSchedules"
          >
            <span class="class-label" @click="lookExperience(item)">
              {{ item.scheduleName || '-' }}
              <span v-if="item.sectionNameStudent">{{ `(${item.sectionNameStudent})` }}</span>
            </span>
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions class="descriptions-wrap" title="中文-学习计划">
          <a-descriptions-item label="科目类型">
            {{ studentDetail.data.eduPlanInfo.CHINESE.subject || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="级别（Ⅰ）">
            {{ studentDetail.data.eduPlanInfo.CHINESE.level1 || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="级别（Ⅱ）">
            {{ studentDetail.data.eduPlanInfo.CHINESE.level2 || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="级别（Ⅲ）">
            {{ studentDetail.data.eduPlanInfo.CHINESE.level3 || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="班型">
            {{ studentDetail.data.eduPlanInfo.CHINESE.courseCategory || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="上课频率（每周）">
            {{ studentDetail.data.eduPlanInfo.CHINESE.courseRate || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="上课时长">
            {{ studentDetail.data.eduPlanInfo.CHINESE.courseDuration || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="入学教学期望">
            {{ studentDetail.data.eduPlanInfo.CHINESE.expectation || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="期望首课日期">
            {{ studentDetail.data.eduPlanInfo.CHINESE.classOpenTime || '-' }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions class="descriptions-wrap" title="中文-入学学情">
          <a-descriptions-item label="入学年龄">
            {{ studentDetail.data.entranceAge || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="拼音学习史">
            {{ studentDetail.data.entryCriteria.pinyinHistory || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="孩子中文水平">
            {{ studentDetail.data.entryCriteria.chineseLevel || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="家庭语言环境">
            {{ studentDetail.data.entryCriteria.familyLanguage || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="汉字书写预期">
            {{ studentDetail.data.entryCriteria.writingExpectation || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="国内中文学习史">
            {{ studentDetail.data.entryCriteria.chinaStudyHistory || '-' }}
          </a-descriptions-item>

          <a-descriptions-item label="海外中文学习史">
            {{ studentDetail.data.entryCriteria.overseasStudyHistory || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="曾用教材">
            {{ studentDetail.data.entryCriteria.textbookUsed || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="曾用教材学到第几册">
            {{ studentDetail.data.entryCriteria.textbookProgress || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="中文程度-补充说明">
            {{ studentDetail.data.entryCriteria.chineseAdditionalRemark || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="家长学习期待">
            {{ studentDetail.data.entryCriteria.parentsExpect || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="学生兴趣爱好">
            {{ studentDetail.data.entryCriteria.hobby || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="试学期优惠">
            <span v-if="studentDetail.data.entryCriteria.joinTrySemester === true">已参与</span>
            <span v-else-if="studentDetail.data.entryCriteria.joinTrySemester === false">未参与</span>
            <span v-else>-</span>
          </a-descriptions-item>
          <a-descriptions-item label="期待老师风格">
            {{ studentDetail.data.entryCriteria.teacherStyleExpect || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="学习目标"> {{ chineseStudyPurposeFormat }} </a-descriptions-item>
        </a-descriptions>
      </div>
      <div v-if="showData" v-show="subject === 'MATH' && !showEditData">
        <div class="sub-title">当前学情</div>
        <a-descriptions class="descriptions-wrap" title="数学-课程进度">
          <a-descriptions-item label="主修课班型">
            {{ studentDetail.stuProfile.situationExpectData.MATH.currentSchedule.classTypeLabel || '-' }}
          </a-descriptions-item>
          <!-- 
          <a-descriptions-item label="周消耗课时">
            <span>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>周消耗课时=过去28天已完成课时/4</span>
                </template>
                <i class="explain"></i>
              </a-tooltip>
            </span>
            {{ studentDetail.stuProfile.situationExpectData.MATH.currentSchedule.weekCourseUnite || '-' }}
          </a-descriptions-item> -->
          <a-descriptions-item label="主修课班级名称">
            {{ studentDetail.stuProfile.situationExpectData.MATH.currentSchedule.className || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="上课时长">
            {{ studentDetail.stuProfile.situationExpectData.MATH.currentSchedule.duration || '-' }} min
          </a-descriptions-item>
          <a-descriptions-item label="当前级别">
            {{ studentDetail.stuProfile.situationExpectData.MATH.currentSchedule.sectionData.level1 || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="最近完成的主修课">
            <span>{{ studentDetail.stuProfile.situationExpectData.MATH.currentSchedule.scheduleName || '-' }}</span>
            <span v-if="studentDetail.stuProfile.situationExpectData.MATH.currentSchedule.sectionNameStudent">{{
              `(${studentDetail.stuProfile.situationExpectData.MATH.currentSchedule.sectionNameStudent})`
            }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="Cognia进度">
            <a-icon style="color: #04cb94" type="file-text" @click="handleOpenCognia" />
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions class="descriptions-wrap" title="数学-教学期望">
          <a-descriptions-item label="数与代数">
            {{ studentDetail.stuProfile.situationExpectData.MATH.algebra || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="几何与测量">
            {{ studentDetail.stuProfile.situationExpectData.MATH.geometry || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="推理与策略">
            {{ studentDetail.stuProfile.situationExpectData.MATH.strategy || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="应用与实战">
            {{ studentDetail.stuProfile.situationExpectData.MATH.practice || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="统计与概率">
            {{ studentDetail.stuProfile.situationExpectData.MATH.statistics || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="课程进度">
            {{ studentDetail.stuProfile.situationExpectData.MATH.courseProcess || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="作业难度">
            {{ studentDetail.stuProfile.situationExpectData.MATH.homeworkDifficulty || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="作业量">
            {{ studentDetail.stuProfile.situationExpectData.MATH.homeworkVolume || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="其他/特殊需求">
            {{ studentDetail.stuProfile.situationExpectData.MATH.specialNeeds || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="作业偏好">
            {{
              (studentDetail.stuProfile.situationExpectData.MATH.homeworkPrefer &&
                initOptionsType(studentDetail.stuProfile.situationExpectData.MATH.homeworkPrefer, homeworkPreferArr)) ||
              '-'
            }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions class="descriptions-wrap" title="数学-其他学情">
          <a-descriptions-item label="教学互动">
            {{ studentDetail.stuProfile.situationExpectData.MATH.interaction || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="作业完成度">
            {{ studentDetail.stuProfile.situationExpectData.MATH.homewordCompletion || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="行为习惯">
            {{ studentDetail.stuProfile.situationExpectData.MATH.behaviorHabit || '-' }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions class="descriptions-wrap" title="数学-排课信息">
          <a-descriptions-item label="入学时间（北京时间）">
            {{
              (studentDetail.data.mathEntranceDate &&
                $moment(studentDetail.data.mathEntranceDate).format('YYYY年MM月DD日 HH:mm')) ||
              '-'
            }}
          </a-descriptions-item>
          <a-descriptions-item label="首课时间（北京时间）">
            {{
              (studentDetail.data.firstLessonDate_MATH &&
                $moment(studentDetail.data.firstLessonDate_MATH).format('YYYY年MM月DD日 HH:mm')) ||
              '-'
            }}
          </a-descriptions-item>
          <a-descriptions-item label="补升权益">
            {{ studentDetail.data.levelUpText_MATH || '-' }}
          </a-descriptions-item>
        </a-descriptions>
        <div class="sub-title">入学学情</div>
        <a-descriptions class="descriptions-wrap" title="数学-体验课信息">
          <a-descriptions-item
            :label="`体验课名称${index + 1}`"
            :key="index"
            v-for="(item, index) in studentDetail.stuProfile.situationExpectData.MATH.trialSchedules"
          >
            <span class="class-label" @click="lookExperience(item)">
              {{ item.scheduleName || '-' }}
              <span v-if="item.sectionNameStudent">{{ `(${item.sectionNameStudent})` }}</span>
            </span>
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions class="descriptions-wrap" title="数学-学习计划">
          <a-descriptions-item label="科目类型">
            {{ studentDetail.data.eduPlanInfo.MATH.subject || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="级别（Ⅰ）">
            {{ studentDetail.data.eduPlanInfo.MATH.level1 || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="级别（Ⅱ）">
            {{ studentDetail.data.eduPlanInfo.MATH.level2 || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="级别（Ⅲ）">
            {{ studentDetail.data.eduPlanInfo.MATH.level3 || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="入学班次">
            {{ studentDetail.data.eduPlanInfo.MATH.admissionShift || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="班型">
            {{ studentDetail.data.eduPlanInfo.MATH.courseCategory || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="上课频率（每周）">
            {{ studentDetail.data.eduPlanInfo.MATH.courseRate || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="上课时长">
            {{ studentDetail.data.eduPlanInfo.MATH.courseDuration || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="入学教学期望">
            {{ studentDetail.data.eduPlanInfo.MATH.expectation || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="期望首课日期">
            {{ studentDetail.data.eduPlanInfo.MATH.classOpenTime || '-' }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions class="descriptions-wrap" title="数学-入学学情">
          <a-descriptions-item label="测试级别">
            {{ studentDetail.data.entryCriteria.testLevel || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="分数">
            {{ studentDetail.data.entryCriteria.score || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="入学在读年级">
            {{ studentDetail.data.entryCriteria.grade || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="入学年龄">
            {{ studentDetail.data.mathEntranceAge || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="孩子数学水平">
            {{ studentDetail.data.entryCriteria.mathLevel || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="参与有数学辅导班">
            <span v-if="studentDetail.data.entryCriteria.mathTutoringClass === true">有</span>
            <span v-else-if="studentDetail.data.entryCriteria.mathTutoringClass === false">无</span>
            <span v-else>-</span>
          </a-descriptions-item>
          <a-descriptions-item label="参与有其他兴趣班">
            <span v-if="studentDetail.data.entryCriteria.otherinterestClass === true">有</span>
            <span v-else-if="studentDetail.data.entryCriteria.otherinterestClass === false">无</span>
            <span v-else>-</span>
          </a-descriptions-item>
          <a-descriptions-item label="试学期优惠">
            <span v-if="studentDetail.data.entryCriteria.mathJoinTrySemester === true">已参与</span>
            <span v-else-if="studentDetail.data.entryCriteria.mathJoinTrySemester === false">未参与</span>
            <span v-else>-</span>
          </a-descriptions-item>
          <a-descriptions-item label="家长学习期待">
            {{ studentDetail.data.entryCriteria.mathParentsExpect || '-' }}
          </a-descriptions-item>
          <!-- <a-descriptions-item label="期待老师风格">
            {{ studentDetail.data.mathTeacherStyleExpect  && studentDetail.data.mathTeacherStyleExpect.join(",") || '-' }}
          </a-descriptions-item> -->
        </a-descriptions>
      </div>
      <div v-if="showData" v-show="subject === 'ENGLISH' && !showEditData">
        <div class="sub-title">当前学情</div>
        <a-descriptions class="descriptions-wrap" title="英文-课程进度">
          <a-descriptions-item label="主修课班型">
            {{ studentDetail.stuProfile.situationExpectData.ENGLISH.currentSchedule.classTypeLabel || '-' }}
          </a-descriptions-item>

          <!-- <a-descriptions-item label="周消耗课时">
            <span>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>周消耗课时=过去28天已完成课时/4</span>
                </template>
                <i class="explain"></i>
              </a-tooltip>
            </span>
            {{ studentDetail.stuProfile.situationExpectData.ENGLISH.currentSchedule.weekCourseUnite || '-' }}
          </a-descriptions-item> -->
          <a-descriptions-item label="主修课班级名称">
            {{ studentDetail.stuProfile.situationExpectData.ENGLISH.currentSchedule.className || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="上课时长">
            {{ studentDetail.stuProfile.situationExpectData.ENGLISH.currentSchedule.duration || '-' }} min
          </a-descriptions-item>
          <a-descriptions-item label="当前级别">
            {{ studentDetail.stuProfile.situationExpectData.ENGLISH.currentSchedule.sectionData.level1 || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="最近完成的主修课">
            <span>{{ studentDetail.stuProfile.situationExpectData.ENGLISH.currentSchedule.scheduleName || '-' }}</span>
            <span v-if="studentDetail.stuProfile.situationExpectData.ENGLISH.currentSchedule.sectionNameStudent">{{
              `(${studentDetail.stuProfile.situationExpectData.ENGLISH.currentSchedule.sectionNameStudent})`
            }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="Cognia进度">
            <a-icon style="color: #04cb94" type="file-text" @click="handleOpenCognia" />
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions class="descriptions-wrap" title="英文-教学期望"> </a-descriptions>
        <a-descriptions class="descriptions-wrap" title="英文-其他学情"> </a-descriptions>

        <a-descriptions class="descriptions-wrap" title="英文-排课信息">
          <a-descriptions-item label="入学时间（北京时间）">
            {{
              (studentDetail.data.englishEntranceDate &&
                $moment(studentDetail.data.englishEntranceDate).format('YYYY年MM月DD日 HH:mm')) ||
              '-'
            }}
          </a-descriptions-item>
          <a-descriptions-item label="首课时间（北京时间）">
            {{
              (studentDetail.data.firstLessonDate_ENGLISH &&
                $moment(studentDetail.data.firstLessonDate_ENGLISH).format('YYYY年MM月DD日 HH:mm')) ||
              '-'
            }}
          </a-descriptions-item>
          <a-descriptions-item label="补升权益">
            {{ studentDetail.data.levelUpText_ENGLISH || '-' }}
          </a-descriptions-item>
        </a-descriptions>
        <div class="sub-title">入学学情</div>
        <a-descriptions class="descriptions-wrap" title="英文-体验课信息">
          <a-descriptions-item
            :label="`体验课名称${index + 1}`"
            :key="index"
            v-for="(item, index) in studentDetail.stuProfile.situationExpectData.ENGLISH.trialSchedules"
          >
            <span class="class-label" @click="lookExperience(item)">
              {{ item.scheduleName || '-' }}
              <span v-if="item.sectionNameStudent">{{ `(${item.sectionNameStudent})` }}</span>
            </span>
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions class="descriptions-wrap" title="英文-学习计划">
          <a-descriptions-item label="科目类型">
            {{ studentDetail.data.eduPlanInfo.ENGLISH.subject || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="级别（Ⅰ）">
            {{ studentDetail.data.eduPlanInfo.ENGLISH.level1 || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="级别（Ⅱ）">
            {{ studentDetail.data.eduPlanInfo.ENGLISH.level2 || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="级别（Ⅲ）">
            {{ studentDetail.data.eduPlanInfo.ENGLISH.level3 || '-' }}
          </a-descriptions-item>

          <a-descriptions-item label="班型">
            {{ studentDetail.data.eduPlanInfo.ENGLISH.courseCategory || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="上课频率（每周）">
            {{ studentDetail.data.eduPlanInfo.ENGLISH.courseRate || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="上课时长">
            {{ studentDetail.data.eduPlanInfo.ENGLISH.courseDuration || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="入学教学期望">
            {{ studentDetail.data.eduPlanInfo.ENGLISH.expectation || '-' }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions class="descriptions-wrap" title="英文-入学学情">
          <a-descriptions-item label="入学在读年级">
            {{ studentDetail.data.entryCriteria.englishGrade || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="入学年龄">
            {{ studentDetail.data.englishEntranceAge || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="参与有英文辅导班">
            <span v-if="studentDetail.data.entryCriteria.englishTutoringClass === true">有</span>
            <span v-else-if="studentDetail.data.entryCriteria.englishTutoringClass === false">无</span>
            <span v-else>-</span>
          </a-descriptions-item>
          <a-descriptions-item label="参与有其他兴趣班">
            <span v-if="studentDetail.data.entryCriteria.englishOtherinterestClass === true">有</span>
            <span v-else-if="studentDetail.data.entryCriteria.englishOtherinterestClass === false">无</span>
            <span v-else>-</span>
          </a-descriptions-item>
          <a-descriptions-item label="试学期优惠">
            <span v-if="studentDetail.data.entryCriteria.englishJoinTrySemester === true">已参与</span>
            <span v-else-if="studentDetail.data.entryCriteria.englishJoinTrySemester === false">未参与</span>
            <span v-else>-</span>
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <div v-show="showEditData">
        <a-form class="ant-advanced-search-form" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" :form="form">
          <div class="sub-title">当前学情</div>
          <template v-if="subject === 'CHINESE'">
            <InfoTitle title="中文-教学期望" />
            <a-row :gutter="24">
              <a-col :span="8">
                <a-form-item label="听说">
                  <a-select
                    placeholder="请选择"
                    allowClear
                    v-decorator="['stuProfile.situationExpectData.CHINESE.listeningSpeaking', {}]"
                  >
                    <a-select-option value="希望加强练习">希望加强练习</a-select-option>
                    <a-select-option value="保持不变">保持不变</a-select-option>
                    <a-select-option value="希望适当减少">希望适当减少</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="读">
                  <a-select
                    placeholder="请选择"
                    allowClear
                    v-decorator="['stuProfile.situationExpectData.CHINESE.reading', {}]"
                  >
                    <a-select-option value="希望加强练习">希望加强练习</a-select-option>
                    <a-select-option value="保持不变">保持不变</a-select-option>
                    <a-select-option value="希望适当减少">希望适当减少</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="写">
                  <a-select
                    placeholder="请选择"
                    allowClear
                    v-decorator="['stuProfile.situationExpectData.CHINESE.writing', {}]"
                  >
                    <a-select-option value="希望加强练习">希望加强练习</a-select-option>
                    <a-select-option value="保持不变">保持不变</a-select-option>
                    <a-select-option value="希望适当减少">希望适当减少</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="课程进度">
                  <a-select
                    placeholder="请选择"
                    allowClear
                    v-decorator="['stuProfile.situationExpectData.CHINESE.courseProcess', {}]"
                  >
                    <a-select-option value="希望快一点">希望快一点</a-select-option>
                    <a-select-option value="保持不变">保持不变</a-select-option>
                    <a-select-option value="希望慢一点">希望慢一点</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="作业难度">
                  <a-select
                    placeholder="请选择"
                    allowClear
                    v-decorator="['stuProfile.situationExpectData.CHINESE.homeworkDifficulty', {}]"
                  >
                    <a-select-option value="希望难一点">希望难一点</a-select-option>
                    <a-select-option value="保持不变">保持不变</a-select-option>
                    <a-select-option value="希望简单一点">希望简单一点</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="作业量">
                  <a-select
                    placeholder="请选择"
                    allowClear
                    v-decorator="['stuProfile.situationExpectData.CHINESE.homeworkVolume', {}]"
                  >
                    <a-select-option value="希望加强练习">希望少一点</a-select-option>
                    <a-select-option value="保持不变">保持不变</a-select-option>
                    <a-select-option value="希望多一点">希望多一点</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="其他/特殊需求">
                  <a-textarea v-decorator="['stuProfile.situationExpectData.CHINESE.specialNeeds', {}]" />
                </a-form-item>
              </a-col>
              <!-- <a-col :span="24">
              <div style="padding-left: 26px">
                <a-form-item label="其他/特殊需求" :labelCol="{ span: 2, offset: 0 }">
                  <a-input
                    allowClear
                    style="padding-left: 5px"
                    placeholder="请填写"
                    v-decorator="['stuProfile.situationExpectData.CHINESE.specialNeeds', {}]"
                  />
                </a-form-item>
              </div>
            </a-col> -->
              <a-col :span="8">
                <a-form-item label="作业偏好">
                  <a-select
                    placeholder="请选择"
                    allowClear
                    v-decorator="['stuProfile.situationExpectData.CHINESE.homeworkPrefer', {}]"
                  >
                    <a-select-option v-for="(item, index) in homeworkPreferArr" :value="item.value" :key="index">{{
                      item.label
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="代课偏好">
                  <a-select
                    placeholder="请选择"
                    allowClear
                    v-decorator="['stuProfile.situationExpectData.CHINESE.substitutePrefer', {}]"
                  >
                    <a-select-option v-for="(item, index) in substitutePreferArr" :value="item.value" :key="index">{{
                      item.label
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <InfoTitle title="中文-其他学情" />
            <a-row :gutter="24">
              <a-col :span="8">
                <a-form-item label="授课语言">
                  <a-select
                    placeholder="请选择"
                    allowClear
                    v-decorator="['stuProfile.situationExpectData.CHINESE.teachingLanguage', {}]"
                  >
                    <a-select-option value="中文普通话">中文普通话</a-select-option>
                    <a-select-option value="英语">英语</a-select-option>
                    <a-select-option value="日语">日语</a-select-option>
                    <a-select-option value="法语">法语</a-select-option>
                    <a-select-option value="德语">德语</a-select-option>
                    <a-select-option value="俄语">俄语</a-select-option>
                    <a-select-option value="韩语">韩语</a-select-option>
                    <a-select-option value="粤语">粤语</a-select-option>
                    <a-select-option value="泰语">泰语</a-select-option>
                    <a-select-option value="印尼语">印尼语</a-select-option>
                    <a-select-option value="西班牙语">西班牙语</a-select-option>
                    <a-select-option value="新加坡英语">新加坡英语</a-select-option>
                    <a-select-option value="阿拉伯语">阿拉伯语</a-select-option>
                    <a-select-option value="其他">其他</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="教学互动">
                  <a-select
                    placeholder="请选择"
                    allowClear
                    v-decorator="['stuProfile.situationExpectData.CHINESE.interaction', {}]"
                  >
                    <a-select-option value="高，乐于表达">高，乐于表达</a-select-option>
                    <a-select-option value="中，需要老师引导">中，需要老师引导</a-select-option>
                    <a-select-option value="低于50%，需关注">低，基本不参与互动</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="作业完成度">
                  <a-select
                    placeholder="请选择"
                    allowClear
                    v-decorator="['stuProfile.situationExpectData.CHINESE.homewordCompletion', {}]"
                  >
                    <a-select-option value="超80%完成，优秀">超80%完成，优秀</a-select-option>
                    <a-select-option value="80%～50%完成，良好">80%～50%完成，良好</a-select-option>
                    <a-select-option value="低于50%，需关注">低于50%，需关注</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="行为习惯">
                  <a-select
                    placeholder="请选择"
                    allowClear
                    v-decorator="['stuProfile.situationExpectData.CHINESE.behaviorHabit', {}]"
                  >
                    <a-select-option value="准时出席">准时出席</a-select-option>
                    <a-select-option value="偶尔缺席/迟到">偶尔缺席/迟到</a-select-option>
                    <a-select-option value="高频缺席/迟到">高频缺席/迟到</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <InfoTitle title="中文-排课信息" />
            <a-row :gutter="24">
              <a-col :span="8">
                <a-form-item label="入学时间(北京时间)">
                  <a-date-picker
                    format="YYYY-MM-DD HH:mm"
                    disabled
                    :show-time="{ defaultValue: $moment('00:00', 'HH:mm'), format: 'HH:mm' }"
                    style="width: 100%"
                    v-decorator="['data.entranceDate', {}]"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item label="补升权益">
                  <a-input placeholder="请填写" v-decorator="['data.levelUpText_CHINESE', {}]" disabled />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="首课时间(北京时间)">
                  <a-date-picker
                    disabled
                    style="width: 100%"
                    v-decorator="['data.firstLessonDate_CHINESE', {}]"
                    format="YYYY-MM-DD HH:mm"
                  />
                  <!-- {{chineseFirstLessonDate}} -->
                  <!-- <a-icon type="edit" v-show="showChineseFirstLesson"  @click="onEditFirstLessonDate('CHINESE')" /> -->
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="阅读课(北京时间)">
                  <a-select placeholder="请选择" disabled allowClear v-decorator="['data.readingClass', {}]">
                    <a-select-option value="阅读A班-北京时间周六08:00">阅读A班-北京时间周六08:00</a-select-option>
                    <a-select-option value="阅读B班-北京时间周六16:00">阅读B班-北京时间周六16:00</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <div class="sub-title">入学学情</div>
            <InfoTitle title="中文-入学学情" />
            <a-row :gutter="24">
              <a-col :span="8">
                <a-form-item label="入学年龄">
                  <a-input placeholder="请填写" v-decorator="['data.entranceAge', {}]" disabled />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="拼音学习史">
                  <a-select
                    show-search
                    placeholder="请选择"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    allowClear
                    v-decorator="['data.entryCriteria.pinyinHistory', {}]"
                  >
                    <a-select-option :value="i" v-for="i of pinyinHistory" :key="i">
                      {{ i }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="家庭语言环境">
                  <a-select
                    show-search
                    placeholder="请选择"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    allowClear
                    v-decorator="['data.entryCriteria.familyLanguage', {}]"
                  >
                    <a-select-option :value="i" v-for="i of familyLanguage" :key="i">
                      {{ i }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="孩子中文水平">
                  <a-select
                    show-search
                    placeholder="请选择"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    allowClear
                    v-decorator="['data.entryCriteria.chineseLevel', {}]"
                  >
                    <a-select-option :value="i" v-for="i of chineseLevel" :key="i">
                      {{ i }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="汉字书写预期">
                  <a-input v-decorator="['data.entryCriteria.writingExpectation', {}]" placeholder="请填写" />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="国内中文学习史">
                  <a-select
                    show-search
                    placeholder="请选择"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    allowClear
                    v-decorator="['data.entryCriteria.chinaStudyHistory', {}]"
                  >
                    <a-select-option :value="i" v-for="i of chinaStudyHistory" :key="i">
                      {{ i }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item label="海外中文学习史">
                  <a-select
                    show-search
                    placeholder="请选择"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    allowClear
                    v-decorator="['data.entryCriteria.overseasStudyHistory', {}]"
                  >
                    <a-select-option :value="i" v-for="i of overseasStudyHistory" :key="i">
                      {{ i }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="曾用教材">
                  <a-select
                    show-search
                    placeholder="请选择"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    allowClear
                    v-decorator="['data.entryCriteria.textbookUsed', {}]"
                  >
                    <a-select-option :value="i" v-for="i of textbookUsed" :key="i">
                      {{ i }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="曾用教材学到第几册">
                  <a-select
                    show-search
                    placeholder="请选择"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    allowClear
                    v-decorator="['data.entryCriteria.textbookProgress', {}]"
                  >
                    <a-select-option :value="i" v-for="i of textbookProgress" :key="i">
                      {{ i }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item label="中文程度-补充说明">
                  <a-textarea v-decorator="['data.entryCriteria.chineseAdditionalRemark', {}]" />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="家长学习期待">
                  <a-select
                    mode="multiple"
                    show-search
                    placeholder="请选择"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    allowClear
                    v-decorator="['data.entryCriteria.parentsExpect', {}]"
                  >
                    <a-select-option :value="i" v-for="i of parentsExpectArr" :key="i">
                      {{ i }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="学生兴趣爱好">
                  <a-textarea v-decorator="['data.entryCriteria.hobby', {}]" />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="试学期优惠">
                  <a-select
                    disabled
                    show-search
                    placeholder="请选择"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    allowClear
                    v-decorator="['data.entryCriteria.joinTrySemester', {}]"
                  >
                    <a-select-option :value="true">已参与</a-select-option>
                    <a-select-option :value="false">未参与</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="期待老师风格">
                  <a-select
                    v-decorator="['data.entryCriteria.teacherStyleExpect']"
                    placeholder="请选择"
                    mode="multiple"
                  >
                    <a-select-option v-for="item in teachingStylesOptions" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="学习目标">
                  <a-select v-decorator="['data.entryCriteria.studyPurpose']" placeholder="请选择" mode="multiple">
                    <a-select-option
                      v-for="[value, label] in Object.entries(StudyPurposeEnumText)"
                      :key="value"
                      :value="value"
                    >
                      {{ label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </template>

          <template v-if="subject === 'MATH'">
            <InfoTitle title="数学-教学期望" />
            <a-row :gutter="24">
              <a-col :span="8">
                <a-form-item label="数与代数">
                  <a-select
                    placeholder="请选择"
                    allowClear
                    v-decorator="['stuProfile.situationExpectData.MATH.algebra', {}]"
                  >
                    <a-select-option value="希望难一点">希望难一点</a-select-option>
                    <a-select-option value="保持不变">保持不变</a-select-option>
                    <a-select-option value="希望简单一点">希望简单一点</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="几何与测量">
                  <a-select
                    placeholder="请选择"
                    allowClear
                    v-decorator="['stuProfile.situationExpectData.MATH.geometry', {}]"
                  >
                    <a-select-option value="希望难一点">希望难一点</a-select-option>
                    <a-select-option value="保持不变">保持不变</a-select-option>
                    <a-select-option value="希望简单一点">希望简单一点</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="推理与策略">
                  <a-select
                    placeholder="请选择"
                    allowClear
                    v-decorator="['stuProfile.situationExpectData.MATH.strategy', {}]"
                  >
                    <a-select-option value="希望难一点">希望难一点</a-select-option>
                    <a-select-option value="保持不变">保持不变</a-select-option>
                    <a-select-option value="希望简单一点">希望简单一点</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="应用与实战">
                  <a-select
                    placeholder="请选择"
                    allowClear
                    v-decorator="['stuProfile.situationExpectData.MATH.practice', {}]"
                  >
                    <a-select-option value="希望难一点">希望难一点</a-select-option>
                    <a-select-option value="保持不变">保持不变</a-select-option>
                    <a-select-option value="希望简单一点">希望简单一点</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="统计与概率">
                  <a-select
                    placeholder="请选择"
                    allowClear
                    v-decorator="['stuProfile.situationExpectData.MATH.statistics', {}]"
                  >
                    <a-select-option value="希望难一点">希望难一点</a-select-option>
                    <a-select-option value="保持不变">保持不变</a-select-option>
                    <a-select-option value="希望简单一点">希望简单一点</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="课程进度">
                  <a-select
                    placeholder="请选择"
                    allowClear
                    v-decorator="['stuProfile.situationExpectData.MATH.courseProcess', {}]"
                  >
                    <a-select-option value="希望快一点">希望快一点</a-select-option>
                    <a-select-option value="保持不变">保持不变</a-select-option>
                    <a-select-option value="希望慢一点">希望慢一点</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="作业难度">
                  <a-select
                    placeholder="请选择"
                    allowClear
                    v-decorator="['stuProfile.situationExpectData.MATH.homeworkDifficulty', {}]"
                  >
                    <a-select-option value="希望难一点">希望难一点</a-select-option>
                    <a-select-option value="保持不变">保持不变</a-select-option>
                    <a-select-option value="希望简单一点">希望简单一点</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="作业量">
                  <a-select
                    placeholder="请选择"
                    allowClear
                    v-decorator="['stuProfile.situationExpectData.MATH.homeworkVolume', {}]"
                  >
                    <a-select-option value="希望加强练习">希望少一点</a-select-option>
                    <a-select-option value="保持不变">保持不变</a-select-option>
                    <a-select-option value="希望多一点">希望多一点</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <!-- <a-col :span="24">
              <div style="padding-left: 26px">
                <a-form-item label="其他/特殊需求" :labelCol="{ span: 2, offset: 0 }">
                  <a-input
                    allowClear
                    style="padding-left: 5px"
                    placeholder="请填写"
                    v-decorator="['stuProfile.situationExpectData.MATH.specialNeeds', {}]"
                  />
                </a-form-item>
              </div>
            </a-col> -->
              <a-col :span="8">
                <a-form-item label="其他/特殊需求">
                  <a-textarea v-decorator="['stuProfile.situationExpectData.MATH.specialNeeds', {}]" />
                </a-form-item>
              </a-col>
              <!-- <a-col :span="24">
              <div style="padding-left: 26px">
                <a-form-item label="其他/特殊需求" :labelCol="{ span: 2, offset: 0 }">
                  <a-input
                    allowClear
                    style="padding-left: 5px"
                    placeholder="请填写"
                    v-decorator="['stuProfile.situationExpectData.CHINESE.specialNeeds', {}]"
                  />
                </a-form-item>
              </div>
            </a-col> -->
              <a-col :span="8">
                <a-form-item label="作业偏好">
                  <a-select
                    placeholder="请选择"
                    allowClear
                    v-decorator="['stuProfile.situationExpectData.MATH.homeworkPrefer', {}]"
                  >
                    <a-select-option v-for="(item, index) in homeworkPreferArr" :value="item.value" :key="index">{{
                      item.label
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <InfoTitle title="数学-其他学情" />
            <a-row :gutter="24">
              <!-- <a-col :span="8">
                <a-form-item label="授课语言">
                  <a-select
                    placeholder="请选择"
                    allowClear
                    v-decorator="['stuProfile.situationExpectData.MATH.teachingLanguage', {}]"
                  >
                    <a-select-option value="中文普通话">中文普通话</a-select-option>
                    <a-select-option value="英语">英语</a-select-option>
                    <a-select-option value="日语">日语</a-select-option>
                    <a-select-option value="法语">法语</a-select-option>
                    <a-select-option value="德语">德语</a-select-option>
                    <a-select-option value="俄语">俄语</a-select-option>
                    <a-select-option value="韩语">韩语</a-select-option>
                    <a-select-option value="粤语">粤语</a-select-option>
                    <a-select-option value="泰语">泰语</a-select-option>
                    <a-select-option value="印尼语">印尼语</a-select-option>
                    <a-select-option value="西班牙语">西班牙语</a-select-option>
                    <a-select-option value="新加坡英语">新加坡英语</a-select-option>
                    <a-select-option value="阿拉伯语">阿拉伯语</a-select-option>
                    <a-select-option value="其他">其他</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col> -->
              <a-col :span="8">
                <a-form-item label="教学互动">
                  <a-select
                    placeholder="请选择"
                    allowClear
                    v-decorator="['stuProfile.situationExpectData.MATH.interaction', {}]"
                  >
                    <a-select-option value="高，乐于表达">高，乐于表达</a-select-option>
                    <a-select-option value="中，需要老师引导">中，需要老师引导</a-select-option>
                    <a-select-option value="低于50%，需关注">低，基本不参与互动</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="作业完成度">
                  <a-select
                    placeholder="请选择"
                    allowClear
                    v-decorator="['stuProfile.situationExpectData.MATH.homewordCompletion', {}]"
                  >
                    <a-select-option value="超80%完成，优秀">超80%完成，优秀</a-select-option>
                    <a-select-option value="80%～50%完成，良好">80%～50%完成，良好</a-select-option>
                    <a-select-option value="低于50%，需关注">低于50%，需关注</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="行为习惯">
                  <a-select
                    placeholder="请选择"
                    allowClear
                    v-decorator="['stuProfile.situationExpectData.MATH.behaviorHabit', {}]"
                  >
                    <a-select-option value="准时出席">准时出席</a-select-option>
                    <a-select-option value="偶尔缺席/迟到">偶尔缺席/迟到</a-select-option>
                    <a-select-option value="高频缺席/迟到">高频缺席/迟到</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <InfoTitle title="数学-排课信息" />
            <a-row :gutter="24">
              <a-col :span="8">
                <a-form-item label="入学时间(北京时间)">
                  <a-date-picker
                    format="YYYY-MM-DD HH:mm"
                    disabled
                    :show-time="{ defaultValue: $moment('00:00', 'HH:mm'), format: 'HH:mm' }"
                    style="width: 100%"
                    v-decorator="['data.mathEntranceDate', {}]"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item label="补升权益">
                  <a-input placeholder="请填写" v-decorator="['data.levelUpText_MATH', {}]" disabled />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="首课时间(北京时间)">
                  <a-date-picker
                    disabled
                    style="width: 100%"
                    v-decorator="['data.firstLessonDate_MATH', {}]"
                    format="YYYY-MM-DD HH:mm"
                  />
                  <!-- {{mathFirstLessonDate}}
               <a-icon type="edit" v-show="showMathFirstLesson" @click="onEditFirstLessonDate('MATH')" /> -->
                </a-form-item>
              </a-col>
            </a-row>
            <div class="sub-title">入学学情</div>
            <InfoTitle title="数学-入学学情" />
            <a-row :gutter="24">
              <a-col :span="8">
                <a-form-item label="入学年龄">
                  <a-input placeholder="请填写" v-decorator="['data.mathEntranceAge', {}]" disabled />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="孩子数学水平">
                  <a-select
                    show-search
                    placeholder="请选择"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    allowClear
                    v-decorator="['data.entryCriteria.mathLevel', {}]"
                  >
                    <a-select-option value="优秀">优秀</a-select-option>
                    <a-select-option value="良好">良好</a-select-option>
                    <a-select-option value="一般">一般</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="参与有数学辅导班">
                  <a-select
                    show-search
                    placeholder="请选择"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    allowClear
                    v-decorator="['data.entryCriteria.mathTutoringClass', {}]"
                  >
                    <a-select-option :value="true">有</a-select-option>
                    <a-select-option :value="false">无</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="参与有其他兴趣班">
                  <a-select
                    show-search
                    placeholder="请选择"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    allowClear
                    v-decorator="['data.entryCriteria.otherinterestClass', {}]"
                  >
                    <a-select-option :value="true">有</a-select-option>
                    <a-select-option :value="false">无</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="试学期优惠">
                  <a-select
                    show-search
                    disabled
                    placeholder="请选择"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    allowClear
                    v-decorator="['data.entryCriteria.mathJoinTrySemester', {}]"
                  >
                    <a-select-option :value="true">已参与</a-select-option>
                    <a-select-option :value="false">未参与</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <!-- <a-col :span="8">
                <a-form-item label="家长学习期待">
                  <a-select
                    mode="multiple"
                    show-search
                    placeholder="请选择"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    allowClear
                    v-decorator="['data.entryCriteria.mathParentsExpect', {}]"
                  >
                    <a-select-option :value="i" v-for="i of parentsExpectArr" :key="i">
                      {{ i }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                  <a-form-item label="期待老师风格">
                    <a-select   
                    v-decorator="['data.entryCriteria.mathTeacherStyleExpect']"   placeholder="请选择"  mode="multiple">
                      <a-select-option v-for="item in teachingStylesOptions" :key="item.value" :value="item.value">
                        {{ item.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
              </a-col> -->
            </a-row>
          </template>

          <template v-if="subject === 'ENGLISH'">
            <InfoTitle title="英文-排课信息" />
            <a-row :gutter="24">
              <a-col :span="8">
                <a-form-item label="入学时间(北京时间)">
                  <a-date-picker
                    format="YYYY-MM-DD HH:mm"
                    disabled
                    :show-time="{ defaultValue: $moment('00:00', 'HH:mm'), format: 'HH:mm' }"
                    style="width: 100%"
                    v-decorator="['data.englishEntranceDate', {}]"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item label="补升权益">
                  <a-input placeholder="请填写" v-decorator="['data.levelUpText_ENGLISH', {}]" disabled />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="首课时间(北京时间)">
                  <a-date-picker
                    disabled
                    style="width: 100%"
                    v-decorator="['data.firstLessonDate_ENGLISH', {}]"
                    format="YYYY-MM-DD HH:mm"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <div class="sub-title">入学学情</div>
            <InfoTitle title="英文-入学学情" />
            <a-row :gutter="24">
              <a-col :span="8">
                <a-form-item label="入学年龄">
                  <a-input placeholder="请填写" v-decorator="['data.englishEntranceAge', {}]" disabled />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="参与有英文辅导班">
                  <a-select
                    show-search
                    placeholder="请选择"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    allowClear
                    v-decorator="['data.entryCriteria.englishTutoringClass', {}]"
                  >
                    <a-select-option :value="true">有</a-select-option>
                    <a-select-option :value="false">无</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="参与有其他兴趣班">
                  <a-select
                    show-search
                    placeholder="请选择"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    allowClear
                    v-decorator="['data.entryCriteria.englishOtherinterestClass', {}]"
                  >
                    <a-select-option :value="true">有</a-select-option>
                    <a-select-option :value="false">无</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </template>
        </a-form>
        <div class="edit-btn">
          <a-button @click="cancel"> 取消 </a-button>
          <a-button type="primary" @click="onSave"> 保存编辑 </a-button>
        </div>
      </div>
      <a-modal title="体验课详情" v-model="modalVisible" :footer="null">
        <div class="experience-wrap">
          <div class="experience-item">
            <div class="experience-label">体验课名称：</div>
            <div class="experience-desc">{{ experienceObj.scheduleName }}</div>
          </div>
          <div class="experience-item">
            <div class="experience-label">上课平台：</div>
            <div class="experience-desc">{{ getDdlLabel(scheduleOptions.platforms, experienceObj.platform) }}</div>
          </div>
          <div class="experience-item">
            <div class="experience-label">体验课老师：</div>
            <div class="experience-desc">{{ experienceObj.teacherName }}</div>
          </div>
          <div class="experience-item">
            <div class="experience-label">体验课回放：</div>
            <div class="experience-desc">
              <a style="color: #3372fe" @click="playback(experienceObj)">回放链接</a>
            </div>
          </div>
          <div class="experience-item">
            <div class="experience-label">课后点评：</div>
            <div class="experience-desc">{{ experienceObj.ratingComment }}</div>
          </div>
        </div>
      </a-modal>
      <a-modal title="Cognia进度" v-model="cogniaModalVisible" :footer="null">
        <a-table
          :columns="cogniaColumns"
          :data-source="cogniaTableData"
          size="small"
          :pagination="false"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template slot="progress" slot-scope="text, record">
            <span v-if="record.canApply">可申请</span>
            <span v-else-if="record.hasApplied && record.certificateUrl"
              >已申请
              <a download :href="record.certificateUrl" target="_blank">下载证书</a>
            </span>
            <span v-else> {{ record.courseCompletionDegree }}% </span>
          </template>
        </a-table>
      </a-modal>
    </div>
  </div>
</template>

<script>
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import { queryStudentSituation, editStudent, getCagniaProgress } from '@/api/headTeacher';
import InfoTitle from '@/components/WkInfoTitle/InfoTitle';
import { teachingStylesOptions, StudyPurposeEnumText } from '@/utils/const';
import { loadScheduleOptions } from '@/api/schedule';
import { getDdlLabel } from '@/utils/util';
import { getReplayLink } from '@/api/common';

const session = storage.createStore(sessionStorage);

export default {
  name: 'StudentSituation',
  components: { InfoTitle },
  props: {
    code: {
      type: String,
    },
  },
  data() {
    return {
      StudyPurposeEnumText,
      getDdlLabel,
      scheduleOptions: {},
      teachingStylesOptions,
      homeworkPreferArr: [
        { label: '互动作业', value: 'INTERACTION' },
        { label: '纸质作业(家长明确要求)', value: 'PAPER' },
        { label: '不布置作业(家长明确要求)', value: 'NONE' },
      ],
      substitutePreferArr: [
        { label: '接受代课', value: 'ACCEPT_SUBSTITUTE_TEACHING' },
        { label: '不接受代课', value: 'DECLINE_SUBSTITUTE_TEACHING' },
      ],
      showLoading: true,
      experienceObj: {}, // 体验课
      modalVisible: false,
      showData: false, // 避免报参数错误
      showEditData: false,
      subject: 'CHINESE',
      studentId: '',
      studentDetail: {},
      form: this.$form.createForm(this),
      studentProfile: session.get('studentProfile'),
      parentsExpectArr: [
        '重“学习兴趣培养”型',
        '重“听说”型',
        '重“书写”型',
        '重“阅读”型',
        '重“文化拓展”型',
        '重“阅读写作技巧”型',
      ],
      pinyinHistory: ['无', '完全没学过拼音', '学过拼音，掌握不牢固', '学过拼音，可以拼读'],
      familyLanguage: ['无', '主要用中文', '主要用其他语言', '混合语言'],
      chinaStudyHistory: [
        '无',
        '没学过',
        '国内幼儿园或学前班',
        '国内小学1年级',
        '国内小学2年级',
        '国内小学3年级',
        '国内小学4年级',
        '国内小学5年级',
        '国内小学6年级',
        '其他',
      ],
      overseasStudyHistory: [
        '无',
        '海外完全没学过中文',
        '海外学中文不到1年',
        '海外学中文1-2年',
        '海外学中文2-3年',
        '海外学中文3-4年',
        '海外学中文4-5年',
        '海外学中文5-6年',
        '其他',
      ],
      textbookUsed: [
        '无',
        '暨南大学《中文》',
        '《马力平中文》',
        '国内教材《语文》',
        '其他教材',
        '《四五快读》',
        '《人教版语文》',
      ],
      textbookProgress: [
        '无',
        '0-学前',
        '1-第一册（一上）',
        '2-第二册（一下）',
        '3-第三册（二上）',
        '4-第三册（二下）',
        '5-第五册（三上）',
        '6-第六册（三下）',
        '7-第七册（四上）',
        '8-第八册（四下）',
        '9-第九册（五上）',
        '10-第十册（五下）',
        '11-第十一册（六上）',
        '12-第十二册（六下）',
      ],
      chineseLevel: [
        '无',
        '听说不流利-S系列',
        '听说流利，家庭启蒙/零基础-L系列启蒙',
        '听说流利，海外机构学习<1年-L系列',
        '听说流利，海外机构学习1-2年-L系列',
        '听说流利，海外机构学习>2年-G系列',
        '国内上过小学/我想要学习国内小学标准教材-G系列',
      ],
      cogniaProgress: {},
      cogniaModalVisible: false,
      cogniaColumns: [
        {
          title: '级别',
          dataIndex: 'level1Name',
        },
        {
          title: '进度',
          dataIndex: 'progress',
          scopedSlots: { customRender: 'progress' },
        },
      ],
    };
  },
  methods: {
    handleOpenCognia() {
      this.cogniaModalVisible = true;
    },
    async fetchCagniaProgress() {
      const res = await getCagniaProgress(this.code);
      this.cogniaProgress = res.data;
      console.log(res);
    },
    async playback(record) {
      // href="https://classroom-dev.wukongedu.net/playback?courseScheduleId=3e401464-9d4c-4113-90c9-2b554640d0e7"
      // window.open(record.liveUrl, '_blank');
      // 上课平台是classIn，直接打开
      if (record.platform === 'CLASS_IN') {
        window.open(record.liveUrl, '_blank');
      } else if (record.platform === 'TALK_CLOUD') {
        try {
          const { data } = await getReplayLink(record.scheduleId);
          const videoInfo = data?.[0];
          if (videoInfo?.type === 'HTML_URL') {
            window.open(videoInfo?.link);
          } else {
            window.open(`/lessonReplay?courseScheduleId=${record.scheduleId}`, '_blank');
          }
        } catch {
          window.open(`/lessonReplay?courseScheduleId=${record.scheduleId}`, '_blank');
        }
      }

      // 拓课云，接口请求，打开新页面,列表展示
    },
    goHistoryPlan() {
      this.$router.push({ path: '/learningPlanningHistory' });
    },
    // 学习规划
    editStudyPlan() {
      this.$router.push({ path: '/learningPlanning' });
    },
    // 查看体验课信息
    lookExperience(item) {
      console.log(item);
      this.experienceObj = item;
      this.modalVisible = true;
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    onSave(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const datas = values;
          datas.fullName = this.studentProfile.fullName;
          datas.data.entryCriteria.parentsExpect =
            datas.data.entryCriteria.parentsExpect && datas.data.entryCriteria.parentsExpect.join(',');
          datas.data.entryCriteria.mathParentsExpect =
            datas.data.entryCriteria.mathParentsExpect && datas.data.entryCriteria.mathParentsExpect.join(',');

          datas.data.entryCriteria.teacherStyleExpect =
            datas.data.entryCriteria.teacherStyleExpect && datas.data.entryCriteria.teacherStyleExpect.join(',');
          datas.data.entryCriteria.mathTeacherStyleExpect =
            datas.data.entryCriteria.mathTeacherStyleExpect &&
            datas.data.entryCriteria.mathTeacherStyleExpect.join(',');

          datas.data.entryCriteria.studyPurpose =
            datas.data.entryCriteria.studyPurpose && datas.data.entryCriteria.studyPurpose.join(',');

          editStudent({ uuid: this.studentId, data: datas }).then(() => {
            this.$message.success('保存成功');
            // 获取服务模块
            this.queryStudentSituation();
            this.showEditData = false;
          });
        }
      });
    },
    cancel() {
      this.showEditData = false;
    },
    editDataFun() {
      this.showEditData = true;
      const allValues = this.form.getFieldsValue();
      Object.keys(allValues).forEach((item) => {
        console.log(item);
        console.log(allValues[item]);

        allValues[item] = this.studentDetail[item];
        allValues.data.entryCriteria.parentsExpect = this.studentDetail.data.entryCriteria.parentsExpect
          ? this.studentDetail.data.entryCriteria.parentsExpect.split(',')
          : [];
        allValues.data.entryCriteria.mathParentsExpect = this.studentDetail.data.entryCriteria.mathParentsExpect
          ? this.studentDetail.data.entryCriteria.mathParentsExpect.split(',')
          : [];

        allValues.data.entryCriteria.teacherStyleExpect = this.studentDetail.data.entryCriteria.teacherStyleExpect
          ? this.studentDetail.data.entryCriteria.teacherStyleExpect.split(',')
          : [];

        allValues.data.entryCriteria.mathTeacherStyleExpect = this.studentDetail.data.entryCriteria
          .mathTeacherStyleExpect
          ? this.studentDetail.data.entryCriteria.mathTeacherStyleExpect.split(',')
          : [];

        allValues.data.entryCriteria.studyPurpose = this.studentDetail.data.entryCriteria.studyPurpose
          ? this.studentDetail.data.entryCriteria.studyPurpose.split(',')
          : [];
      });

      this.$nextTick(() => {
        this.form.setFieldsValue(allValues);
      });
    },

    queryStudentSituation() {
      const params = {
        studentId: this.studentId,
      };
      queryStudentSituation(params).then((res) => {
        this.showLoading = false;
        this.studentDetail = res.data.content;
        this.showData = true;
      });
    },
    changeSubject(e) {
      this.subject = e.target.value;
    },
    initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },
  },
  created() {
    loadScheduleOptions((res) => {
      this.scheduleOptions = res;
    });
  },
  mounted() {
    this.studentId = this.$route.query.uuid || this.studentProfile.uuid;
    this.queryStudentSituation();
    this.fetchCagniaProgress();
  },
  computed: {
    cogniaTableData() {
      console.log('???', this.cogniaProgress);
      console.log('???', this.cogniaProgress?.subjectLevels);
      console.log('???', this.cogniaProgress?.subjectLevels?.[this.subject]);
      return this.cogniaProgress?.subjectLevels?.[this.subject] || [];
    },
    chineseStudyPurposeFormat() {
      if (!this.studentDetail.data?.entryCriteria?.studyPurpose) return;

      const studyPurposeList = this.studentDetail.data?.entryCriteria?.studyPurpose?.split?.(',') || [];
      return studyPurposeList.map((item) => StudyPurposeEnumText[item]).join(',');
    },
  },
};
</script>

<style lang="less" scoped>
.ant-advanced-search-form {
  padding-bottom: 48px;
}
.classHour-top {
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
}
.sub-title {
  color: #43d186;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}
.edit-btn {
  width: calc(100% - 156px);
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  position: fixed;
  right: 20px;
  bottom: 0px;
  border-top: 1px solid #f0f0f0;
  // box-shadow: 0 -6px 16px -8px rgb(0 0 0 / 8%), 0 -9px 28px 0 rgb(0 0 0 / 5%), 0 -12px 48px 16px rgb(0 0 0 / 3%);
  .ant-btn {
    margin: 0 10px;
  }
  // .ant-btn-primary {
  //   background: #009cff;
  // }
}
.experience-item {
  display: flex;
  margin-bottom: 12px;
}
.experience-label {
  width: 100px;
}
.experience-desc {
  flex: 1;
}
.explain {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('~@/assets/explain1.png') no-repeat center;
  background-size: 100%;
  margin-left: 4px;
  cursor: pointer;
  position: absolute;
  left: -23px;
  top: 3px;
}
.class-label {
  cursor: pointer;
  color: #3372fe;
}

/deep/ .ant-descriptions-item {
  position: relative;
}
/deep/ .ant-descriptions-item-label {
  color: rgba(31, 45, 61, 0.4);
}
/deep/ .ant-descriptions-item-content {
  color: #1f2d3d;
}
//修改编辑表单label颜色
/deep/ .ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.6);
}
/deep/ .ant-input,
.ant-select {
  color: #1f2d3d;
}
.loading-wrap {
  padding-top: 200px;
  text-align: center;
}
</style>
