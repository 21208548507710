<template>
  <a-spin :spinning="loading" size="small">
    <div class="tag-list">
      <div class="tag-item" v-for="item in userSetting.showStudentLabels" :key="item.value">
        {{ item.label }}
        : {{ tagValues[item.value] || '-' }}
      </div>
      <custom-view
        title="待办任务自定义"
        :options="tagOptions"
        :defaultCheckedList="userSetting.showStudentLabels"
        :onFinish="handleChangeTagList"
        :minLength="1"
        :maxLength="maxLength"
        :disabled="!userInfo.subject"
      >
        <template v-slot:extraTitle>
          <div class="extra-title">
            <div>
              <span>当前服务科目：</span>
              <span v-if="!userInfo.subject" class="bolder"
                >当前暂未设置科目，前去<span class="primary-color pointer" @click="$router.push({ path: '/set' })"
                  >设置</span
                ></span
              >
              <span v-else class="bolder">{{ SubjectEnumText[userInfo.subject] }}</span>
            </div>
            <div>自定义选择该科目的学员标签（最多选择{{ maxLength }}个）：</div>
          </div>
        </template>
        <template v-slot:trigger>
          <div class="tag-item pointer">
            <img class="more-btn" src="../icons/setting.svg" />
          </div>
        </template>
      </custom-view>
    </div>
  </a-spin>
</template>


<script>
import { queryStudentColumns, setStudentColumns } from '@/api/headTeacher';
import { getStudentTags, getLayerTagsList } from '@/api/students';
import CustomView from '@/components/CustomView';
import { SubjectEnumText } from '@/enum';
import storage from 'store';
import { getUserUUID } from '@/utils/user';
import { reportEvent } from '@/utils/gatherer';

export default {
  data() {
    return {
      SubjectEnumText,
      maxLength: 10,
      loading: false,
      tagOptions: [],
      userInfo: storage.get('userInfo'),
      tagValues: {},
      userSetting: { showStudentLabels: [] },
    };
  },
  components: { CustomView },
  async created() {
    this.loading = true;
    // 注意有执行顺序，需要先请求所有的列表
    await this.handleGetLayerTagsList();
    await Promise.all([this.initChecked(), this.handleGetValues()]);

    this.loading = false;
  },
  methods: {
    async handleGetLayerTagsList() {
      const { data } = await getLayerTagsList();
      this.tagOptions = (data.content || []).map((item) => ({
        label: item.tagName || item.zhName,
        value: item.name,
      }));
    },
    async initChecked() {
      const { data: setting } = await queryStudentColumns();
      setting.content ||= {};
      // 给上默认
      setting.content.showStudentLabels ||= this.tagOptions.filter((item) =>
        [
          'studyStabilityScore',
          'studentRetentionScore',
          'renewalOpportunityScore',
          'recommendationOpportunityScore',
          'churnRiskScore',
          'avgCourseConsumptionLast4Weeks',
        ].includes(item.value),
      );

      this.userSetting = setting.content;
    },
    async handleGetValues() {
      if (!this.userInfo.subject) return;

      const { data } = await getStudentTags({ subject: this.userInfo.subject, uuid: this.$route.query.uuid });
      
      this.tagValues = data.content || {};
    },

    async handleChangeTagList(value) {
      reportEvent({
        data: {
          _event: 'CAstudio_StutagSetClick',
          User_id: getUserUUID(),
          FlagID: value
        },
      });
      await setStudentColumns({ ...this.userSetting, showStudentLabels: value });
      await this.initChecked();
      return true;
    },
  },
};
</script>
<style scoped lang="less">
/deep/.ant-spin-container,
.ant-spin-nested-loading {
  height: 100%;
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.tag-item {
  font-size: 12px;
  font-weight: 600;
  background: #e6f7ff;
  padding: 4px 8px;
  border-radius: 4px;
  color: #1890ff;
}
.extra-title {
  margin-bottom: 12px;
}
.bolder {
  font-weight: 600;
}
</style>