<template>
  <div class="report-main">
    <template v-if="JSON.stringify(dataInfo) !== '{}'">
      <div class="report-header">
        <p class="report-name">
          {{ moment(new Date(dataInfo.whenCreated)).format('YYYY年MM月DD日') }}{{ dataInfo.linkName }}报告
        </p>
        <p>
          答题总时长：<span>{{ classOutInTime(dataInfo.totalAnswerTime) || '暂无数据' }}</span> 提交时间：
          <span>{{ dataInfo.whenCreated || '暂无数据' }}</span>
        </p>
        <div class="fraction-main">
          <p v-if="isAverageScore">
            朗读题平均得分：<span class="fraction">{{ dataInfo.averageScore || 0 }}</span>
          </p>
          <p v-if="isErrorNumber">
            互动题错误次数： <span class="fraction">{{ dataInfo.totalErrorNumber || 0 }}</span>
          </p>
          <p>
            总分： <span class="fraction">{{ dataInfo.reportData.score || 0 }}</span>
          </p>
        </div>
      </div>
      <div class="report-expand">
        <p class="expand-btn" @click="onExpand">
          {{ is_expand ? '展开详情' : '收起详情' }}
          <a-icon :type="is_expand ? 'down' : 'up'" />
        </p>
      </div>
      <div v-if="!is_expand" class="report-list">
        <div v-for="(item, index) in data" :key="item.id">
          <div class="report-item" v-if="item.questionType === 'PICTURE_BOOK'">
            <div class="item-title">
              <p>{{ index + 1 }} 、{{ item.title }}</p>
              <span v-if="item.tag">{{ item.tag }}</span>
            </div>
            <div class="item-audio">
              <span>朗读录音: </span>
              <audio :src="item.readRecording" controls="controls" preload></audio>
            </div>
            <div class="item-audio">
              <span>朗读得分: {{ item.readScore }}分</span>
            </div>
            <div class="item-ai"><span>AI分析</span>{{ aiAnalysisHandle(item.aiAnalysis) || '正确' }}</div>
          </div>
          <div class="report-item" v-else>
            <div class="item-title">
              <p v-if="item.questionType !== 'EXPLAN_CHOICE' && item.questionType !== 'SINGLE_CHOICE'">{{ index + 1 }} 、{{ item.title }}</p>
              <div v-else style="display: flex; justify-content: flex-start">
                <p>{{ index + 1 }}、</p>
                <div>
                  <QuillRender :delta="transformStringToDelta(item.title)" />
                </div>
              </div>
              <span v-if="item.tag">{{ item.tag }}</span>
            </div>
            <div class="item-audio">
              <span>错误次数：{{ item.errorNumber }} </span>
            </div>
            <div class="item-audio">
              <span>答题时长：{{ classOutInTime(item.answerTime) || '0s' }}</span>
            </div>
          </div>
        </div>
        <a-table
          v-if="false"
          class="time-table"
          :columns="columns"
          :data-source="data"
          :pagination="false"
          size="small"
          :scroll="{ x: 0, y: 300 }"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template slot="answerTime" slot-scope="answerTime"> {{ classOutInTime(answerTime) }} </template>
          <template slot="readRecording" slot-scope="readRecording">
            <div class="readRecording" @click="audioPlay(readRecording)">
              <!-- <a-icon type="sound" v-if="readRecording !== parentaudio" /> -->
              <img v-if="readRecording !== parentaudio" class="" src="@/assets/icon_readvoice_normal.png" alt="" />
              <img v-else class="" src="@/assets/readRecording.gif" alt="" />
            </div>
          </template>
          <template slot="aiAnalysis" slot-scope="aiAnalysis">
            <a-popover v-if="aiAnalysisHandle(aiAnalysis)">
              <a slot="content">{{ aiAnalysisHandle(aiAnalysis) }}</a>
              <span>{{ aiAnalysisHandle(aiAnalysis) || '正确' }}</span>
            </a-popover>
            <span v-else>正确</span>
          </template>
        </a-table>
      </div>
    </template>
    <div v-else class="empty">
      <p>报告还未提交</p>
      <img src="@/assets/icons/pic_emptypage.svg" alt="" />
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { classOutInTime } from '@/utils/domUtil';
import QuillRender from '@wk/iexercise/lib/components/QuillRender';
import { transformStringToDelta } from '@wk/iexercise/lib/utils/quill';

const columns = [
  {
    title: '题目序号',
    dataIndex: 'questionNumber',
    align: 'center',
    scopedSlots: { customRender: 'questionNumber' },
  },
  {
    title: '绘本语句',
    dataIndex: 'title',
    ellipsis: true,
    align: 'center',
    width: 130,
    scopedSlots: { customRender: 'title' },
  },
  {
    title: '朗读得分',
    dataIndex: 'readScore',
    align: 'center',
    scopedSlots: { customRender: 'readScore' },
  },
  {
    title: '朗读语音',
    dataIndex: 'readRecording',
    align: 'center',
    scopedSlots: { customRender: 'readRecording' },
  },
  {
    title: 'AI分析',
    dataIndex: 'aiAnalysis',
    ellipsis: true,
    align: 'center',
    width: 130,
    scopedSlots: { customRender: 'aiAnalysis' },
  },
  {
    title: '答题时间',
    dataIndex: 'answerTime',
    align: 'center',
    scopedSlots: { customRender: 'answerTime' },
  },
];

export default {
  components: { QuillRender },
  props: {
    dataInfo: {
      type: Object,
      default: () => {},
    },
    parentaudio: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      moment,
      columns,
      // audio: null,
      isAudio: false,
      data: this.dataInfo?.reportData?.answerRecord || [],
      is_expand: true,
    };
  },
  computed: {
    isAverageScore() {
      return this.data.some((item) => item.questionType === 'PICTURE_BOOK');
    },
    isErrorNumber() {
      return this.data.some((item) => item.questionType !== 'PICTURE_BOOK');
    },
  },
  methods: {
    transformStringToDelta,
    classOutInTime,
    aiAnalysisHandle(data) {
      if (!data) {
        return '无';
      }
      const datas = JSON.parse(JSON.stringify(data));
      let strArr = [];
      datas.forEach((item) => {
        strArr.push(
          `${item.initialsErrorMessage || item.vowelErrorMessage ? `${item.text}：` : ''}${
            item.initialsErrorMessage ? `声母：${item.initialsErrorMessage},` : ''
          }${item.vowelErrorMessage ? `韵母：${item.vowelErrorMessage};` : ''}`,
        );
      });
      strArr = Array.from(new Set(strArr));
      return strArr.join(' ');
    },
    onExpand() {
      this.is_expand = !this.is_expand;
    },
    audioPlay(value) {
      if (this.parentaudio === value) {
        // this.$parent.clearAudio();
        this.$emit('clearAudio');
        // this.isAudio = false;
        return;
      }
      // this.isAudio = true;
      this.$emit('audioPlay', { audio: value, isAudio: this.isAudio });
    },
  },
};
</script>
<style lang="less" scoped>
.report-main {
  padding: 10px 0 0;
  border-radius: 10px;
  border: 1px solid #eee;
  margin-bottom: 20px;
  .report-header {
    padding: 0 20px;
  }
  .readRecording {
    &:hover {
      cursor: pointer;
    }
    img {
      width: 14px;
      height: 14px;
    }
  }
  p {
    font-size: 16px;
    color: #999;
    margin-bottom: 10px;
    span {
      color: #333;
      margin-right: 40px;
    }
  }
  .report-name {
    color: #333;
    font-size: 18px;
    font-weight: 600;
  }
  .fraction-main {
    display: flex;
    justify-content: space-between;
  }
  .fraction {
    font-size: 32px;
    font-weight: 600;
    color: #333333;
    line-height: 32px;
  }
  .report-expand {
    .expand-btn {
      width: 100%;
      height: 42px;
      padding-right: 10px;
      line-height: 42px;
      text-align: right;
      // border-radius: 10px;
      font-size: 16px;
      color: #333;
      background-color: #eee;
      margin-bottom: 0;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .report-list {
    padding: 10px 20px 0;
    & > div:last-child .report-item {
      border: none;
    }
    .report-item {
      border-bottom: 1px solid #eee;
      margin-bottom: 20px;
    }
    .item-title {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      p {
        font-size: 16px;
        line-height: 22px;
        color: #333;
        max-width: 80%;
        margin-bottom: 0;
      }
      span {
        font-size: 14px;
        font-weight: normal;
        padding: 2px 5px;
        border-radius: 10px;
        border: 1px solid #eee;
        margin-left: 5px;
      }
    }
    .item-audio {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
      height: 28px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      audio {
        height: 22px;
        margin: 0 10px;
      }
    }
    .item-ai {
      font-size: 14px;
      color: #666;
      margin-bottom: 10px;
      span {
        padding: 2px 8px;
        border-radius: 10px;
        background: #f7f7f7;
        border-radius: 6px;
        margin-right: 10px;
      }
    }
  }
  .empty {
    font-size: 16px;
    color: #999;
    padding-top: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
</style>
