<template>
  <div class="report-main">
    <template v-if="JSON.stringify(dataInfo) !== '{}'">
      <p class="report-name">
        {{ moment(new Date(dataInfo.whenCreated)).format('YYYY年MM月DD日') }}{{ dataInfo.linkName }}报告
      </p>
      <p>
        生成时间: <span>{{ dataInfo.whenCreated || '暂无数据' }}</span> 总作答时长:
        <span>{{ classOutInTime(dataInfo.totalAnswerTime) || '暂无数据' }}</span>
      </p>
      <div class="report-expand">
        <p>
          平均错误次数: <span>{{ dataInfo.averageErrorNumber || 0 }}次</span>
        </p>
        <p class="expand-btn" @click="onExpand">
          {{ is_expand ? '展开详情' : '收起详情' }}
          <a-icon :type="is_expand ? 'down' : 'up'" />
        </p>
      </div>
      <div v-if="!is_expand">
        <a-table
          class="time-table"
          :columns="columns"
          :data-source="data"
          :pagination="false"
          size="small"
          :scroll="{ x: 0, y: 300 }"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template slot="answerTime" slot-scope="answerTime"> {{ answerTime }}s </template>
        </a-table>
      </div>
    </template>
    <div v-else class="empty">
      <p>报告还未提交</p>
      <img src="@/assets/icons/pic_emptypage.svg" alt="" />
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { classOutInTime } from '@/utils/domUtil';

const columns = [
  {
    title: '题目序号',
    dataIndex: 'questionNumber',
    align: 'center',
    scopedSlots: { customRender: 'questionNumber' },
  },
  {
    title: '题干',
    dataIndex: 'title',
    ellipsis: true,
    align: 'center',
    scopedSlots: { customRender: 'title' },
  },
  {
    title: '错误次数',
    dataIndex: 'errorNumber',
    align: 'center',
    scopedSlots: { customRender: 'errorNumber' },
  },
  {
    title: '答题时间',
    dataIndex: 'answerTime',
    align: 'center',
    scopedSlots: { customRender: 'answerTime' },
  },
];

export default {
  props: {
    dataInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      moment,
      columns,
      audio: null,
      data: this.dataInfo?.reportData?.answerRecord || [],
      is_expand: true,
    };
  },
  mounted() {},
  methods: {
    classOutInTime,
    onExpand() {
      this.is_expand = !this.is_expand;
    },
  },
};
</script>
<style lang="less" scoped>
.report-main {
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #eee;
  margin-bottom: 20px;
  p {
    font-size: 16px;
    color: #999;
    margin-bottom: 10px;
    span {
      color: #333;
      margin-right: 40px;
    }
  }
  .report-name {
    color: #333;
    font-size: 18px;
    font-weight: 600;
  }
  .report-expand {
    display: flex;
    justify-content: space-between;
    .expand-btn {
      width: 114px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border-radius: 10px;
      font-size: 14px;
      color: #666;
      border: 1px solid #dedede;
    }
  }
  .empty {
    font-size: 16px;
    color: #999;
    padding-top: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
</style>
