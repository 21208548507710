// record-sdk.js
import Recorder from './recorder';

export default class Record {
  startRecord(param) {
    const self = this;
    try {
      // eslint-disable-next-line consistent-return
      Recorder.get((rec) => {
        if (rec.error) return param.error(rec.error);
        self.recorder = rec;
        self.recorder.start();
        param.success('开始录音');
      });
    } catch (e) {
      param.error(`开始录音失败${e}`);
    }
  }

  stopRecord(param) {
    const self = this;
    try {
      const blobData = self.recorder.getBlob();
      param.success(blobData);
    } catch (e) {
      param.error(`结束录音失败${e}`);
    }
  }

  play(audio) {
    const self = this;
    try {
      self.recorder.play(audio);
    } catch (e) {
      console.error(`录音播放失败${e}`);
    }
  }

  clear(audio) {
    const self = this;
    try {
      self.recorder.clear(audio);
    } catch (e) {
      console.error(`清空录音失败${e}`);
    }
  }
}
