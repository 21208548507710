<template>
  <div class="tab-wrap">
    <div>
      <div style="margin-bottom: 14px">
        <a-radio-group :default-value="selType" @change="handleTabClick">
          <a-radio-button :value="item.value" v-for="item in selTabData" :key="item.value">{{
            item.label
          }}</a-radio-button>
        </a-radio-group>
      </div>

      <!-- 课后作业表格 -->
      <a-table
        :key="selType"
        v-if="selType === 'homeWork'"
        :columns="homeWorkColumns"
        :loading="homeWorkLoading"
        :data-source="tableWorkData"
        @change="handleWorkTableChange"
        :pagination="homeWorkPagination"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
        <template slot="studentName" slot-scope="studentName, record">
          <span>{{ record.studentName }} ({{ record.studentCode }})</span>
        </template>
        <template slot="sectionName" slot-scope="sectionName, record">
          <a-tooltip placement="topLeft" :key="record.uuid">
            <template slot="title">
              <span>{{ record.sectionName }}</span>
              <span v-if="record.sectionNameStudent">{{ `(${record.sectionNameStudent})` }}</span>
            </template>
            <span>{{ record.sectionName }}</span>
            <span v-if="record.sectionNameStudent">{{ `(${record.sectionNameStudent})` }}</span>
          </a-tooltip>
        </template>
        <template slot="subject" slot-scope="subject">
          <span>{{ getDdlLabel(subjectArr, subject) }}</span>
        </template>
        <template slot="status" slot-scope="status">
          <span>{{ status ? initOptionsType(status, homeWorkStatusArr) : '-' }}</span>
        </template>
        <template slot="correctRate" slot-scope="correctRate">
          <span>{{ correctRate ? correctRate : '-' }}</span>
        </template>
        <template slot="startDateTime" slot-scope="startDateTime, record">
          <span>{{
            startDateTime
              ? `${$moment(startDateTime).format('YYYY-MM-DD HH:mm')} - ${$moment(record.endDateTime)
                  .format('YYYY-MM-DD HH:mm')
                  .substring(11, 16)}`
              : '-'
          }}</span>
        </template>
        <template slot="submitTime" slot-scope="submitTime">
          <span>{{ submitTime ? $moment(submitTime).format('YYYY-MM-DD HH:mm') : '-' }}</span>
        </template>

        <template slot="operation" slot-scope="operation, record" v-if="record.status !== 'NO_PUBLISH'">
          <span class="operation" @click="lookDetail(record)">作业详情</span>
        </template>
      </a-table>
      <a-table
        :key="selType"
        v-if="selType === 'study'"
        :columns="columns"
        :loading="loading"
        :data-source="tableData"
        @change="handleTableChange"
        :pagination="initPagination"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
        <a slot="level" slot-scope="text">{{ text }}</a>
        <template slot="userName" slot-scope="userName, record">
          <span>{{ userName }}</span>
          <p>{{ record.role }} ({{ record.department }})</p>
        </template>
        <template slot="sectionName" slot-scope="sectionName, record">
          <a-tooltip placement="topLeft" :key="record.uuid">
            <template slot="title">
              <span>{{ record.sectionName }}</span>
              <span v-if="record.sectionNameStudent">{{ `(${record.sectionNameStudent})` }}</span>
            </template>
            <span>{{ record.sectionName }}</span>
            <span v-if="record.sectionNameStudent">{{ `(${record.sectionNameStudent})` }}</span>
          </a-tooltip>
        </template>
        <template slot="accuracyPercent" slot-scope="accuracyPercent">
          <span>{{ accuracyPercent || '-' }}</span>
        </template>
        <template slot="classDuration" slot-scope="classDuration">
          <span>{{ classDuration ? `${classDuration}min` : '-' }}</span>
        </template>
        <template slot="stageUpTotal" slot-scope="stageUpTotal">
          <span>{{ stageUpTotal ? `${stageUpTotal}min` : '-' }}</span>
        </template>
        <template slot="status" slot-scope="status">
          <span>{{ status ? initOptionsType(status, homeWorkStatusArr) : '-' }}</span>
        </template>
        <template slot="subject" slot-scope="subject">
          <span>{{ getDdlLabel(subjectArr, subject) }}</span>
        </template>
        <template slot="startDateTime" slot-scope="startDateTime, record">
          <span>{{
            startDateTime
              ? `${$moment(startDateTime).format('YYYY-MM-DD HH:mm')} - ${$moment(record.endDateTime)
                  .format('YYYY-MM-DD HH:mm')
                  .substring(11, 16)}`
              : ''
          }}</span>
        </template>
        <template slot="operation" slot-scope="operation, record">
          <span class="operation" @click="lookReport(record)">报告详情</span>
        </template>
      </a-table>
    </div>
    <component
      :sortValueSel="sortValueSel"
      :is="currentView"
      :homeworkTemplate="homeworkTemplate"
      :studentReview="studentReview"
      :studentId="studentId"
      :checkData="checkData"
      :teacherId="teacherId"
      :subject="homeWorkSubject"
      :courseScheduleId="courseScheduleId"
      @openCheckOperation="openCheckOperation"
    ></component>
    <!-- 中文课后作业未提交 -->
    <a-drawer title="查看详情" placement="right" width="700" :closable="true" :visible="checkVisible" @close="cancal">
      <CheckHomeWork
        :subject="homeWorkSubject"
        :checkData="checkData"
        :studentReview="studentReview"
        :homeworkTemplate="homeworkTemplate"
      />
    </a-drawer>
    <!--学习报告-->
    <a-drawer
      v-if="visibleReport"
      :title="reportTitle"
      placement="right"
      width="700"
      :visible="visibleReport"
      @close="onCloseReportTC"
    >
      <StudyReport :subject="subject" :reportInfoData="reportInfoData"></StudyReport>
    </a-drawer>
    <!--结业证书-->
    <studentReport v-if="selType === 'certificate' || selType === 'report'" :selType="selType"></studentReport>
  </div>
</template>
<script>
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import { CheckHomeWork } from '@wk/tch-sc';
import StudyReport from '@/views/calendar/StudyReport.vue';
import studentReport from './studentReport.vue';
import { queryStudentHomeWorkList, courseHomeWork, queryStudyReport, getCourseReport } from '@/api/headTeacher';
import ReviewOperation from '@/views/operation/ReviewOperation.vue';
import { getDdlLabel } from '@/utils/util';
import { getUserUUID } from '@/utils/user';
import { reportEvent } from '@/utils/gatherer';

const session = storage.createStore(sessionStorage);
export default {
  name: 'studentStudy',
  components: { CheckHomeWork, ReviewOperation, StudyReport, studentReport },
  data() {
    return {
      getDdlLabel,
      subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
      ],
      loading: false,
      filteredInfo: null,
      subject: '',
      visibleReport: false,
      reportTitle: '学习报告',
      reportInfoData: {},
      filtersStudyObj: {},
      tableStudySorter: {},
      pageSize: 10,
      pageWorkNum: 1,
      filtersObj: {},
      // CheckOperation | ReviewOperation
      currentView: null, // 组件名称
      courseScheduleId: '',
      teacherId: '',
      checkVisible: false,
      submited: [],
      noSubmited: [],
      homeworkTemplate: null,
      homeWorkSubject: '',
      studentReview: [],
      checkData: {},
      sortValueSel: 0, // 默认选中预览题目tab
      homeWorkStatusArr: [
        { label: '未发布', value: 'NO_PUBLISH' },
        { label: '未完成', value: 'NO_COMPLETE' },
        { label: '已完成', value: 'COMPLETED' },
      ],
      homeWorkLoading: false,
      tableWorkData: [],
      workCurrent: 0,
      tableSorter: {},
      homeWorkData: [],
      pageWorkSize: 10,
      homeWorkPagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50'], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
      },
      // 课后作业columns
      homeWorkColumns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${(this.workCurrent - 1) * this.pageWorkSize + (index + 1)}`,
        },
        {
          title: '课节名称',
          dataIndex: 'sectionName',
          key: 'sectionName',
          scopedSlots: { customRender: 'sectionName' },
          width: 180,
          ellipsis: true,
        },
        {
          title: '课节时间',
          dataIndex: 'startDateTime',
          key: 'startDateTime',
          sorter: true,
          scopedSlots: { customRender: 'startDateTime' },
        },
        {
          title: '科目',
          dataIndex: 'subject',
          key: 'subject',
          scopedSlots: { customRender: 'subject' },
          filters: [
            { text: '中文', value: 'CHINESE' },
            { text: '数学', value: 'MATH' },
            { text: '英文', value: 'ENGLISH' },
          ],
        },

        {
          title: '作业正确率',
          dataIndex: 'correctRate',
          key: 'correctRate',
          scopedSlots: { customRender: 'correctRate' },
        },
        //  {
        //   title: '作业状态',
        //   dataIndex: 'status',
        //   key: 'status',
        //   scopedSlots: { customRender: 'status' },
        // },
        {
          title: '作业提交时间',
          dataIndex: 'submitTime',
          key: 'submitTime',

          scopedSlots: { customRender: 'submitTime' },
        },

        {
          title: '操作',
          dataIndex: 'operation',
          with: 120,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      selType: 'study',
      selTabData: [
        { label: '学习报告', value: 'study' },
        { label: '课后作业', value: 'homeWork' },
        { label: '阶段报告', value: 'report' },
        { label: '阶段证书', value: 'certificate' },
      ],
      showLoading: true,
      reportSubject: '',
      showClassSection: false, // 获取课节配置参数
      showNewReport: false,
      userInfo: {},
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) =>
            `${(this.initPagination.current - 1) * this.initPagination.pageSize + (index + 1)}`,
        },
        {
          title: '课节名称',
          dataIndex: 'sectionName',
          key: 'sectionName',
          scopedSlots: { customRender: 'sectionName' },
          width: 180,
          ellipsis: true,
        },
        {
          title: '课节时间',
          dataIndex: 'startDateTime',
          key: 'startDateTime',
          sorter: true,
          scopedSlots: { customRender: 'startDateTime' },
          width: 200,
        },
        {
          title: '科目',
          dataIndex: 'subject',
          key: 'subject',
          scopedSlots: { customRender: 'subject' },
          filters: [
            { text: '中文', value: 'CHINESE' },
            { text: '数学', value: 'MATH' },
            { text: '英文', value: 'ENGLISH' },
          ],
        },

        {
          title: '答题正确率',
          dataIndex: 'classPerformance.accuracyPercent',
          key: 'accuracyPercent',
          scopedSlots: { customRender: 'accuracyPercent' },
        },
        {
          title: '上课时长',
          dataIndex: 'activeData.classDuration',
          key: 'classDuration',

          scopedSlots: { customRender: 'classDuration' },
        },
        {
          title: '教师评分',
          dataIndex: 'ratingComment.ratingScore',
          key: 'ratingScore',
        },

        {
          title: '上台时长',
          dataIndex: 'activeData.stageUpTotal',
          key: 'stageUpTotal',
          scopedSlots: { customRender: 'stageUpTotal' },
        },
        {
          title: '奖励次数',
          dataIndex: 'classPerformance.maxAward',
          key: 'maxAward',
        },
        {
          title: '举手次数',
          dataIndex: 'activeData.handsupTotal',
          key: 'handsupTotal',
        },

        {
          title: '操作',
          dataIndex: 'operation',
          with: 120,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      pageNum: 1,
      total: 0,
      studentId: '',
      search: {
        subject: undefined,
        level: undefined,
        className: undefined,
        reportType: this.tableType,
      },

      tableData: null,
      initPagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50'], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
      },
      studentInfo: null,
      studyData: null,
      studentProfile: session.get('studentProfile'),
    };
  },

  mounted() {
    this.studentId = this.$route.query.uuid || this.studentProfile.uuid;
    this.userInfo = storage.get('userInfo');

    this.queryStudyReport();
  },

  methods: {
    // 获取学习报告
    getCourseReport(record) {
      const params = {
        courseScheduleId: record.courseScheduleId,
        studentId: this.studentProfile.uuid,
      };
      getCourseReport(params).then((res) => {
        const data = res.data.content;
        if (data.ratingComment.ratingScore === -2 || data.ratingComment.ratingScore === -1) {
          this.$message.warning('暂无学习报告');
          return false;
        }
        this.reportInfoData = {
          scheduleName: record.courseScheduleName,
          classType: record.classType === 'PRIVATE' ? '私教课' : '班课',
          timeValue: `${this.$moment(record.startDateTime).format('YYYY年MM月DD日')} ${this.$moment(
            record.startDateTime,
          ).format('dddd')}${record.startDateTime.substr(11)}-${record.endDateTime.substr(11)}`,
          structure: record.structure,
          ratingComment: data.ratingComment, // 本课评价
          classPerformance: data.classPerformance, // 课堂评价
          activeData: data.activeData, // 课堂数据
          teacherName: `${record.teacherName}（${record.teacherCode})`,
        };
        this.visibleReport = true;
      });
    },
    onCloseReportTC() {
      this.visibleReport = false;
    },
    lookReport(record) {
      reportEvent({
        data: {
          Code: this.studentProfile.code,
          subject: record.subject,
          User_id: getUserUUID(),
          StuScheduleId_id: record.stuScheduleId,
          CourseSchedule_id: record.courseScheduleId,
          _event: 'CAstudio_Learning_ReportDetailsClick',
        },
      });
      this.subject = record.subject;
      this.reportInfoData = {};
      this.reportTitle = this.subject === 'MATH' ? 'Teaching Focus' : '学习报告';
      this.getCourseReport(record);
    },
    handleTabClick(e) {
      // 重置筛选条件
      this.filtersObj = {};
      this.tableSorter = {};
      this.tableStudySorter = {};
      this.filtersStudyObj = {};
      this.selType = e.target.value;

      if (this.selType === 'homeWork') {
        this.pageWorkNum = 1;
        this.initSearchData();
      } else if (this.selType === 'study') {
        this.pageNum = 1;
        this.queryStudyReport();
      }
    },
    queryStudyReport() {
      this.loading = true;
      const params = {
        studentId: this.studentProfile.uuid,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      let sort = '';
      if (this.tableStudySorter.order === 'descend') {
        sort = 'desc';
      } else if (this.tableStudySorter.order === 'ascend') {
        sort = 'asc';
      }
      if (this.tableStudySorter.order) {
        if (this.tableStudySorter.columnKey === 'startDateTime') {
          params.orderBy = `courseSchedule.${this.tableStudySorter.columnKey} ${sort}`;
        } else {
          params.orderBy = `${this.tableStudySorter.columnKey} ${sort}`;
        }
      }
      if (this.filtersStudyObj.subject && this.filtersStudyObj.subject.length === 1) {
        params.subject = this.filtersStudyObj.subject[0];
      }
      queryStudyReport(params).then((res) => {
        const data = res.data.content;
        this.tableData = data.content;
        this.current = data.currentPage;
        this.pageSize = data.pageSize;
        this.initPagination.total = data.totalCount;
        this.initPagination.pageSize = data.pageSize;
        this.initPagination.current = data.currentPage;
        this.loading = false;
      });
    },
    cancal() {
      this.currentView = null;
      this.checkVisible = false;
    },
    openCheckOperation(sort) {
      // 从题目详情点击进去预览题目，选中对应的tab
      this.sortValueSel = sort;

      // 打开预览作业
      this.currentView = 'CheckOperation';
      this.homeworkTemplate = session.get('homeworkTemplate');
    },
    lookDetail(record) {
      console.log('asdfas');
      
      this.homeWorkSubject = record.subject;
      this.courseScheduleId = record.courseScheduleId;
      this.teacherId = record.teacherId;
      this.initCourseHomeWork(record);
    },
    // 获取预览作业
    initCourseHomeWork(record) {
      const params = {
        courseScheduleId: record.courseScheduleId,
        courseSectionId: record.sectionId,
        studentId: record.studentId,
        teacherId: record.teacherId,
      };
      courseHomeWork(params).then((res) => {
        this.studentReview = res.data.content;
        // 判断课后作业有没有发布，发布了，判断学生是否提交了作业，提交了展示提交的作业，否则展示预览作业
        if (!this.studentReview.publish) {
          this.$message.warning('暂无作业发布');
          return;
        }

        // 预览老师发布的作业
        this.checkData = res.data.content?.homeworkTemplate?.homeworkLink;
        this.homeworkTemplate = res.data.content?.homeworkTemplate;
        this.submited = this.studentReview.stuSchedules.filter((item) => {
          return item.student.submitStatus;
        });
        this.noSubmited = this.studentReview.stuSchedules.filter((item) => {
          return !item.student.submitStatus;
        });

        if (this.studentReview?.student?.caSubmitStatus) {
          // 预览学生提交的作业,里面也要展示未提交的
          this.currentView = 'ReviewOperation';
        } else {
          this.sortValueSel = 0;
          this.checkVisible = true;
        }
      });
    },
    initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },
    initSearchData() {
      this.homeWorkLoading = true;
      const params = {
        studentId: this.studentId,
        pageNum: this.pageWorkNum,
        pageSize: this.pageWorkSize,
      };
      let sort = '';
      if (this.tableSorter.order === 'descend') {
        sort = 'desc';
      } else if (this.tableSorter.order === 'ascend') {
        sort = 'asc';
      }
      if (this.tableSorter.order) {
        if (this.tableSorter.columnKey === 'startDateTime') {
          params.orderBy = `courseSchedule.${this.tableSorter.columnKey} ${sort}`;
        } else {
          params.orderBy = `${this.tableSorter.columnKey} ${sort}`;
        }
      }
      if (this.filtersObj.subject && this.filtersObj.subject.length === 1) {
        params.subject = this.filtersObj.subject[0];
      }

      queryStudentHomeWorkList(params).then((res) => {
        const data = res.data.content;
        this.tableWorkData = data.content;
        this.workCurrent = data.currentPage;
        this.pageWorkSize = data.pageSize;
        this.homeWorkPagination.total = data.totalCount;
        this.homeWorkPagination.pageSize = data.pageSize;
        this.homeWorkPagination.current = data.currentPage;
        this.homeWorkLoading = false;
      });
    },
    handleWorkTableChange(data, filters, sorter) {
      this.tableSorter = sorter;
      this.filtersObj = filters;
      this.pageWorkNum = data.current;
      this.pageWorkSize = data.pageSize;
      this.homeWorkPagination.current = data.current;
      this.initSearchData();
    },

    handleTableChange(data, filters, sorter) {
      this.tableStudySorter = sorter;
      this.filtersStudyObj = filters;
      this.pageNum = data.current;
      this.pageSize = data.pageSize;
      this.initPagination.current = data.current;
      this.queryStudyReport();
    },
  },
};
</script>
<style lang="less" scoped>
.operation {
  color: #3372fe;
  cursor: pointer;
}
.reports-search {
  width: 400px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.loading-wrap {
  padding-top: 200px;
  text-align: center;
}
</style>
