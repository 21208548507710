<template>
  <div>
    <a-drawer
      class="history-main"
      title="历史详情"
      placement="right"
      width="800"
      :closable="true"
      :visible="visible"
      @close="onClose"
    >
      <div class="loading" v-if="loadingBol">
        <a-spin size="large" />
      </div>
      <div style="padding:20px">
       
            <div v-if="JSON.stringify(historyObject) !== '[]'">
              <div class="tabcls">
                <span
                  v-for="(item, index) in historyObject"
                  :key="index"
                  :class="{ active: item.linkId === typeValue }"
                  @click="changeType(item.linkId, index)"
                  :title="item.linkName"
                >
                  {{ item.linkName }}
                </span>
              </div>
              <div v-if="datas">
                <div v-for="item in datas" :key="item.uuid">
                  <div v-if="item.exerciseType === 'INTERACTIVE_EXERCISE' || item.exerciseType === 'SERIOUS_EXERCISE'">
                    <ReadReport
                      v-if="item.reportData.answerRecord[0].questionType !== 'EXPLAN_CHOICE'"
                      :dataInfo="item || {}"
                      @audioPlay="audioPlay"
                      @clearAudio="clearAudio"
                      :parentaudio="parentaudio"
                    />
                    <MathReport
                      v-else
                      :dataInfo="item || {}"
                      @audioPlay="audioPlay"
                      @clearAudio="clearAudio"
                      :parentaudio="parentaudio"
                    />
                  </div>
                  <DoItReport v-else :dataInfo="item || {}" />
                  <!-- <ExerciseReport v-else :dataInfo="item || {}" /> -->
                </div>
              </div>
            </div>
            <div v-else class="empty">
              <p>暂无数据</p>
              <img src="@/assets/icons/pic_emptypage.svg" alt="" />
            </div>
         
      </div>
    </a-drawer>
  </div>
</template>
<script>
import ReadReport from './ReadReport';
import DoItReport from './DoItReport';
import MathReport from './MathReport';

export default {
  components: { ReadReport, DoItReport, MathReport },
  props: {
    reportHistory: {
      type: Array,
      default: () => [],
    },
    studentReview: {
      type: Object,
      default: () => {},
    },
    historyType: {
      type: String,
      default: '',
    },
    studentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loadingBol: false,
      visible: true,
      dataInfo: null,
      typeValue: this.historyType,
      historyObject: JSON.parse(JSON.stringify(this.reportHistory)),
      studentUuid: this.studentId,
      audio: null,
      parentaudio: '',
      datas: null,
    };
  },
  mounted() {
    this.historyObject.forEach((item) => {
      if (item.linkId === this.typeValue) {
        this.datas = item.reports;
      }
    });
    // this.datas = this.historyObject[0].reports;
    // this.datas = this.historyObject[this.typeValue];
  },
  methods: {
    initReport() {
      this.loadingBol = true;
      this.historyObject = [];
      this.$fetch(`/api/homework/practice/list/history/${this.$route.query.uuid}/${this.studentUuid}`)
        .then((res) => {
          this.historyObject = res.data.content;
          this.typeValue = this.historyObject[0].linkId;
          this.datas = this.historyObject[0].reports;
          this.loadingBol = false;
        })
        .catch(() => {
          this.loadingBol = false;
        });
    },
    changeType(value, index) {
      this.datas = null;
      this.typeValue = value;
      this.datas = this.historyObject[index].reports;
    },
    handleTab(val) {
      this.studentUuid = val;
      this.initReport();
    },
    audioPlay(value) {
      if (!value.audio) {
        this.$message.warning('暂无朗读录音');
        return;
      }
      if (this.audio && this.parentaudio !== value.audio) {
        this.audio.pause();
        this.audio = null;
      }
      if (!this.audio) {
        this.audio = new Audio();
        this.audio.loop = false;
        this.audio.addEventListener(
          'ended',
          () => {
            this.audio.pause();
            this.audio = null;
            this.parentaudio = '';
          },
          false,
        );
        this.audio.src = value.audio;
        this.parentaudio = value.audio;
        this.audio.play();
      }
    },
    clearAudio() {
      if (this.audio) {
        this.audio.pause();
        this.audio = null;
        this.parentaudio = '';
      }
    },
    callback() {},
    onClose() {
      this.loadingBol = false;
      this.$parent.cancal();
    },
  },
};
</script>
<style lang="less">
.history-main {
  .ant-drawer-body {
    padding: 0;
  }
  .ant-tabs-content {
    overflow-y: auto;
    height: calc(100vh - 48px);
  }
  .ant-tabs .ant-tabs-left-content {
    padding: 24px;
  }
  .ant-tabs-nav {
    .ant-tabs-tab-active {
      color: #000;
      background-color: #fff;
    }
    .ant-tabs-tab {
      width: 150px;
      text-align: center;
      padding: 16px 24px;
      text-align: left;
      margin: 0;
    }
    .ant-tabs-ink-bar {
      background-color: #fff;
    }
  }
  .ant-tabs-nav-wrap {
    background-color: #f7f7f7;
  }
  .ant-tabs-left-content {
    border-left: none;
  }
  .tabs-main {
    height: calc(100vh - 55px);
    overflow-y: auto;
  }
  .tabcls {
    display: flex;
    justify-content: flex-start;
    span {
      display: inline-block;
      width: 100px;
      font-size: 14px;
      color: #333;
      padding: 0 12px;
      background: #f7f7f7;
      border-radius: 6px;
      margin-bottom: 20px;
      margin-right: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }
    .active {
      color: #fff;
      background-color: #009cff;
    }
  }
  .empty {
    font-size: 16px;
    color: #999;
    padding-top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .loading {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    padding: 50% 0 0 0;
    text-align: center;
    font-size: 60px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
  }
}
</style>
