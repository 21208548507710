<template>
<div class="tab-wrap">
 
  <div>
    <div class="classHour-top">
      <div>
        <a-radio-group default-value="CHINESE" @change="changeSubject">
          <a-radio-button value="CHINESE">中文</a-radio-button>
          <a-radio-button value="MATH">数学</a-radio-button>
           <a-radio-button value="ENGLISH">英文</a-radio-button>

        </a-radio-group>
      </div>
      <div>
       <a-button type="primary" ghost style="margin-right: 10px" @click="goPeriodValidity">课时有效期</a-button>
        <a-button type="primary" ghost style="margin-right: 10px" @click="shareLessons">分享赠课记录</a-button>
         <a-button type="primary" ghost style="margin-right: 10px" @click="changeClassHour">课时变更</a-button>

        <!-- <a-button type="primary" ghost style="margin-right: 10px" @click="toOrderPage">申请变更</a-button> -->
        <a-button type="primary" ghost @click="changeLog">变更记录</a-button>
      </div>
    </div>
   <div class="loading-wrap" v-show="showLoading">
   <a-spin size="large" />
  </div>
    <a-descriptions class="descriptions-wrap"  v-show="!showLoading" :title="`${getDdlLabel(subjectArr,subject)}课时信息`">
      <a-descriptions-item>
        <template v-slot:label>
           <a-popover  trigger="hover">
              <template slot="content">
                <p>学员端总课时：
                  <span v-show="subject === 'CHINESE'">{{classHourData.stuCenterChineseCu}}</span>
                   <span v-show="subject === 'MATH'">{{classHourData.stuCenterMathCu}}</span>
                    <span v-show="subject === 'ENGLISH'">{{classHourData.stuCenterEnglishCu}}</span>

                </p>
                <p>修复课时：
                 

                     <span v-show="subject === 'CHINESE'">{{classHourData.chineseCu-classHourData.stuCenterChineseCu}}</span>
                   <span v-show="subject === 'MATH'">{{classHourData.mathCu-classHourData.stuCenterMathCu}}</span>
                    <span v-show="subject === 'ENGLISH'">{{classHourData.englishCu-classHourData.stuCenterEnglishCu}}</span>
                </p>
              </template>
               剩余/<span style="color:#04CB94;cursor:pointer">总课时 </span>
            </a-popover>
        </template>
      <span class="green-color" v-show="subject === 'CHINESE'">
         {{classHourData.chineseRemainingCu}} 
      </span>
     <span class="green-color" v-show="subject === 'MATH'">
         {{classHourData.mathRemainingCu}}  
      </span>
     <span class="green-color" v-show="subject === 'ENGLISH'">
         {{classHourData.englishRemainingCu}}  
      </span>
        /
      <span v-show="subject === 'CHINESE'">
         {{classHourData.chineseCu}} 
      </span>
       <span v-show="subject === 'MATH'">
         {{classHourData.mathCu}} 
      </span>
       <span v-show="subject === 'ENGLISH'">
         {{classHourData.englishCu}} 
      </span>
    
      </a-descriptions-item>
      <a-descriptions-item>
         <template v-slot:label>
           <a-popover  trigger="hover">
              <template slot="content" v-if="deductionObj.length>0">
                <p v-for="(item,index) in deductionObj" :key="index">{{item.typeString}}：{{item.cu}}</p>
             
              </template>
              <template slot="content" v-else>
                <p>暂无已扣课时</p>
            
              </template>
              <span style="color:#04CB94;cursor:pointer">
                      已扣课时数
                </span>
            </a-popover>
          
        </template>
        <span v-show="subject ==='CHINESE'">
          {{classHourData.chineseCu-classHourData.chineseRemainingCu || '-'}}
        </span>
        <span v-show="subject ==='MATH'">
          {{classHourData.mathCu-classHourData.mathRemainingCu || '-'}}
        </span>
       <span v-show="subject ==='ENGLISH'">
          {{classHourData.englishCu-classHourData.englishRemainingCu || '-'}}
        </span>


      </a-descriptions-item>

      <a-descriptions-item label="伴学奖赠课">
          <span class="green-color">{{classHourData.content['GIFT_HOMEWORK_COMPLETION']?classHourData.content['GIFT_HOMEWORK_COMPLETION'].remainingCu:'-'}}</span>
         / {{classHourData.content['GIFT_HOMEWORK_COMPLETION']?classHourData.content['GIFT_HOMEWORK_COMPLETION'].totalCu:'-' }}
      </a-descriptions-item>
      <a-descriptions-item label="购买课时">
        <span class="green-color">
       {{ classHourData.content['PURCHASED']?classHourData.content['PURCHASED'].remainingCu:'-'}}</span> / {{ classHourData.content['PURCHASED']?classHourData.content['PURCHASED'].totalCu:'-' }}

      </a-descriptions-item>
      <a-descriptions-item label="推荐赠课">
          <span class="green-color">
           {{classHourData.content['GIFT_RECOMMEND']?classHourData.content['GIFT_RECOMMEND'].remainingCu:'-' }}</span> / {{classHourData.content['GIFT_RECOMMEND']?classHourData.content['GIFT_RECOMMEND'].totalCu:'-' }}

      </a-descriptions-item>
      <a-descriptions-item label="手动变更课时">
          <span class="green-color">
          {{classHourData.content['GIFT_ADJUST']?classHourData.content['GIFT_ADJUST'].remainingCu:'-' }}</span> / {{ classHourData.content['GIFT_ADJUST']?classHourData.content['GIFT_ADJUST'].totalCu:'-' }}

      </a-descriptions-item>
      <a-descriptions-item label="分销赠课">
          <span class="green-color">
           {{classHourData.content['GIFT_DISTRIBUTION']?classHourData.content['GIFT_DISTRIBUTION'].remainingCu:'-'}}</span> / {{classHourData.content['GIFT_DISTRIBUTION']?classHourData.content['GIFT_DISTRIBUTION'].totalCu:'-' }}

      </a-descriptions-item>
      <a-descriptions-item label="购买赠课">
          <span class="green-color">
                {{ classHourData.content['GIFT_PURCHASED']?classHourData.content['GIFT_PURCHASED'].remainingCu:'-'}}</span> / {{classHourData.content['GIFT_PURCHASED']?classHourData.content['GIFT_PURCHASED'].totalCu:'-' }}

      </a-descriptions-item>
      <a-descriptions-item label="社交平台分享赠课">
          <span class="green-color">
                  {{ classHourData.content['GIFT_SOCIAL_NETWORK_SHARE']?classHourData.content['GIFT_SOCIAL_NETWORK_SHARE'].remainingCu:'-' }}</span> / {{classHourData.content['GIFT_SOCIAL_NETWORK_SHARE']?classHourData.content['GIFT_SOCIAL_NETWORK_SHARE'].totalCu:'-' }}

      </a-descriptions-item>
      <a-descriptions-item label="入学分享赠课">
          <span class="green-color">
                {{ classHourData.content['GIFT_SIGNUP_SHARE']?classHourData.content['GIFT_SIGNUP_SHARE'].remainingCu:'-' }}</span> / {{classHourData.content['GIFT_SIGNUP_SHARE']?classHourData.content['GIFT_SIGNUP_SHARE'].totalCu:'-' }}

      </a-descriptions-item>
      <a-descriptions-item label="小班课专属赠课">
          <span class="green-color">
                {{classHourData.content['GIFT_SMALL_LESSON']?classHourData.content['GIFT_SMALL_LESSON'].remainingCu:'-' }}</span> / {{classHourData.content['GIFT_SMALL_LESSON']?classHourData.content['GIFT_SMALL_LESSON'].totalCu:'-' }}

      </a-descriptions-item>
       <a-descriptions-item>
          <template v-slot:label>
           <a-popover  trigger="hover">
              <template slot="content" v-if="compensateObj.length>0">
                <p v-for="(item,index) in compensateObj" :key="index">{{item.typeString}}：<span class="green-color">{{item.remainingCu}}</span> / {{item.cu}}</p>
             
              </template>
              <template slot="content" v-else>
                <p>暂无其他赠课</p>
            
              </template>
              <span style="color:#04CB94;cursor:pointer">
                      其他赠课
                </span>
            </a-popover>
          
        </template>
          <span class="green-color">
                {{classHourData.content['GIFT_COMPENSATE']?classHourData.content['GIFT_COMPENSATE'].remainingCu:'-' }}</span> / {{classHourData.content['GIFT_COMPENSATE']?classHourData.content['GIFT_COMPENSATE'].totalCu:'-' }}

      </a-descriptions-item>
    </a-descriptions>
    <a-descriptions  v-show="!showLoading" class="descriptions-wrap" :title="`${getDdlLabel(subjectArr,subject)}分享赠课额度`">
      <a-descriptions-item label="剩余/总额度">
        <span class="green-color" v-show="subject === 'CHINESE'">
          {{classHourData.chineseRemainingQuota}}
        </span>
      <span class="green-color" v-show="subject === 'MATH'">
          {{classHourData.mathRemainingQuota}}
        </span>
      <span class="green-color" v-show="subject === 'ENGLISH'">
          {{classHourData.englishRemainingQuota}}
        </span>
        /
        <span v-show="subject === 'CHINESE'">
          {{classHourData.chineseQuota}}
        </span>
       <span v-show="subject === 'MATH'">
          {{classHourData.mathQuota}}
        </span>
       <span v-show="subject === 'ENGLISH'">
          {{classHourData.englishQuota}}
        </span>
       
      </a-descriptions-item>
   
      <a-descriptions-item label="分享赠课额度">
          <span class="green-color">
           {{classHourData.content['GIFT_SOCIAL_NETWORK_SHARE']?classHourData.content['GIFT_SOCIAL_NETWORK_SHARE'].remainingQuota:'-' }}</span> / {{classHourData.content['GIFT_SOCIAL_NETWORK_SHARE']?classHourData.content['GIFT_SOCIAL_NETWORK_SHARE'].totalQuota:'-' }}

      </a-descriptions-item>
    </a-descriptions>

    <editCourseDuration v-if="editCourse" :id="this.studentId" @cancelCourse="cancelCourse"></editCourseDuration>
    <changeClassHour  v-if="editClassHour"  @cancelClassHour="cancelClassHour"></changeClassHour>
  </div>
</div>
</template>

<script>
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import { queryStudentClassHourNew,queryDeductionClass,queryCompensateClass} from '@/api/headTeacher';
import editCourseDuration from './editCourseDuration.vue';
import changeClassHour from './changeClassHour.vue'
const session = storage.createStore(sessionStorage);
import { getDdlLabel } from '@/utils/util';

export default {
  name: 'StudentClassHour',
  components: {
    editCourseDuration,
    changeClassHour
  },
  data() {
    return {
      getDdlLabel,
     subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
         { label: '英文', value: 'ENGLISH' },
      ],
      compensateObj:[],
      deductionObj:[],
      studentProfile: session.get('studentProfile'),
      transferObj:{},
      editClassHour:false,
      showLoading:false,
      editCourse: false,
      subject: 'CHINESE',
      studentId: '',
      studentClassData: [],
      classHourData: {
      content:{},
      },
      chineseClassData: {
       
      },
      mathClassData: {
       
      },
     englishClassData: {
       
      },
    };
  },
  methods: {
    goPeriodValidity(){
     this.$router.push({ path: '/periodValidity',query:{studentId:this.studentId} })
    },
    shareLessons(){
       this.$router.push({ path: '/sharingLessons',query:{studentId:this.studentId} })
    },
    //查询补偿课时
    queryCompensateClass(){
       let params={
       studentId:this.studentId,
        subject:this.subject
      }
      queryCompensateClass(params).then(res=>{
           this.compensateObj=res.data.content

      })
    },
    //查询已扣课时数的详情
    queryDeductionClass(){
      let params={
       studentId:this.studentId,
        subject:this.subject
      }
      queryDeductionClass(params).then(res=>{
        this.deductionObj=res.data.content
      })
    },
    cancelClassHour(){
        this.editClassHour=false
    },
    changeClassHour(){
      this.editClassHour=true
    },
    cancelCourse(val) {
      this.editCourse = false;
      if (val === 'submit') {
        this.queryStudentClassHour();
      }
    },
    toOrderPage() {
      this.editCourse = true;
      // this.$router.push({
      //   path: '/editCourseDuration',
      //   query: { id: this.studentId },
      // });
    },
    changeLog() {
      this.$router.push({
        path: '/courseDurationList',
        query: { id: this.studentId },
      });
    },
    queryStudentClassHour() {
      this.showLoading=true
      const params = {
        studentId: this.studentId,
      
      };
      queryStudentClassHourNew(params).then((res) => {
      
        this.showLoading=false
        this.classHourData = res.data.content;
         this.chineseClassData=this.classHourData.chineseCuTransferMap
        this.mathClassData=this.classHourData.mathCuTransferMap
       this.englishClassData=this.classHourData.englishCuTransferMap


        if(this.subject==='CHINESE'){
          this.classHourData.content= {...this.chineseClassData}
        }else if(this.subject==='MATH'){
            this.classHourData.content= {...this.mathClassData}
        }else if(this.subject==='ENGLISH'){
            this.classHourData.content= {...this.englishClassData}
        }

      });
    },
    changeSubject(e) {
      this.subject = e.target.value;
      console.log(this.subject)
        if(this.subject==='CHINESE'){
          this.classHourData.content= {...this.chineseClassData}
        }else if(this.subject==='MATH'){
            this.classHourData.content= {...this.mathClassData}
        }else if(this.subject==='ENGLISH'){
            this.classHourData.content= {...this.englishClassData}
        }
   this.queryDeductionClass();
    this.queryCompensateClass()
    }
  },
  mounted() {
    this.studentId = this.$route.query.uuid || this.studentProfile.uuid;
    this.queryStudentClassHour();
    this.queryDeductionClass();
    this.queryCompensateClass()
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-descriptions-item-label{
  color:rgba(31, 45, 61, 0.4);
}
/deep/ .ant-descriptions-item-content{
  color:#1F2D3D;
}
.classHour-top {
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
}
//修改编辑表单label颜色
/deep/ .ant-form-item-label > label{
  color: rgba(0, 0, 0, 0.6);
}
/deep/ .ant-input,.ant-select{
   color:#1F2D3D;
}
.loading-wrap{
  padding-top: 200px;
  text-align: center;
}
.green-color{
  color:#04CB94
}
</style>
