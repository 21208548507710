<template>
  <div>
    <div class="info-topTitle">
      <div class="info-title" v-if="imGroupList.length > 0">
        {{ groupName }}<span style="color: #999">【{{ subject }}】</span>
      </div>
      <div class="group-txt" @click="showGroup">
        <span style="padding: 0 10px; font-size: 18px">|</span>
        <span class="group-num">群成员（{{ groupMember.length }}）</span>
        <span class="pull-img" :class="showGroupFlag ? 'rotate0' : 'rotate180'"> </span>
      </div>
      <div class="toggle-group" @click="toggleGroup" v-if="imGroupList.length > 1">切换服务群 ></div>
    </div>
    <div class="chat-history" v-show="showGroupFlag">
      <div class="title">
        <span>群成员</span>
        <span style="color: #999; padding-left: 10px">({{ groupMember.length }})</span>
      </div>
      <div class="group-member">
        <div class="member-item" v-for="(item, index) in groupMember" :key="index">
          <div v-if="item.user !== null">
            <div class="member-pic">
              <img :src="item.user.avatar" />
            </div>
            <div>
              <div class="member-name">{{ item.user.nick }}</div>
              <div class="member-role">{{ item.user.memberRole }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chat-history">
      <div class="chat-search">
        <a-input-search
          allowClear
          placeholder="搜索聊天记录"
          style="width: 200px; margin-right: 20px"
          @search="onSearch"
        />
        <a-range-picker
          :show-time="{ format: 'HH:mm' }"
          format="YYYY-MM-DD HH:mm"
          :placeholder="['开始日期', '结束日期']"
          @change="onChange"
          @ok="onOk"
        />
        <a-button
          type="default"
          icon="poweroff"
          style="margin-left: 10px"
          :loading="iconLoading"
          @click="enterIconLoading"
        >
          刷新
        </a-button>
      </div>
      <div
        class="chat-scroll"
        :class="showGroupFlag ? 'haveMember' : 'noMember'"
        v-infinite-scroll="loadMore"
        infinite-scroll-disabled="busy"
        infinite-scroll-distance="10"
      >
        <div class="chat-item" v-for="(message, index) in messageArr" :key="index">
          <div class="chat-pic">
            <img :src="message.avatar" />
          </div>
          <div class="chat-content">
            <div class="chat-topcon">
              <div class="chat-name">{{ message.nameCard }}</div>
              <div class="chat-time">{{ message.whenCreated }}</div>
            </div>
            <!--文本-->
            <text-element :payload="message.payload" :message="message" v-if="message.type === TIM.TYPES.MSG_TEXT" />

            <!--图片-->
            <image-element
              v-else-if="message.type === TIM.TYPES.MSG_IMAGE"
              :payload="message.payload"
              :message="message"
            />
            <!--视频-->
            <video-element
              v-else-if="message.type === TIM.TYPES.MSG_VIDEO"
              :payload="message.payload"
              :message="message"
            />
            <!--音频-->
            <sound-element
              v-else-if="message.type === TIM.TYPES.MSG_SOUND"
              :payload="message.payload"
              :message="message"
            />
            <!--文件-->
            <file-element
              v-else-if="message.type === TIM.TYPES.MSG_FILE"
              :payload="message.payload"
              :message="message"
            />

            <!--表情-->
            <face-element
              v-else-if="message.type === TIM.TYPES.MSG_FACE"
              :payload="message.payload"
              :message="message"
            />
            <!--自定义消息-->
            <custom-element
              v-else-if="message.type === TIM.TYPES.MSG_CUSTOM"
              :payload="message.payload"
              :message="message"
            />
            <div v-else>暂未支持的消息类型：{{ message.type }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { decodeText } from '@/utils/IM/decodeText';
import InfoTitle from '@/components/WkInfoTitle/InfoTitle';

import FileElement from './message-elements/file-element.vue';
import FaceElement from './message-elements/face-element.vue';
import ImageElement from './message-elements/image-element.vue';
import TextElement from './message-elements/text-element.vue';
import SoundElement from './message-elements/sound-element.vue';
import VideoElement from './message-elements/video-element.vue';
import CustomElement from './message-elements/custom-element.vue';
import { getDetailHistory, getGroupMember } from '@/api/headTeacher';
import { getDdlLabel } from '@/utils/util';

export default {
  data() {
    return {
      getDdlLabel,
      subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
      ],
      subject: '',
      iconLoading: false,
      groupName: '',
      amr: null,
      timer: null,
      secondTime: 0,
      showGroupFlag: false,
      groupID: '',
      startTime: '',
      endTime: '',
      name: '',
      currentPage: 1,
      messageArr: [],
      groupMember: [],

      messageOriginArr: [],
      busy: false, // 滚动开启
    };
  },
  components: {
    FileElement,
    FaceElement,
    ImageElement,
    TextElement,
    SoundElement,
    CustomElement,
    VideoElement,
    InfoTitle,
  },
  props: {
    indexPos: {
      type: Number,
    },
    imGroupList: {
      type: Array,
      default: [],
    },
  },
  // watch: {
  //     studentProfile: {
  //       // 深度监听，可监听到对象、数组的变化
  //       handler(obj) {
  //       this.busy=false;
  //       this.messageArr=[];
  //       this.messageOriginArr=[];
  //       this.currentPage=1;
  //       this.imGroupList=obj.imGroupList
  //       this.groupID=this.imGroupList[0].groupId
  //       this.getGroupMember()
  //       this.getDetailHistory()
  //       },
  //       deep: true, // true 深度监听
  //     },
  //   },
  computed: {
    ...mapState({
      studentProfile: (state) => {
        return state.student.studentProfile;
      },
    }),
    contentList(payload) {
      return decodeText(payload);
    },
    url() {
      return this.payload.url;
    },
    size() {
      return this.payload.size;
    },
    second() {
      return this.payload.second;
    },
    playState() {
      return this.$store.state.conversation.playStateAll;
    },
    widthPercent() {
      if (this.second < 10) {
        return 50;
      }
      if (this.second >= 10 && this.second < 20) {
        return 70;
      }
      if (this.second >= 20 && this.second < 30) {
        return 90;
      }
      if (this.second >= 30 && this.second < 40) {
        return 110;
      }
      if (this.second >= 40 && this.second < 50) {
        return 130;
      }
      if (this.second >= 50 && this.second <= 60) {
        return 150;
      }
    },
  },
  methods: {
    enterIconLoading() {
      this.iconLoading = true;
      this.currentPage = 1;
      this.getDetailHistory('search');
    },
    // 获取群成员
    getGroupMember() {
      const params = {
        groupId: this.groupID,
      };
      getGroupMember(params).then((res) => {
        this.groupMember = res.data.content.memberList;
      });
    },
    // 获取详情里面的聊天记录
    getDetailHistory(act) {
      const params = {
        groupId: this.groupID,
        pageNum: this.currentPage,
        pageSize: 20,
        name: this.name,
        startTime: this.startTime,
        endTime: this.endTime,
      };
      getDetailHistory(params)
        .then((res) => {
          this.busy = false;
          this.iconLoading = false;
          const arr = res.data.content.content;
          if (arr.length === 0) {
            this.busy = true;
          }

          if (act === 'search') {
            this.messageArr = arr;
            this.messageOriginArr = [];
            this.busy = true;
          } else {
            this.messageArr = this.messageOriginArr.concat(arr);
            this.messageOriginArr = this.messageArr;
          }
        })
        .catch(() => {
          this.busy = true;
          this.iconLoading = false;
        });
    },
    loadMore() {
      this.busy = true;
      setTimeout(() => {
        this.currentPage++;
        this.getDetailHistory();
      }, 1000);
    },
    // 切换其他群
    toggleGroup() {
      const otherGroup = this.imGroupList.filter((item) => {
        return item.groupId !== this.groupID;
      });
      this.groupID = otherGroup[0].groupId;
      this.groupName = otherGroup[0].groupName;
      this.subject = this.getDdlLabel(this.subjectArr, otherGroup[0].subject);
      this.messageArr = [];
      this.messageOriginArr = [];
      this.currentPage = 1;
      this.getGroupMember();
      this.getDetailHistory();
    },
    showGroup() {
      this.showGroupFlag = !this.showGroupFlag;
    },
    onChange(value, dateString) {
      console.log('Selected Time: ', value);
      console.log('Formatted Selected Time: ', dateString);

      this.startTime = dateString[0];
      this.endTime = dateString[1];
    },
    onOk(value) {
      this.messageArr = [];
      this.messageOriginArr = [];
      this.currentPage = 1;
      this.getDetailHistory('search');
    },
    onSearch(val) {
      this.name = val;
      this.messageArr = [];
      this.currentPage = 1;
      this.getDetailHistory('search');
    },
    downloadFile() {},
    videoError(e) {
      this.$store.commit('showMessage', { type: 'error', message: `视频出错，错误原因：${e.target.error.message}` });
    },
    play() {
      const audio = this.$refs[`audio${this.indexPos}`];
      // 获取到所有语音，暂停不是当前播放的所有语音
      const allAudios = document.getElementsByClassName('audio');
      allAudios.forEach((item) => {
        if (item.src !== audio.src) {
          item.pause();
          for (const key in this.playState) {
            // 切换播放暂停的效果
            if (key !== `isShow${this.indexPos}`) {
              this.$set(this.playState, key, false);
            } else {
              this.$set(this.playState, key, true);
            }
          }
        }
      });
      this.secondTime = this.payload.second;
      this.timer = null;
      clearInterval(this.tiemr);
      // 目前移动端的语音消息采用 aac 格式，以前用 amr 格式。默认先用 audio 标签播放，若无法播放则尝试 amr 格式播放。
      // const audio = document.createElement('audio')
      audio.addEventListener('error', this.tryPlayAMR); // 播放出错，则尝试使用 AMR 播放
      // audio.src = this.url
      // console.log(audio.paused)
      if (audio.paused) {
        audio.load(); // 重新加载音频，免得从暂停开始播放
        const promise = audio.play();
        if (promise) {
          promise
            .then(() => {
              // 音频加载成功
              this.playState[`isShow${this.indexPos}`] = true; // 播放状态
              // 音频的播放需要耗时,音频播放完，切换到暂停状态的图
              if (this.timer) {
                return;
              }
              this.tiemr = setInterval(() => {
                this.secondTime--;
                if (this.secondTime <= 0) {
                  this.playState[`isShow${this.indexPos}`] = false; // 暂停状态
                  this.timer = null;
                  clearInterval(this.tiemr);
                }
              }, 1000);
            })
            .catch(() => {});
        }
      } else {
        audio.pause();
        this.playState[`isShow${this.indexPos}`] = false; // 暂停状态
      }
    },
    tryPlayAMR() {
      try {
        const isIE = /MSIE|Trident|Edge/.test(window.navigator.userAgent);
        // amr 播放组件库在 IE 不支持
        if (isIE) {
          this.$store.commit('showMessage', {
            message: '您的浏览器不支持该格式的语音消息播放，请尝试更换浏览器，建议使用：谷歌浏览器',
            type: 'warning',
          });
          return;
        }
        // 动态插入 amr 播放组件库
        if (!window.BenzAMRRecorder) {
          const script = document.createElement('script');
          script.addEventListener('load', this.playAMR);
          script.src = './BenzAMRRecorder.js';
          const firstScript = document.getElementsByTagName('script')[0];
          firstScript.parentNode.insertBefore(script, firstScript);
          return;
        }
        this.playAMR();
      } catch (error) {
        this.$store.commit('showMessage', {
          message: '您的浏览器不支持该格式的语音消息播放，请尝试更换浏览器，建议使用：谷歌浏览器',
          type: 'warning',
        });
      }
    },
    playAMR() {
      if (!this.amr && window.BenzAMRRecorder) {
        this.amr = new window.BenzAMRRecorder();
      }
      if (this.amr.isInit()) {
        this.amr.play();
        return;
      }
      this.amr.initWithUrl(this.url).then(() => {
        this.amr.play();
      });
    },
  },
  mounted() {
    if (this.imGroupList !== null) {
      this.groupID = this.imGroupList[0].groupId;
      this.groupName = this.imGroupList[0].groupName;

      this.subject =this.getDdlLabel(this.subjectArr,this.imGroupList[0].subject);

      this.getGroupMember();
      this.getDetailHistory();
    }
  },
};
</script>

<style lang="less" scoped>
.info-title {
  font-size: 18px;
  color: #333;
}
.info-topTitle {
  padding: 5px 10px;
  background-color: rgba(245, 245, 245, 0.65);
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.chat-history {
  border: 1px solid #eeeeee;
  border-radius: 8px;
  margin-top: 20px;
}
.chat-item {
  margin: 20px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  padding-bottom: 20px;
}

.chat-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  img {
    width: 100%;
    height: 100%;
  }
}
.chat-content {
  margin-left: 10px;
  flex: 1;
}
.chat-topcon {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.chat-name {
  font-size: 14px;
  color: #666;
}
.chat-time {
  font-size: 12px;
  color: #999;
}
.image-wrap {
  // min-width:50px;
  // min-height:50px;
  // border-radius:8px;
  // overflow:hidden;
}

.image-element {
  max-width: 100px;
  max-height: 100px;
  cursor: zoom-in;
  border-radius: 8px;
  overflow: hidden;
}
.file-element-wrapper {
  cursor: pointer;
}
.header {
  display: flex;
}
.file-icon {
  font-size: 40px !important;
}
.file-element {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}
.file-size {
  font-size: 12px;
  padding-top: 5px;
}
.video {
  width: 100%;
  max-height: 300px;
}
.sound-element-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 10px;
}
.audio-loading {
  width: 16px;
  height: 14px;
  display: inline-block;
  background: url('~@/./assets/audio.gif') no-repeat 0 0;
  background-size: cover;
}
.audio-pause {
  width: 16px;
  height: 14px;
  display: inline-block;
  background: url('~@/./assets/pauseIcon.png') no-repeat 0 0;
  background-size: cover;
}
.face-element-wrapper {
  img {
    max-width: 90px;
  }
}
.chat-search {
  padding: 20px;
}
.chat-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
}
.noMember {
  height: 57vh;
}
.haveMember {
  height: calc(57vh - 178px);
}
.title {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  margin: 10px 20px;
  &::before {
    content: '';
    display: block;
    float: left;
    margin: 4px 6px 0 0;
    width: 3px;
    height: 16px;
    background-color: #41cc83;
    border-radius: 0px 2px 2px 0px;
  }
}
.group-member {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 20px;
}
.member-item {
  flex: 1;
  display: flex;
}
.member-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: salmon;
  img {
    width: 100%;
    height: 100%;
  }
}
.member-name {
  font-size: 13px;
  color: #333;
  margin-bottom: 5px;
}
.member-role {
  font-size: 12px;
  color: #999;
}
.pull-img {
  width: 12px;
  height: 12px;
  display: inline-block;
  background: url('~@/assets/home_icon_pull.png');
  background-size: 100% 100%;
}
.toggle-group {
  margin-left: auto;
  font-size: 15px;
  color: #333;
  cursor: pointer;
}
.group-txt {
  color: #999;
  cursor: pointer;
  &:hover {
    .group-num {
      color: #43d186;
    }
    .pull-img {
      background: url('~@/assets/home_icon_pullgreen.png');
      background-size: 100% 100%;
    }
  }
}
.rotate180 {
  transform: rotate(180deg);
}
.rotate0 {
  transform: rotate(0deg);
}
</style>
