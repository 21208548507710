import { render, staticRenderFns } from "./studentData.vue?vue&type=template&id=332f18ee&scoped=true&"
import script from "./studentData.vue?vue&type=script&lang=js&"
export * from "./studentData.vue?vue&type=script&lang=js&"
import style0 from "./studentData.vue?vue&type=style&index=0&id=332f18ee&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "332f18ee",
  null
  
)

export default component.exports