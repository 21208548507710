<template>
    <div class="border-wrap">
      <video :src="payload.videoUrl" controls class="video" @error="videoError"></video>
    </div>

    
</template>

<script>
import { Progress } from 'element-ui'
export default {
  name: 'VideoElement',
  components: {
    ElProgress: Progress
  },
  props: {
    payload: {
      type: Object,
      required: true
    },
    message: {
      type: Object,
      required: true
    },
  
  },

  computed: {
    showProgressBar() {
      return this.message.status === 'unSend'
    },
    percentage() {
      return Math.floor((this.$parent.message.progress || 0) * 100)
    }
  },
  methods: {
    videoError(e) {
      this.$store.commit('showMessage', { type: 'error', message: '视频出错，错误原因：' + e.target.error.message })
    }
  }
}
</script>

<style lang="stylus" scoped>
.video
  width 100%
  max-height 100px
.border-wrap
  max-width:100px
  border-radius 6px
  overflow hidden

</style>
