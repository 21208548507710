<template>
  <a-modal
    title="课时变更"
    :visible="visible"
    width="600px"
    :okButtonProps="{
      props: {
        loading: iconLoading,
      },
    }"
    @ok="onSubmit"
    dialogClass="schedule-related"
    @cancel="onCancel(0)"
  >
    <a-spin :spinning="loading" size="large">
      <a-form ref="ruleForm" :form="form" :label-col="labelCol" :wrapper-col="wrapperCol">
        <div class="small-txt">请先选择课时变更类型：</div>
        <a-form-item label="变更类型">
          <a-select
            @change="changeTransferType"
            placeholder="请选择"
            v-decorator="['transactionCategory', { rules: [{ required: true, message: '请选择变更类型!' }] }]"
          >
            <a-select-option value="TRANSFER">课时转移</a-select-option>
            <a-select-option value="COMPENSATE">课时赠送</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="变更原因">
          <a-select
            placeholder="请选择"
            v-decorator="['transactionReason', { rules: [{ required: true, message: '请选择变更原因!' }] }]"
          >
            <template v-if="transferType === 'TRANSFER'">
              <a-select-option :value="index" v-for="(item, index) in reasonArr['TRANSFER']" :key="index">{{
                item
              }}</a-select-option>
            </template>
            <template v-if="transferType === 'RECOVER'">
              <a-select-option :value="index" v-for="(item, index) in reasonArr['RECOVER']" :key="index">{{
                item
              }}</a-select-option>
            </template>
            <template v-if="transferType === 'COMPENSATE'">
              <a-select-option :value="index" v-for="(item, index) in reasonArr['COMPENSATE']" :key="index">{{
                item
              }}</a-select-option>
            </template>
          </a-select>
        </a-form-item>
        <a-form-item :label="subjectTxt">
          <a-select
            @change="changeSubject"
            placeholder="请选择"
            v-decorator="['subject', { rules: [{ required: true, message: '请选择科目!' }] }]"
          >
            <a-select-option value="CHINESE">中文</a-select-option>
            <a-select-option value="MATH">数学</a-select-option>
            <a-select-option value="ENGLISH">英文</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="转入学员" v-if="transferType === 'TRANSFER'">
          <a-select
            placeholder="请选择学员"
            allow-clear
            show-search
            :filter-option="filterOption"
            option-filter-prop="children"
            @search="getStudentInfo"
            v-decorator="['toStudentId', { rules: [{ required: true, message: '请选择学员!' }] }]"
          >
            <a-select-option v-for="item in studentList" :value="item.uuid" :key="item.uuid"
              >{{ item.fullName }}({{ item.code }})</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="转入科目" v-if="transferType === 'TRANSFER'">
          <a-select
            placeholder="请选择"
            v-decorator="['toSubject', { rules: [{ required: true, message: '请选择转移至科目!' }] }]"
          >
            <a-select-option value="CHINESE">中文</a-select-option>
            <a-select-option value="MATH">数学</a-select-option>
            <a-select-option value="ENGLISH">英语</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          label="购买课时"
          v-if="
            transferType === 'TRANSFER' && this.transferObj['PURCHASED'] && this.transferObj['PURCHASED'].remainingCu
          "
        >
          <a-input-number disabled style="width: 37%" placeholder="请输入" v-decorator="['TOTALPURCHASED']" />
          <span class="transfer-num">转移数量</span>
          <a-input-number style="width: 37%" placeholder="请输入" v-decorator="['PURCHASED']" :min="0" />
        </a-form-item>
        <a-form-item
          label="其他赠课"
          v-if="
            transferType === 'TRANSFER' &&
            this.transferObj['GIFT_COMPENSATE'] &&
            this.transferObj['GIFT_COMPENSATE'].remainingCu
          "
        >
          <a-input-number disabled style="width: 37%" placeholder="请输入" v-decorator="['TOTALGIFT_COMPENSATE']" />
          <span class="transfer-num">转移数量</span>
          <a-input-number style="width: 37%" placeholder="请输入" v-decorator="['GIFT_COMPENSATE']" :min="0" />
        </a-form-item>
        <a-form-item
          label="购买赠课"
          v-if="
            transferType === 'TRANSFER' &&
            this.transferObj['GIFT_PURCHASED'] &&
            this.transferObj['GIFT_PURCHASED'].remainingCu
          "
        >
          <a-input-number disabled style="width: 37%" placeholder="请输入" v-decorator="['TOTALGIFT_PURCHASED']" />
          <span class="transfer-num">转移数量</span>
          <a-input-number style="width: 37%" placeholder="请输入" v-decorator="['GIFT_PURCHASED']" :min="0" />
        </a-form-item>
        <a-form-item
          label="推荐赠课"
          v-if="
            transferType === 'TRANSFER' &&
            this.transferObj['GIFT_RECOMMEND'] &&
            this.transferObj['GIFT_RECOMMEND'].remainingCu
          "
        >
          <a-input-number disabled style="width: 37%" placeholder="请输入" v-decorator="['TOTALGIFT_RECOMMEND']" />
          <span class="transfer-num">转移数量</span>
          <a-input-number style="width: 37%" placeholder="请输入" v-decorator="['GIFT_RECOMMEND']" :min="0" />
        </a-form-item>
        <a-form-item
          label="手动变更课时"
          v-if="
            transferType === 'TRANSFER' &&
            this.transferObj['GIFT_ADJUST'] &&
            this.transferObj['GIFT_ADJUST'].remainingCu
          "
        >
          <a-input-number disabled style="width: 37%" placeholder="请输入" v-decorator="['TOTALGIFT_ADJUST']" />
          <span class="transfer-num">转移数量</span>
          <a-input-number style="width: 37%" placeholder="请输入" v-decorator="['GIFT_ADJUST']" :min="0" />
        </a-form-item>
        <a-form-item
          label="分销赠课"
          v-if="
            transferType === 'TRANSFER' &&
            this.transferObj['GIFT_DISTRIBUTION'] &&
            this.transferObj['GIFT_DISTRIBUTION'].remainingCu
          "
        >
          <a-input-number disabled style="width: 37%" placeholder="请输入" v-decorator="['TOTALGIFT_DISTRIBUTION']" />
          <span class="transfer-num">转移数量</span>
          <a-input-number style="width: 37%" placeholder="请输入" v-decorator="['GIFT_DISTRIBUTION']" :min="0" />
        </a-form-item>
        <a-form-item
          label="社交平台分享赠课"
          v-if="
            transferType === 'TRANSFER' &&
            this.transferObj['GIFT_SOCIAL_NETWORK_SHARE'] &&
            this.transferObj['GIFT_SOCIAL_NETWORK_SHARE'].remainingCu
          "
        >
          <a-input-number
            disabled
            style="width: 37%"
            placeholder="请输入"
            v-decorator="['TOTALGIFT_SOCIAL_NETWORK_SHARE']"
          />
          <span class="transfer-num">转移数量</span>
          <a-input-number
            style="width: 37%"
            placeholder="请输入"
            v-decorator="['GIFT_SOCIAL_NETWORK_SHARE']"
            :min="0"
          />
        </a-form-item>
        <a-form-item
          label="入学分享赠课"
          v-if="
            transferType === 'TRANSFER' &&
            this.transferObj['GIFT_SIGNUP_SHARE'] &&
            this.transferObj['GIFT_SIGNUP_SHARE'].remainingCu
          "
        >
          <a-input-number disabled style="width: 37%" placeholder="请输入" v-decorator="['TOTALGIFT_SIGNUP_SHARE']" />
          <span class="transfer-num">转移数量</span>
          <a-input-number style="width: 37%" placeholder="请输入" v-decorator="['GIFT_SIGNUP_SHARE']" :min="0" />
        </a-form-item>
        <a-form-item
          label="伴学奖赠课"
          v-if="
            transferType === 'TRANSFER' &&
            this.transferObj['GIFT_HOMEWORK_COMPLETION'] &&
            this.transferObj['GIFT_HOMEWORK_COMPLETION'].remainingCu
          "
        >
          <a-input-number
            disabled
            style="width: 37%"
            placeholder="请输入"
            v-decorator="['TOTALGIFT_HOMEWORK_COMPLETION']"
          />
          <span class="transfer-num">转移数量</span>
          <a-input-number style="width: 37%" placeholder="请输入" v-decorator="['GIFT_HOMEWORK_COMPLETION']" :min="0" />
        </a-form-item>
        <a-form-item
          label="小班课专属赠课"
          v-if="
            transferType === 'TRANSFER' &&
            this.transferObj['GIFT_SMALL_LESSON'] &&
            this.transferObj['GIFT_SMALL_LESSON'].remainingCu
          "
        >
          <a-input-number disabled style="width: 37%" placeholder="请输入" v-decorator="['TOTALGIFT_SMALL_LESSON']" />
          <span class="transfer-num">转移数量</span>
          <a-input-number style="width: 37%" placeholder="请输入" v-decorator="['GIFT_SMALL_LESSON']" :min="0" />
        </a-form-item>
        <a-form-item
          label="分享赠课额度"
          v-if="
            transferType === 'TRANSFER' &&
            this.transferObj['GIFT_SOCIAL_NETWORK_SHARE'] &&
            this.transferObj['GIFT_SOCIAL_NETWORK_SHARE'].remainingQuota
          "
        >
          <a-input-number
            disabled
            style="width: 37%"
            placeholder="请输入"
            v-decorator="['TOTALGIFT_SOCIAL_NETWORK_SHAREQuota']"
          />
          <span class="transfer-num">转移额度</span>
          <a-input-number
            style="width: 37%"
            placeholder="请输入"
            v-decorator="['GIFT_SOCIAL_NETWORK_SHAREQuota']"
            :min="0"
          />
        </a-form-item>
        <a-form-item label="相关课节" v-if="transferType === 'RECOVER'">
          <a-select
            placeholder="请选择"
            @change="changeCourseSchedule"
            show-search
            :filter-option="filterOption"
            option-filter-prop="children"
            @search="getCourseScheduleList"
            v-decorator="['stuScheduleId', { rules: [{ required: true, message: '请选择该学员已上课节!' }] }]"
          >
            <a-select-option v-for="item in courseScheduleList" :value="item.uuid" :key="item.uuid">{{
              `${item.courseScheduleRespDTO.scheduleName}(${item.courseScheduleRespDTO.startChinaDateTime})`
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="课时数量" v-if="transferType === 'RECOVER' || transferType === 'COMPENSATE'">
          <a-input-number
            :disabled="transferType === 'RECOVER'"
            style="width: 100%"
            placeholder="请输入"
            v-decorator="['transactionAmount', { rules: [{ required: true, message: '请填写课时数量!' }] }]"
          />
        </a-form-item>
        <a-form-item label="飞书审批编号" v-if="transferType === 'TRANSFER'">
          <a-input
            placeholder="请输入"
            v-decorator="['feiShuNo', { rules: [{ required: true, message: '请输入飞书审批编号!' }] }]"
          />
        </a-form-item>
        <a-form-item label="飞书审批编号" v-else>
          <a-input placeholder="请输入" v-decorator="['feiShuNo']" />
        </a-form-item>
        <a-form-item label="上传图片">
          <a-upload
            name="file"
            :file-list="fileList"
            list-type="picture-card"
            @change="handleChange"
            v-decorator="['imageUrl', { rules: [{ required: true, message: '请上传图片!' }] }]"
          >
            <div v-if="fileList.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item label="说明">
          <a-textarea
            placeholder="请输入"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            v-decorator="['transactionRemark', { rules: [{ required: true, message: '请填写说明!' }] }]"
          />
        </a-form-item>
        <!-- <a-form-model-item :wrapper-col="{ span: 14, offset: 10 }">
          <a-button type="primary" @click="onSubmit"> 提交 </a-button>
          <a-button style="margin-left: 10px" @click="resetForm"> 取消 </a-button>
        </a-form-model-item> -->
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import { getDdlLabel } from '@/utils/util';
import { imgpostUrl } from '@/api/register';
import {
  getUploadOssToken,
  queryTakeOverStudent,
  getCourseScheduleList,
  submitCourseSchedule,
  queryClassHourNum,
  queryChangeReason,
  queryStudentClassHourNew,
} from '@/api/headTeacher';

const session = storage.createStore(sessionStorage);
export default {
  props: {
    // transferObj:{
    //   type:Object,
    //   default: () => {},
    // }
  },
  data() {
    return {
      iconLoading: false,
      fileList: [],
      subjectTxt: '转出科目',
      chineseClassData: {},
      mathClassData: {},
      englishClassData: {},
      transferObj: {},
      reasonArr: {},
      transferArr: [],
      studentProfile: session.get('studentProfile'),
      courseScheduleList: [],
      studentList: [],
      cuTransferList: [],
      form: this.$form.createForm(this),
      transferType: '',
      visible: true,
      getDdlLabel,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      previewVisible: false,
      previewImage: '',
      studentId: '',
      loading: false,
      roles: [],
      userInfo: {},

      apiUrl: '/api/admin/scheduling/course_unit',
      fileList: [],
      formData: null,
      uploading: false,
    };
  },
  created() {
    this.studentId = this.id;
    this.roles = storage.get('roles');
    this.userInfo = storage.get('userInfo');
  },
  mounted() {
    this.getCourseScheduleList('');
    this.queryChangeReason();
  },
  methods: {
    queryStudentClassHour(val) {
      const params = {
        studentId: this.studentProfile.uuid,
      };
      queryStudentClassHourNew(params).then((res) => {
        if (val === 'CHINESE') {
          this.transferObj = res.data.content.chineseCuTransferMap;
          this.chineseClassData = this.transferObj;
        } else if (val === 'MATH') {
          this.transferObj = res.data.content.mathCuTransferMap;
          this.mathClassData = this.transferObj;
        } else if (val === 'ENGLISH') {
          this.transferObj = res.data.content.englishCuTransferMap;
          this.englishClassData = this.transferObj;
        }

        // 回填转移数量
        const obj = {};
        const arr = Object.entries(this.transferObj);
        arr.forEach((item) => {
          obj[`TOTAL${item[0]}`] = item[1].remainingCu;
          if (item[0] === 'GIFT_SOCIAL_NETWORK_SHARE' && item[1].remainingQuota > 0) {
            // 社交平台分享赠课额度

            obj[`TOTAL${item[0]}Quota`] = item[1].remainingQuota;
          }
        });

        this.$nextTick(() => {
          this.form.setFieldsValue(obj);
        });
      });
    },
    queryChangeReason() {
      const params = {
        nodeTag: 'STUDENT_CU_TRANSFER',
      };
      queryChangeReason(params).then((res) => {
        this.reasonArr = res.data.content;
      });
    },
    changeSubject(val) {
      if (this.transferType === 'TRANSFER') {
        this.queryStudentClassHour(val);
      }
      this.courseScheduleList = [];
      this.form.setFieldsValue({
        transactionAmount: null,
        stuScheduleId: null,
      });
      this.getCourseScheduleList('', val);
    },
    changeCourseSchedule(val) {
      const params = {
        stuScheduleId: val,
      };
      queryClassHourNum(params).then((res) => {
        this.form.setFieldsValue({
          transactionAmount: res.data.content,
        });
      });
    },
    getCourseScheduleList(name, subject) {
      const params = {
        subject,
        scheduleName: name,
        studentId: this.studentProfile.uuid,
        hasTransaction: true,
        studentTimeZone: this.studentProfile.timezoneStd,
      };
      getCourseScheduleList(params).then((res) => {
        this.courseScheduleList = res.data.content;
      });
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    getStudentInfo(name) {
      const params = {
        name,
      };
      queryTakeOverStudent(params).then((res) => {
        this.studentList = res.data.content;
      });
    },
    changeTransferType(val) {
      this.form.setFieldsValue({
        transactionReason: '',
      });
      this.transferType = val;
      if (this.transferType === 'TRANSFER') {
        this.subjectTxt = '转出科目';
        // 变更类型变了，对应的转移也要变
        const subject = this.form.getFieldValue('subject');
        if (subject) {
          this.queryStudentClassHour(subject);
        }
      } else {
        this.subjectTxt = '科目';
      }
    },
    onCancel(val) {
      this.$emit('cancelClassHour', val);
    },
    handleChange(info) {
      if (info.file.status === 'removed') {
        this.fileList = info.fileList;
      } else {
        this.imgInfo = info;
        this.uploading = true;
        getUploadOssToken().then((res) => {
          this.uploadToOss(info, res.data.content);
        });
      }
    },
    handleCancel() {
      this.previewVisible = false;
    },
    uploadToOss(info, fileData) {
      const formData = new FormData();
      const formArray = Object.entries(fileData.formFields);
      formArray.forEach((item) => {
        formData.append(item[0], item[1]);
      });
      formData.append('file', info.file.originFileObj);
      imgpostUrl(`https://${fileData.endpoint}`, formData)
        .then((res) => {
          this.fileList.push({
            uid: res.data.fileId,
            name: 'image.png',
            status: 'done',
            url: res.data.url,
          });
          console.log(this.fileList);
        })
        .finally(() => {
          this.uploading = false;
        });
    },

    onSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.iconLoading = true;
          const params = {
            transactionCategory: values.transactionCategory,
            transactionReason: values.transactionReason,
            subject: values.subject,
            studentId: this.studentProfile.uuid,
            transactionRemark: values.transactionRemark,
            feiShuNo: values.feiShuNo,
          };
          if (this.fileList.length > 0) {
            params.imageUrl = [this.fileList[0].url];
          }

          if (this.transferType === 'TRANSFER') {
            // 课时转移的参数
            const cuTransferList = [];
            const cuTransfer = {
              toSubject: values.toSubject,
              toStudentId: values.toStudentId,
            };
            const arr = Object.entries(values);
            console.log(arr);
            arr.forEach((item) => {
              // 课时有值才加入
              if (
                (item[0] === 'GIFT_ADJUST' ||
                  item[0] === 'PURCHASED' ||
                  item[0] === 'GIFT_PURCHASED' ||
                  item[0] === 'GIFT_SIGNUP_SHARE' ||
                  item[0] === 'GIFT_SOCIAL_NETWORK_SHARE' ||
                  item[0] === 'GIFT_HOMEWORK_COMPLETION' ||
                  item[0] === 'GIFT_RECOMMEND' ||
                  item[0] === 'GIFT_DISTRIBUTION' ||
                  item[0] === 'GIFT_SMALL_LESSON' ||
                  item[0] === 'GIFT_COMPENSATE') &&
                item[1] > 0
              ) {
                // 把原有的课时，原有的额度加上
                arr.forEach((subItem) => {
                  if (subItem[0] === `TOTAL${item[0]}`) {
                    cuTransferList.push({
                      type: item[0],
                      quota: '',
                      cu: item[1],
                      oldCu: subItem[1],
                    });
                  }
                });
              }
              if (item[0] === 'GIFT_SOCIAL_NETWORK_SHAREQuota' && item[1] > 0) {
                // 分享赠课额度
                // 把原有的课时，原有的额度加上
                arr.forEach((subItem) => {
                  if (subItem[0] === 'TOTALGIFT_SOCIAL_NETWORK_SHAREQuota') {
                    cuTransferList.push({
                      type: 'GIFT_SOCIAL_NETWORK_SHARE',
                      quota: item[1],
                      cu: '',
                      oldQuota: subItem[1],
                    });
                  }
                });
              }
            });

            if (cuTransferList.length === 0) {
              this.$message.warning('至少选择一项进行转移!');
              this.iconLoading = false;
              return false;
            }
            cuTransfer.cuTransferList = cuTransferList;
            params.cuTransfer = cuTransfer;
          }
          // 课时恢复
          if (this.transferType === 'RECOVER') {
            params.transactionAmount = values.transactionAmount;
            params.stuScheduleId = values.stuScheduleId;
          }
          // 课时补偿
          if (this.transferType === 'COMPENSATE') {
            params.transactionAmount = values.transactionAmount;
          }
          submitCourseSchedule(params)
            .then(() => {
              this.$message.success('提交成功');
              this.iconLoading = false;
              this.onCancel('submit');
            })
            .catch(() => {
              this.iconLoading = false;
            });
        }
      });
    },

    resetForm() {
      this.$refs.ruleForm.resetFields();
      history.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.small-txt {
  padding-left: 66px;
  margin-bottom: 12px;
  color: #999;
}
.transfer-num {
  padding: 0 12px;
  color: #999;
}
/deep/ .ant-modal-body {
  max-height: 580px;
  overflow-y: auto;
}
</style>
