<template>
  <div>
    <a-modal
      id="editImage"
      class="editImage"
      width="800"
      :visible="visible"
      :closable="!visible"
      :getContainer="getContainer"
      :footer="null"
    >
      <div v-if="imgArray.newImage === imgArray.oldImage" id="container" ref="container"></div>
      <img class="newImage" v-else :src="imgArray.newImage" alt="" />
      <div class="edit-tools">
        <div>
          <a-button class="img-btn" :class="{ btnBj: '' }" @click="onAddVector"
            ><img src="@/assets/Vector.png" alt=""
          /></a-button>
          <a-button class="img-btn" @click="onAddError"><img src="@/assets/Error.png" alt="" /></a-button>
          <a-button class="img-btn" :class="{ btnBj: isLine }" @click="onAddLine"
            ><img src="@/assets/Line.png" alt=""
          /></a-button>
          <a-button class="img-btn" :class="{ btnBj: isText }" @click="onAddText"
            ><img src="@/assets/Text.png" alt=""
          /></a-button>
          <a-button class="img-btn" :class="{ btnBj: isRubber }" @click="onAddRubber"
            ><img src="@/assets/Rubber.png" alt=""
          /></a-button>
          <a-button class="img-btn" @click="onDelete"><img src="@/assets/Del.png" alt="" /></a-button>
        </div>
        <div>
          <a-button @click="onReduction">还原</a-button>
          <a-button @click="onClose">退出编辑</a-button>
          <a-button @click="onSave" :loading="loading" :disabled="loading">完成批改</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import Konva from 'konva';
import Vector from '@/assets/Vector.png';
import Error from '@/assets/Error.png';

export default {
  props: {
    imgUrl: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imgArray: JSON.parse(JSON.stringify(this.imgUrl)),
      vector: Vector,
      error: Error,
      visible: true,
      pageStage: null,
      pageLayer: [],
      width: 800,
      height: 500,
      imgLayer: null,
      isLine: false,
      line: null,
      strokeWidth: 3,
      mode: '',
      isPaint: false, // 持续画点
      pointsArray: [],
      isRubber: false,
      currentShape: null,
      textNode: null,
      textAreaEle: null,
      isText: false,
      postion: null,
      fontsize: 30,
      dbtextarea: null,
      changeTextValue: null,
      reduction: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.imgArray.newImage === this.imgArray.oldImage) {
        this.initStage();
      }
    });
  },
  methods: {
    getContainer() {
      return document.body;
    },
    getTimestamp(prefix = '') {
      const timestamp = new Date().getTime().toString();
      if (prefix) {
        return prefix + timestamp;
      }
      return timestamp;
    },
    removeTextarea() {
      this.textAreaEle.parentNode.removeChild(this.textAreaEle);
      window.removeEventListener('click', this.handleOutsideClick);
      this.textAreaEle = null;
      this.textNode.show();
    },

    setTextareaWidth() {
      let textWidth = '';
      textWidth = this.textAreaEle.value.length * this.textNode.fontSize() || 50;
      this.textAreaEle.style.width = `${textWidth}px`;
    },
    handleOutsideClick(e) {
      if (this.textAreaEle && e.target !== this.textAreaEle) {
        // if (this.textAreaEle.value) {
        // this.textNode.text(this.textAreaEle.value);
        // }
        this.removeTextarea();
      }
    },
    initStage() {
      this.pageStage = new Konva.Stage({
        container: 'container',
        width: this.width,
        height: this.height,
      });
      this.addLayer();

      const { layer } = this.pageLayer[0];
      this.pageStage.on('mousedown touchstart', (e) => {
        const pos = this.pageStage.getPointerPosition();
        this.currentShape = e.target;
        // 画线
        if (this.isLine) {
          this.isPaint = true;
          this.line = new Konva.Line({
            stroke: '#df4b26',
            strokeWidth: this.strokeWidth,
            globalCompositeOperation: this.mode === 'brush' ? 'source-over' : '',
            lineCap: 'round',
            name: 'line',
            points: [pos.x, pos.y, pos.x, pos.y],
          });
          layer.add(this.line);
        }
        // 添加输入框
        if (this.isText) {
          this.createText(e, pos);
        }
      });
      this.pageStage.on('mousemove touchmove', (e) => {
        if (this.isLine) {
          if (!this.isPaint) {
            return;
          }
          e.evt.preventDefault();
          const pos = this.pageStage.getPointerPosition();
          const newPoints = this.line.points().concat([pos.x, pos.y]);
          this.pointsArray = this.pointsArray.concat(newPoints);
          this.line.points(newPoints);
          layer.draw();
        }
      });
      this.pageStage.on('mouseup touchend', () => {
        this.isPaint = false;
      });
    },
    addLayer() {
      const name = this.getTimestamp('l_');
      const layer = new Konva.Layer();
      if (this.pageLayer.length === 0) {
        const imageObj = new Image();
        imageObj.src = this.imgArray.oldImage;
        imageObj.crossOrigin = 'Anonymous';
        const backgroundImage = new Konva.Image({
          x: 0,
          y: 0,
          width: this.width,
          height: this.height,
          name: 'bjImage',
          image: imageObj,
        });
        layer.add(backgroundImage);
      }
      this.pageLayer.push({ name, layer });
      this.pageStage.add(layer);
    },
    createText(e, pos) {
      const { layer } = this.pageLayer[0];
      if (this.changeTextValue) {
        if (this.textAreaEle.value) {
          this.changeTextValue.target.text(this.textAreaEle.value);
        }
        this.handleOutsideClick(this.changeTextValue);
        this.changeTextValue = null;
        return;
      }
      // 多行文本是否有值
      if (this.textAreaEle && this.textAreaEle.value) {
        const textNode = new Konva.Text({
          x: this.postion.x,
          y: this.postion.y,
          text: this.textAreaEle.value,
          fontSize: 30,
          fontFamily: 'Calibri',
          draggable: true,
          name: 'text',
        });
        textNode.on('dblclick dbltap', (event) => {
          this.changeText(event, pos);
        });
        this.textNode = textNode;
        layer.add(textNode);
        layer.draw();
        this.handleOutsideClick(e);
      } else if (this.textAreaEle && !this.textAreaEle.value) {
        this.handleOutsideClick(e);
      }
      // 添加输入框
      if (!e.target.hasName('text')) {
        this.postion = pos;
        this.createTextArea(pos);
      }
    },
    changeText(e, pos) {
      this.handleOutsideClick(e);
      this.changeTextValue = e;
      this.createTextArea(pos);
    },
    createTextArea(pos) {
      const textarea = document.createElement('textarea');
      this.textAreaEle = textarea;
      this.$refs.container.appendChild(textarea);
      textarea.focus();
      textarea.style.position = 'absolute';
      textarea.style.top = `${pos.y}px`;
      textarea.style.left = `${pos.x}px`;
      textarea.style.zIndex = 2000;
      textarea.style.width = '150px';
      textarea.style.height = '50px';
      textarea.style.fontSize = '30px';
      textarea.style.textIndent = '10px';
      textarea.style.border = 'none';
      textarea.style.padding = '0px';
      textarea.style.margin = '0px';
      textarea.style.overflow = 'hidden';
      textarea.style.outline = 'none';
      textarea.style.resize = 'none';
      textarea.style.transformOrigin = 'left top';
      let transform = '';
      let px = 0;
      const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      if (isFirefox) {
        px += 2 + Math.round(30 / 20);
      }
      transform += `translateY(-${px}px)`;
      textarea.style.transform = transform;
      textarea.style.height = 'auto';
      textarea.focus();
      textarea.addEventListener('keydown', () => {
        this.setTextareaWidth();
        textarea.style.height = 'auto';
      });
    },
    onAddText() {
      this.isLine = false;
      this.isRubber = false;
      this.isText = true;
    },
    onAddRubber() {
      if (this.currentShape.hasName('line')) {
        this.currentShape.points([]);
      }
      this.isText = false;
      this.isLine = false;
      this.isRubber = true;
    },
    onAddLine() {
      this.isLine = true;
      this.isRubber = false;
      this.isText = false;
      this.mode = 'brush';
    },
    onAddVector() {
      this.isLine = false;
      this.isText = false;
      this.isRubber = false;
      const img = new Image();
      img.src = this.vector;
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        const { layer } = this.pageLayer[0];
        const picture = new Konva.Image({
          image: img,
          width: 50,
          height: 40,
          x: 80,
          y: 30,
          draggable: true,
        });
        layer.add(picture);
        layer.draw();
      };
    },
    onAddError() {
      this.isLine = false;
      this.isText = false;
      this.isRubber = false;
      const img = new Image();
      img.src = this.error;
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        const { layer } = this.pageLayer[0];
        const picture = new Konva.Image({
          image: img,
          width: 50,
          height: 40,
          x: 100,
          y: 50,
          draggable: true,
        });
        layer.add(picture);
        layer.draw();
      };
    },
    onDelete() {
      this.isLine = false;
      this.isText = false;
      const { layer } = this.pageLayer[0];
      if (!this.currentShape.hasName('bjImage')) {
        this.currentShape.destroy();
        layer.draw();
      }
    },
    onReduction() {
      this.$set(this.imgArray, 'newImage', this.imgArray.oldImage);
      this.reduction = true;
      this.$nextTick(() => {
        this.pageLayer = [];
        this.initStage();
      });
    },
    onSave() {
      const $this = this;
      this.$confirm({
        title: '提示',
        content: '暂不支持重新编辑 请谨慎提交',
        okText: '确定',
        onOk() {
          const dataURL = $this.pageStage.toDataURL({ pixelRatio: 2 });
          $this.$emit('onSaveEditImage', { oldImage: $this.imgArray.oldImage, newImage: dataURL });
          $this.downloadURI(dataURL, 'image.png');
        },
        onCancel() {},
      });
    },
    onClose() {
      const $this = this;
      this.$confirm({
        title: '提示',
        content: '退出编辑后，本次编辑内容将会丢失，确定退出？',
        okText: '确定',
        onOk() {
          $this.$emit('onClose');
        },
        onCancel() {},
      });
    },
    downloadURI(uri, name) {
      const link = document.createElement('a');
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>
<style lang="less">
.editImage {
  .newImage {
    max-width: 800px;
  }
  .edit-tools {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 30px;
    .ant-btn {
      padding: 7px 20px;
      margin-right: 10px;
      border-radius: 6px;
      font-size: 12px;
      color: #333;
    }
    .img-btn {
      padding: 0;
      width: 60px;
      height: 60px;
      background-color: #f7f7f7;
      border-radius: 2px;
    }
    .btnBj {
      border: 1px solid #009cff;
    }
  }
}
.editImage .ant-modal {
  // position: relative;
  width: 850px;
  height: 500px;
}
</style>
