<template>
  <div class="tab-wrap">
    <div class="loading-wrap" v-show="showLoading">
      <a-spin size="large" />
    </div>
    <div v-show="!showLoading">
      <div class="classHour-top">
        <a-radio-group default-value="CHINESE" @change="changeSubject">
          <a-radio-button value="CHINESE">中文</a-radio-button>
          <a-radio-button value="MATH">数学</a-radio-button>
        </a-radio-group>
      </div>
      <a-descriptions class="descriptions-wrap" title="学员分层">
        <a-descriptions-item label="学员分层">
          {{ studentLayeredData.rank || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="续费分类">
          {{ studentLayeredData.rechargeType || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="跟进策略">
          {{ studentLayeredData.followStrategy || '-' }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions class="descriptions-wrap" title="周期课时信息">
        <a-descriptions-item label="获客渠道">
          {{ studentLayeredData.channel || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="获客来源">
          {{ studentLayeredData.source || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="付款方式">
          {{ studentLayeredData.payMethod || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="体验周期">
          {{ studentLayeredData.trialPeriod || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="转化周期">
          {{ studentLayeredData.changePeriod || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="开课时长">
          {{ studentLayeredData.courseDuration || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="历史沉睡次数">
          {{ studentLayeredData.historySleepTimes || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="历史沉睡时长">
          {{ studentLayeredData.historySleepDuration || '-' }}
        </a-descriptions-item>

        <a-descriptions-item label="近3个月课消">
          {{ studentLayeredData.threeMonthCourseUnitUsed || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="近3周课消">
          {{ studentLayeredData.threeWeekCourseUnitUsed || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="上上周完课">
          {{ studentLayeredData.lastTwoWeekComplete || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="上周完课">
          {{ studentLayeredData.lastWeekComplete || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="本周已上已约">
          {{ studentLayeredData.currentWeekCompleteArranged || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="下周已约">
          {{ studentLayeredData.nextWeekArranged || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="辅修课占比">
          {{ studentLayeredData.minorCourseRate || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="最后一次付款月">
          {{ studentLayeredData.lastPayMonth || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="历史复购次数">
          {{ studentLayeredData.historyRepurchases || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="剩余课时分档">
          {{ studentLayeredData.remindCourseUnitDived || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="续费池">
          {{ studentLayeredData.renewalPool || '-' }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions class="descriptions-wrap" title="作业信息">
        <a-descriptions-item label="近4周作业提交率">
          {{ studentLayeredData.lastFourWeekHomeworkSubRate || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="近4周作业正确率">
          {{ studentLayeredData.lastFourWeekHomeworkCorrectRate || '-' }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions class="descriptions-wrap" title="分享信息">
        <a-descriptions-item label="近3个月分享">
          {{ studentLayeredData.threeMonthShare || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="上周分享">
          {{ studentLayeredData.lastWeekShare || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="本周分享">
          {{ studentLayeredData.currentWeekShare || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="近3个月进线">
          {{ studentLayeredData.threeMonthLines || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="近3个月节点及时率">
          {{ studentLayeredData.threeMonthTimeLinesRate || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="近3个月节点完成率">
          {{ studentLayeredData.threeMonthCompletionRate || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="更换班主任次数">
          {{ studentLayeredData.changeClassAdminTimes || '-' }}
        </a-descriptions-item>
      </a-descriptions>
    </div>
  </div>
</template>

<script>
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import { getLearnerHierarchy } from '@/api/headTeacher';

const session = storage.createStore(sessionStorage);

export default {
  name: 'studentLayered',
  components: {},
  data() {
    return {
      showLoading: true,
      studentProfile: session.get('studentProfile'),

      subject: 'CHINESE',
      studentId: '',
      studentLayeredData: {},
    };
  },
  methods: {
    changeSubject(e) {
      this.subject = e.target.value;
      this.getLearnerHierarchy();
    },
    getLearnerHierarchy() {
      const params = {
        studentId: this.studentId,
        subject: this.subject,
      };
      getLearnerHierarchy(params).then((res) => {
        this.showLoading = false;
        if (res.data.content === null) {
          this.studentLayeredData = {};
        } else {
          this.studentLayeredData = res.data.content;
        }
      });
    },
  },
  mounted() {
    this.studentId = this.$route.query.uuid || this.studentProfile.uuid;
    this.getLearnerHierarchy();
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-descriptions-item-label {
  color: rgba(31, 45, 61, 0.4);
}
/deep/ .ant-descriptions-item-content {
  color: #1f2d3d;
}
.classHour-top {
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
}
//修改编辑表单label颜色
/deep/ .ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.6);
}
/deep/ .ant-input,
.ant-select {
  color: #1f2d3d;
}
.loading-wrap {
  padding-top: 200px;
  text-align: center;
}
</style>
