<template>
  <div>
    <a-modal title="发送邮件" :visible="visible" width="730px" dialogClass="schedule-related" @cancel="onCancel(0)">
      <div class="insert-btn">
        <span class="insertTemp" @click="insertTemp">插入邮件模板</span>
      </div>
      <div class="email-item" style="display: flex; align-items: center">
        <div class="email-label">发件人：</div>
        <a-select v-model="emialForm" style="width: 240px">
          <a-select-option :value="i.value" v-for="i of emailFromOption" :key="i.label">
            {{ i.label }}
          </a-select-option>
        </a-select>
        <!-- <div>{{ this.userInfo.fullName }}{{ '<' }}{{ this.userInfo.email }}{{ '>' }}</div> -->
      </div>
      <div class="email-item">
        <div class="email-label">收件人：</div>
        <div>
          <span class="name-label">{{ studentInfo.fullName }}{{ '<' }}{{ studentInfo.email }}{{ '>' }}</span>
        </div>
      </div>
      <!-- <div class="email-item">
      <div class="email-label">抄送：</div>
      <div><span class="name-label">洋洋<130835896@qq.com></span></div>
    </div> -->
      <div class="email-item no-border">
        <div class="email-label">主题：</div>
        <div class="email-content">
          <el-input :clearable="true" placeholder="请输入" v-model="topSubject" :disabled="disableFlag" />
        </div>
      </div>
      <editor v-if="showEditor" v-model="tinymceHtml" :disabled="disableFlag" :init="init"></editor>
      <template slot="footer">
        <div class="footer-email">
          <div class="footer-emailBtn">
            <a-button @click="onCancel(0)">取消</a-button>
            <a-button type="primary" @click="submitEamil" :loading="showLoading">确认发送</a-button>
          </div>
        </div>
      </template>
    </a-modal>
    <a-modal title="插入邮件模板" :visible="visibleTemp" width="900px" :footer="null" @cancel="onCancelTemp(0)">
      <div style="margin-bottom: 10px">
        <a-input-search
          style="width: 230px"
          placeholder="搜索模板名称"
          v-model="tempName"
          enter-button="搜索"
          size="small"
          allowClear
          @search="onSearch"
        />
        <a-select
          size="small"
          v-model="courseType"
          placeholder="类型"
          style="width: 150px; margin-left: 15px"
          @change="handleCourseChange"
          allowClear
        >
          <a-select-option :value="item" :key="index" v-for="(item, index) in courseTypes">
            {{ item }}
          </a-select-option>
        </a-select>
        <a-select
          size="small"
          v-model="courseLevel"
          placeholder="级别"
          allowClear
          style="width: 120px; margin-left: 15px"
          @change="handleCourseChange"
        >
          <a-select-option :value="item" :key="index" v-for="(item, index) in courseLevels">
            {{ item }}
          </a-select-option>
        </a-select>
      </div>
      <a-table
        :rowKey="(record) => record.uuid"
        :maskClosable="false"
        :pagination="false"
        size="small"
        :scroll="{ y: 500 }"
        :columns="columns"
        :data-source="emailList"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="insertEmailTemp(record)" v-if="record.status === 'ACTIVE'">插入模板</a>
          <span v-else style="opacity: 0.5">插入模板</span>
        </span>
      </a-table>
    </a-modal>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import storage from 'store';
import Editor from '@tinymce/tinymce-vue';
import { imgpostUrl } from '@/api/register';
import 'tinymce';
import { SendEmail, getEmailTempList, getEmailSelItem, getUploadOssToken } from '@/api/headTeacher';
import 'tinymce/themes/silver/theme';
import 'tinymce/icons/default/icons.min.js';
import { RolesMap } from '@/utils/const.js';

import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/code';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/contextmenu';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/colorpicker';
import 'tinymce/plugins/textcolor';

const columns = [
  {
    title: '模板名称',
    dataIndex: 'templateName',
    width: 250,
    ellipsis: true,
  },
  {
    title: '类型',
    dataIndex: 'templateData.courseType',
    width: 180,
  },
  {
    title: '级别',
    dataIndex: 'templateData.courseLevel',
    width: 100,
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'center',
    scopedSlots: { customRender: 'action' },
  },
];
export default {
  name: 'emailEdit',
  components: {
    Editor,
  },
  props: {
    studentInfo: {
      type: Object,
      default: () => {},
    },
    classInfoData: {
      type: Object,
      default: () => {},
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emailFromOption: [],
      emialForm: '',
      showLoading: false,
      showEditor: false,
      templateCode: '',
      disableFlag: false,
      courseLevels: [],
      courseTypes: [],
      courseLevel: undefined,
      courseType: undefined,
      tempName: undefined,
      emailList: [],
      columns,
      visibleTemp: false,
      userInfo: {},
      topSubject: '',
      visibleEmail: false,
      tinymceHtml: '',
      disabled: false,
      tinymceId: 'email-editor',
      init: {
        language_url: '/tinymce/zh_CN.js', // 汉化路径是自定义的，一般放在public或static里面
        language: 'zh_CN',
        skin_url: '/tinymce/skins/ui/oxide', // 皮肤
        height: 500,
        plugins: 'link lists image code table colorpicker textcolor wordcount contextmenu',
        toolbar:
          'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | undo redo | link unlink image code | removeformat',
        branding: false,
      },
    };
  },
  computed: {
    ...mapState({
      studentProfile: (state) => {
        return state.student.studentProfile;
      },
    }),
  },
  watch: {
    visible(val) {
      if (val) {
        this.$nextTick(() => {
          this.showEditor = true;
        });
      }
    },
  },
  mounted() {
    this.userInfo = storage.get('userInfo');
    this.emialForm = this.userInfo.email;

    this.emailFromOption = [
      {
        label: this.userInfo.email,
        value: this.userInfo.email,
      },
    ];

    if (this.userInfo.roleArr.includes(RolesMap.TEACHING_STAFF)) {
      this.emailFromOption.push({
        label: 'subscription@wukongsch.com',
        value: 'subscription@wukongsch.com',
      });
    }
  },

  methods: {
    handleCourseChange() {
      this.onSearch();
    },
    // 获取邮件模板的下来下拉
    getEmailSelItem() {
      getEmailSelItem().then((res) => {
        this.courseTypes = res.data.content.courseTypes;
        this.courseLevels = res.data.content.courseLevels;
      });
    },
    insertEmailTemp(record) {
      console.log(record);
      if (record.unsubscribe) {
        this.$message.error('当前学员已退订邮件服务，暂不支持发送营销邮件');
        return;
      }

      const that = this;
      if (this.tinymceHtml) {
        this.disableFlag = true;
        this.$confirmAnt({
          title: '提示',
          content: '插入此模板将覆盖当前邮件',
          okText: '插入',
          cancelText: '取消',
          onOk() {
            that.templateCode = record.code;
            that.topSubject = record.title;
            that.tinymceHtml = record.content;

            that.onCancelTemp();
          },
          onCancel() {
            that.onCancelTemp();
          },
        });
      } else {
        this.disableFlag = false;
        this.topSubject = record.title;
        this.tinymceHtml = record.content;
        this.templateCode = record.code;
        this.onCancelTemp();
      }
    },
    insertTemp() {
      this.visibleTemp = true;
      this.onSearch();
      this.getEmailSelItem();
    },
    onCancelTemp() {
      this.disableFlag = false;
      this.visibleTemp = false;
    },
    onSearch() {
      const params = {
        studentCode: this.studentInfo.code,
        studentName: this.studentInfo.fullName,
        templateName: this.tempName,
        courseType: this.courseType,
        courseLevel: this.courseLevel,
        englishName: this.studentInfo.englishName,
      };
      getEmailTempList(params).then((res) => {
        this.emailList = res.data.content;
      });
    },
    uploadFile(info) {
      return getUploadOssToken().then((res) => {
        return this.uploadToOss(info, res.data.content);
      });
    },

    async uploadToOss(info, fileData) {
      const formData = new FormData();
      const formArray = Object.entries(fileData.formFields);
      formArray.forEach((item) => {
        formData.append(item[0], item[1]);
      });
      formData.append('file', info);

      return imgpostUrl(`https://${fileData.endpoint}`, formData).then((res) => {
        return res.data.url;
      });
    },

    uploadImage(src) {
      return fetch(src)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], 'file', { type: blob.type });
          console.log(file);
          return this.uploadFile(file);
        })
        .then((url) => {
          return url;
        });
    },
    async submitEamil() {
      if (!this.topSubject) {
        this.$message.warning('请输入邮件主题！');
        return;
      }
      if (this.topSubject.length > 200) {
        this.$message.warning('邮件主题不能超过50字！');
        return;
      }
      if (!this.tinymceHtml) {
        this.$message.warning('请输入邮件内容！');
        return;
      }
      this.showLoading = true;

      // 把图片上传到oss
      const d = document.createElement('div');
      d.innerHTML = this.tinymceHtml;
      const imgList = d.getElementsByTagName('img');
      const requestList = Array.prototype.map.call(imgList, (item) => {
        const { src } = item;
        if (src.indexOf('data:image') > -1) {
          // base64 图片操作
          return this.uploadImage(item.src).then((url) => {
            // eslint-disable-next-line no-param-reassign
            item.src = url;
          });
        }
        // path 图片操作
        return src;
      });
      await Promise.all(requestList);

      const params = {
        contentHtml: d.innerHTML,

        emailFrom: this.emialForm,
        emailTo: this.studentInfo.email,
        subject: this.topSubject,
        templateCode: this.templateCode,
        studentId: this.studentInfo.uuid,
      };
      SendEmail(params)
        .then((res) => {
          this.showLoading = false;
          this.tinymceHtml = '';
          this.topSubject = '';
          this.templateCode = '';
          this.disableFlag = false;
          this.$message.success('发送成功');
          this.$emit('onCancel');
        })
        .catch(() => {
          this.showLoading = false;
        });
    },
    onCancel() {
      this.$emit('onCancel');
    },
  },
  beforeDestroy() {},
};
</script>
<style lang="less" scoped>
/deep/ .el-input__inner {
  border: none !important;
  height: 20px;
}
/deep/ .el-input__suffix {
  top: -7px;
}

.email-item {
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}
.no-border {
  border: none;
}
.name-label {
  padding: 2px 10px;
  background-color: rgba(240, 240, 240, 1);
  border-radius: 10px;
}
.email-label {
  width: 60px;
}
.email-content {
  flex: 1;
}
.footer-email {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.send-emailName {
  text-align: left;
  flex: 1;
}
.footer-emailBtn {
  width: 340px;
}
.insertTemp {
  padding: 5px 10px;
  border: 1px solid #eee;
  border-radius: 10px;
  color: #43d186;
  font-size: 13px;
  cursor: pointer;
}
.insert-btn {
  position: absolute;
  right: 45px;
  top: 184px;
  z-index: 100;
}
</style>
