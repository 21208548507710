<template>
  <div class="tab-wrap">
    <div class="loading-wrap" v-show="showLoading">
      <a-spin size="large" />
    </div>
    <div v-show="!showLoading">
      <div v-show="!showEditData" style="position: relative">
        <div class="editDataBtn">
          <a-button type="primary" ghost @click="editPayStatus" style="margin-right: 10px"> 状态变更 </a-button>
          <a-button type="primary" ghost @click="editDataFun"> 编辑 </a-button>
        </div>
        <a-descriptions class="descriptions-wrap" title="基本信息">
          <a-descriptions-item label="中文昵称">
            {{ studentData.fullName || '-' }}
            <a-icon type="edit" style="color: #43d186; padding-left: 15px" @click="updateChineseName('chinese')" />
          </a-descriptions-item>
          <a-descriptions-item label="英文昵称">
            {{ studentData.data.EnglishName || '-' }}
            <a-icon type="edit" style="color: #43d186; padding-left: 15px" @click="updateChineseName('english')" />
          </a-descriptions-item>
          <a-descriptions-item label="所在时区">
            {{ timezoneName || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="学号">
            {{ studentData.code || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="性别">
            {{
              (studentData.gender &&
                (studentData.gender === 'M' ? '男' : studentData.gender === 'F' ? '女' : '不确定')) ||
              '-'
            }}
          </a-descriptions-item>
          <a-descriptions-item label="生日">
            {{ studentData.dateOfBirth || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="常驻国家/地区">
            {{ countryLabel || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="州/省">
            {{ stateLabel || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="城市">
            {{ cityLabel || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="当前年龄">
            {{ studentData.estCurrentAge || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="学生币种">
            {{ studentData.currency || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="母语">
            {{ studentData.data.language || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="沟通语言">
            {{ studentData.data.communicationLanguage || '-' }}
          </a-descriptions-item>
          <!-- <a-descriptions-item label="手机">
            {{ studentData.mobile || '-' }}
            <a-icon type="edit" style="color: #43d186; padding-left: 15px" @click="updateMobile" />
          </a-descriptions-item>
          <a-descriptions-item label="邮箱">
            {{ studentData.email || '-' }}
            <a-icon type="edit" style="color: #43d186; padding-left: 15px" @click="updateEmail" />
          </a-descriptions-item> -->
          <a-descriptions-item>
            <template v-slot:label>
              <span style="color: #04cb94; cursor: pointer; font-weight: bold" @click="showFamilyTelTc('tel')">
                家庭手机号
              </span>
            </template>
            <div v-if="studentData.familyContact && studentData.familyContact.mobiles">
              <div v-for="(item, index) in studentData.familyContact.mobiles" :key="index">
                <span>{{ item.mobile }}</span
                ><a-tag color="blue" style="margin-left: 15px" v-if="item.default">该学员默认</a-tag>
              </div>
            </div>
          </a-descriptions-item>
          <a-descriptions-item>
            <template v-slot:label>
              <span style="color: #04cb94; cursor: pointer; font-weight: bold" @click="showFamilyTelTc('email')">
                家庭邮箱
              </span>
            </template>
            <div v-if="studentData.familyContact && studentData.familyContact.emails">
              <div v-for="(item, index) in studentData.familyContact.emails" :key="index">
                <span>{{ item.email }}</span
                ><a-tag color="blue" style="margin-left: 15px" v-if="item.default">该学员默认</a-tag><br />
              </div>
            </div>
          </a-descriptions-item>
          <a-descriptions-item>
            <template v-slot:label>
              <span style="color: #04cb94; cursor: pointer; font-weight: bold" @click="showFamilyStudent">
                家庭学员
              </span>
            </template>
            <div>{{ studentData.familyStudentCount }}名学员</div>
          </a-descriptions-item>
          <a-descriptions-item label="服务渠道">
            {{ ServiceChannelEnumText[studentData.data.serviceChannel] || '-' }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions class="descriptions-wrap" title="账号信息">
          <a-descriptions-item label="学生状态-中文">
            {{ studentData.data.status || '-' }}
          </a-descriptions-item>
          <a-descriptions-item
            label="家长在意点-中文"
            v-if="studentData.data.status === '退费' || studentData.data.status === '不续费'"
          >
            {{ (studentData.data.carePoint && studentData.data.carePoint.join(',')) || '-' }}
          </a-descriptions-item>
          <a-descriptions-item
            label="激活优先级-中文"
            v-if="studentData.data.status === '退费' || studentData.data.status === '不续费'"
          >
            {{ studentData.data.activationPriority || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="学生状态-数学">
            {{ studentData.data.mathStatus || '-' }}
          </a-descriptions-item>
          <a-descriptions-item
            label="家长在意点-数学"
            v-if="studentData.data.mathStatus === '退费' || studentData.data.mathStatus === '不续费'"
          >
            {{ (studentData.data.mathCarePoint && studentData.data.mathCarePoint.join(',')) || '-' }}
          </a-descriptions-item>

          <a-descriptions-item
            label="激活优先级-数学"
            v-if="studentData.data.mathStatus === '退费' || studentData.data.mathStatus === '不续费'"
          >
            {{ studentData.data.mathActivationPriority }}
          </a-descriptions-item>
          <a-descriptions-item label="学生状态-英文">
            {{ studentData.data.englishStatus || '-' }}
          </a-descriptions-item>

          <!-- 新增 -->
          <a-descriptions-item label="中文订阅状态">
            {{ handleGetSubscribeStatus('CHINESE') }}
          </a-descriptions-item>
          <!-- 新增 -->
          <a-descriptions-item label="数学订阅状态">
            {{ handleGetSubscribeStatus('MATH') }}
          </a-descriptions-item>
          <!-- 新增 -->
          <a-descriptions-item label="英文订阅状态">
            {{ handleGetSubscribeStatus('ENGLISH') }}
          </a-descriptions-item>

          <a-descriptions-item
            label="家长在意点-英文"
            v-if="studentData.data.englishStatus === '退费' || studentData.data.englishStatus === '不续费'"
          >
            {{ (studentData.data.englishCarePoint && studentData.data.englishCarePoint.join(',')) || '-' }}
          </a-descriptions-item>

          <a-descriptions-item
            label="激活优先级-英文"
            v-if="studentData.data.englishStatus === '退费' || studentData.data.englishStatus === '不续费'"
          >
            {{ studentData.data.englishActivationPriority }}
          </a-descriptions-item>
          <a-descriptions-item label="微信OPENID">
            {{ studentData.data.openId || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="Classin上课账号">
            {{ studentData.data.class_in_account || '-' }}
            <a-icon type="edit" style="color: #43d186; padding-left: 15px" @click="updateClassin" />
          </a-descriptions-item>
          <a-descriptions-item label="ClassIn同步UUID">
            {{ studentData.data.class_in_id || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="StudentId">
            {{ studentData.uuid || '-' }}
          </a-descriptions-item>

          <a-descriptions-item v-for="(item, index) in studentData.familyMember" :key="index">
            <template v-slot:label>
              <span>
                {{ `学员APP账号${index + 1}` }}
              </span>
            </template>
            <span style="display: flex; align-items: flex-start">
              <div>
                <span v-if="item.email"
                  >{{ item.email }}
                  <a-button
                    :loading="sendEmailLoading"
                    v-if="!item.parentVerification"
                    type="link"
                    @click="sendEmail(item.contactId)"
                    >一键发送</a-button
                  >
                  <br
                /></span>
                <span>{{ item.mobile }}</span>
              </div>
              <a-tag color="blue" style="margin-left: 15px" v-if="item.parentVerification">已验证</a-tag>
            </span>
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions class="descriptions-wrap" title="学生可用时间">
          <a-descriptions-item label="">
            <p class="time-zone"><span style="color: rgba(31, 45, 61, 0.4)">北京时区：</span>(GMT+8:00)beijing</p>
            <div class="info-main" v-if="studentData.data && studentData.data.userTime && studentData.data.userTime.my">
              <div class="info-time" v-if="studentData.data.userTime.my.mondayTime">
                <span>每周一</span>
                <span>{{ studentData.data.userTime.my.mondayTime }}</span>
              </div>
              <div class="info-time" v-if="studentData.data.userTime.my.tuesdayTime">
                <span>每周二</span>
                <span>{{ studentData.data.userTime.my.tuesdayTime }}</span>
              </div>
              <div class="info-time" v-if="studentData.data.userTime.my.wednesdayTime">
                <span>每周三</span>
                <span>{{ studentData.data.userTime.my.wednesdayTime }}</span>
              </div>
              <div class="info-time" v-if="studentData.data.userTime.my.thursdayTime">
                <span>每周四</span>
                <span>{{ studentData.data.userTime.my.thursdayTime }}</span>
              </div>
              <div class="info-time" v-if="studentData.data.userTime.my.fridayTime">
                <span>每周五</span>
                <span>{{ studentData.data.userTime.my.fridayTime }}</span>
              </div>
              <div class="info-time" v-if="studentData.data.userTime.my.saturdayTime">
                <span>每周六</span>
                <span>{{ studentData.data.userTime.my.saturdayTime }}</span>
              </div>
              <div class="info-time" v-if="studentData.data.userTime.my.sundayTime">
                <span>每周天</span>
                <span>{{ studentData.data.userTime.my.sundayTime }}</span>
              </div>
            </div>
          </a-descriptions-item>
          <a-descriptions-item label="">
            <span class="time-zone"
              ><span style="color: rgba(31, 45, 61, 0.4)">学生时区：</span>{{ timezoneName }}</span
            >
            <div class="info-main" v-if="studentData.data && studentData.data.userTime && studentData.data.userTime.my">
              <div class="info-time" v-if="studentData.data.userTime.student.mondayTimeLocal">
                <span>每周一</span>
                <span>{{ studentData.data.userTime.student.mondayTimeLocal }}</span>
              </div>
              <div class="info-time" v-if="studentData.data.userTime.student.tuesdayTimeLocal">
                <span>每周二</span>
                <span>{{ studentData.data.userTime.student.tuesdayTimeLocal }}</span>
              </div>
              <div class="info-time" v-if="studentData.data.userTime.student.wednesdayTimeLocal">
                <span>每周三</span>
                <span>{{ studentData.data.userTime.student.wednesdayTimeLocal }}</span>
              </div>
              <div class="info-time" v-if="studentData.data.userTime.student.thursdayTimeLocal">
                <span>每周四</span>
                <span>{{ studentData.data.userTime.student.thursdayTimeLocal }}</span>
              </div>
              <div class="info-time" v-if="studentData.data.userTime.student.fridayTimeLocal">
                <span>每周五</span>
                <span>{{ studentData.data.userTime.student.fridayTimeLocal }}</span>
              </div>
              <div class="info-time" v-if="studentData.data.userTime.student.saturdayTimeLocal">
                <span>每周六</span>
                <span>{{ studentData.data.userTime.student.saturdayTimeLocal }}</span>
              </div>
              <div class="info-time" v-if="studentData.data.userTime.student.sundayTimeLocal">
                <span>每周天</span>
                <span>{{ studentData.data.userTime.student.sundayTimeLocal }}</span>
              </div>
            </div>
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions class="descriptions-wrap" title="邮寄地址">
          <a-descriptions-item label="收件人姓名">
            {{ studentData.data.recipientName || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="收件人电话">
            {{ studentData.data.recipientPhone || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="发货地址">
            <span v-if="studentData.data.address"
              >{{ sendAddress.street || '-' }} {{ sendAddress.city || '-' }} {{ sendAddress.state || '-' }}
              {{ sendAddress.country || '-' }} {{ studentData.data.address.postalCode || '' }}
            </span>
            <span v-else> - </span>
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <div class="edit-info" v-show="showEditData">
        <a-form
          class="ant-advanced-search-form"
          autocomplete="false"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 16 }"
          :form="form"
        >
          <InfoTitle title="基本信息" />
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-item label="中文昵称">
                <a-input
                  disabled
                  placeholder="中文昵称"
                  v-decorator="['fullName', { rules: [{ required: true, message: '请输入姓名!' }] }]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="英文昵称">
                <a-input disabled placeholder="英文昵称" v-decorator="['data.EnglishName']" />
              </a-form-item>
            </a-col>

            <a-col :span="8">
              <a-form-item label="学号">
                <a-input placeholder="学号" v-decorator="['code', {}]" disabled />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="性别">
                <a-select
                  v-decorator="['gender', { rules: [{ required: true, message: '请选择性别!' }] }]"
                  placeholder="性别"
                >
                  <a-select-option value="M"> 男 </a-select-option>
                  <a-select-option value="F"> 女 </a-select-option>
                  <a-select-option value="U">不确定 </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="8">
              <a-form-item label="生日">
                <a-date-picker
                  @change="onChangeBirth"
                  v-decorator="['dateOfBirth']"
                  style="width: 100%"
                  format="YYYY-MM-DD"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="常驻国家/地区">
                <a-select
                  allowClear
                  show-search
                  :disabled="disabledFlag"
                  placeholder="常驻国家/地区"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  @change="handleCountryChange"
                  v-decorator="['country', { rules: [{ required: true, message: '请选择常驻国家/地区!' }] }]"
                  :options="countryOptions"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="所在时区">
                <a-select
                  show-search
                  placeholder="所在时区"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-decorator="['timezoneStd', { rules: [{ required: true, message: '请选择所在时区!' }] }]"
                >
                  <a-select-option :value="i.value" v-for="(i, index) of timezoneOptions" :key="index">
                    {{ i.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="州/省">
                <a-select
                  show-search
                  placeholder="请选择"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  @change="handleProvinceChange"
                  v-decorator="['data.state', { rules: [{ required: true, message: '请选择所在州/省!' }] }]"
                >
                  <a-select-option :value="i.value" v-for="(i, index) of provinceOptions" :key="index">
                    {{ i.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="城市">
                <a-select
                  show-search
                  placeholder="请选择"
                  option-filter-prop="children"
                  :filter-option="cityFilterOption"
                  v-decorator="['data.city', { rules: [{ required: true, message: '请选择所在城市!' }] }]"
                  @search="handleCitySearch"
                  @change="handleCityChange"
                  :loading="cityLoading"
                >
                  <a-select-option :value="i.key" v-for="i of cityOptions" :item="i" :key="i.key">
                    {{ i.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <!-- 这个不敢动 -->
              <a-form-item label="当前年龄" style="display: none">
                <a-input disabled placeholder="当前年龄" v-decorator="['currentAge', {}]" />
              </a-form-item>
              <a-form-item label="当前年龄">
                <a-input-number style="width: 100%" placeholder="当前年龄" v-decorator="['estCurrentAge', {}]" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="学生币种">
                <a-select
                  show-search
                  placeholder="学生币种"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-decorator="['currency', { rules: [{ required: true, message: '请选择学生币种!' }] }]"
                >
                  <a-select-option :value="i.value" v-for="i of currency" :key="i.label">
                    {{ i.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="母语">
                <a-select v-decorator="['data.language', {}]" placeholder="请选择">
                  <a-select-option value="中文普通话"> 中文普通话 </a-select-option>
                  <a-select-option value="英语"> 英语 </a-select-option>
                  <a-select-option value="日语"> 日语 </a-select-option>
                  <a-select-option value="韩语"> 韩语 </a-select-option>
                  <a-select-option value="法语"> 法语 </a-select-option>
                  <a-select-option value="德语"> 德语 </a-select-option>
                  <a-select-option value="印尼语"> 印尼语 </a-select-option>
                  <a-select-option value="其他"> 其他 </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="沟通语言">
                <a-select v-decorator="['data.communicationLanguage', {}]" placeholder="请选择">
                  <a-select-option :value="item.value" v-for="(item, index) in languageArr" :key="index">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="24" v-if="false">
            <a-col :span="8">
              <a-form-item label="中文班主任">
                <a-select
                  show-search
                  placeholder="中文班主任"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  allowClear
                  disabled
                  v-decorator="['classAdmin.CHINESE.accountId', {}]"
                >
                  <a-select-option :value="i.accountId" v-for="i of classAdmin" :key="i.fullName">
                    {{ i.fullName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="数学辅导老师">
                <a-select
                  show-search
                  placeholder="数学辅导老师"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  allowClear
                  disabled
                  v-decorator="['classAdmin.MATH.accountId', {}]"
                >
                  <a-select-option :value="i.accountId" v-for="i of classAdmin" :key="i.fullName">
                    {{ i.fullName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="中文学习规划师">
                <a-select
                  show-search
                  placeholder="中文学习规划师"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  allowClear
                  disabled
                  v-decorator="['data.courseConsultant.CHINESE.accountId', {}]"
                >
                  <a-select-option :value="i.accountId" v-for="i of consultant" :key="i.fullName">
                    {{ i.fullName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="数学学习规划师">
                <a-select
                  show-search
                  placeholder="数学学习规划师"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  allowClear
                  disabled
                  v-decorator="['data.courseConsultant.MATH.accountId', {}]"
                >
                  <a-select-option :value="i.accountId" v-for="i of consultant" :key="i.fullName">
                    {{ i.fullName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="推荐人">
                <a-input placeholder="推荐人" disabled v-decorator="['data.recommend', {}]" />
              </a-form-item>
            </a-col>
          </a-row>
          <InfoTitle title="账号信息" />
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-item label="Classin上课账号">
                <a-input disabled placeholder="placeholder" v-decorator="['data.class_in_account']" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="Classin同步UUID">
                <a-input disabled placeholder="placeholder" v-decorator="['data.class_in_id', {}]" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="学生状态(中文)">
                <a-select
                  @change="changChineseStatus"
                  :disabled="upStudentStatusFlag"
                  show-search
                  placeholder="学生状态"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  allowClear
                  v-decorator="['data.status', {}]"
                >
                  <a-select-option :value="i" v-for="i of statusArray" :key="i">
                    {{ i }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8" v-if="studentStatus === '退费' || studentStatus === '不续费'">
              <a-form-item label="家长在意点(中文)">
                <a-select
                  show-search
                  mode="multiple"
                  placeholder="家长在意点"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  allowClear
                  v-decorator="['data.carePoint', { rules: [{ required: true, message: '请选择家长在意点!' }] }]"
                >
                  <a-select-option :value="i" v-for="i of parentsCareArr" :key="i">
                    {{ i }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8" v-if="studentStatus === '退费' || studentStatus === '不续费'">
              <a-form-item label="激活优先级(中文)">
                <a-select
                  show-search
                  placeholder="激活优先级"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  allowClear
                  v-decorator="[
                    'data.activationPriority',
                    { rules: [{ required: true, message: '请选择激活优先级!' }] },
                  ]"
                >
                  <a-select-option :value="i" v-for="i of activationArr" :key="i">
                    {{ i }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="学生状态(数学)">
                <a-select
                  @change="changMathStatus"
                  :disabled="upStudentStatusFlag"
                  show-search
                  placeholder="学生状态"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  allowClear
                  v-decorator="['data.mathStatus', {}]"
                >
                  <a-select-option :value="i" v-for="i of statusArray" :key="i">
                    {{ i }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="8" v-if="studentMathStatus === '退费' || studentMathStatus === '不续费'">
              <a-form-item label="家长在意点(数学)">
                <a-select
                  show-search
                  mode="multiple"
                  placeholder="家长在意点"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  allowClear
                  v-decorator="['data.mathCarePoint', { rules: [{ required: true, message: '请选择家长在意点!' }] }]"
                >
                  <a-select-option :value="i" v-for="i of parentsCareArr" :key="i">
                    {{ i }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="8" v-if="studentMathStatus === '退费' || studentMathStatus === '不续费'">
              <a-form-item label="激活优先级(数学)">
                <a-select
                  show-search
                  placeholder="激活优先级"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  allowClear
                  v-decorator="[
                    'data.mathActivationPriority',
                    { rules: [{ required: true, message: '请选择激活优先级!' }] },
                  ]"
                >
                  <a-select-option :value="i" v-for="i of activationArr" :key="i">
                    {{ i }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="8">
              <a-form-item label="学生状态(英文)">
                <a-select
                  show-search
                  @change="changEnglishStatus"
                  :disabled="upStudentStatusFlag"
                  placeholder="学生状态"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  allowClear
                  v-decorator="['data.englishStatus', {}]"
                >
                  <a-select-option :value="i" v-for="i of statusArray" :key="i">
                    {{ i }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8" v-if="studentEnglishStatus === '退费' || studentEnglishStatus === '不续费'">
              <a-form-item label="家长在意点(英文)">
                <a-select
                  show-search
                  mode="multiple"
                  placeholder="家长在意点"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  allowClear
                  v-decorator="['data.englishCarePoint', { rules: [{ required: true, message: '请选择家长在意点!' }] }]"
                >
                  <a-select-option :value="i" v-for="i of parentsCareArr" :key="i">
                    {{ i }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="8" v-if="studentEnglishStatus === '退费' || studentEnglishStatus === '不续费'">
              <a-form-item label="激活优先级(英文)">
                <a-select
                  show-search
                  placeholder="激活优先级"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  allowClear
                  v-decorator="[
                    'data.englishActivationPriority',
                    { rules: [{ required: true, message: '请选择激活优先级!' }] },
                  ]"
                >
                  <a-select-option :value="i" v-for="i of activationArr" :key="i">
                    {{ i }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <InfoTitle title="学生可用时间" />
          <P>北京时区</P>
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-item label="每周一">
                <a-select
                  mode="multiple"
                  v-decorator="['data.userTime.my.mondayTime', {}]"
                  placeholder="请选择北京时区"
                  :options="timeOptions"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="每周二">
                <a-select
                  mode="multiple"
                  v-decorator="['data.userTime.my.tuesdayTime', {}]"
                  placeholder="请选择北京时区"
                  :options="timeOptions"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="每周三">
                <a-select
                  mode="multiple"
                  v-decorator="['data.userTime.my.wednesdayTime', {}]"
                  placeholder="请选择北京时区"
                  :options="timeOptions"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="每周四">
                <a-select
                  mode="multiple"
                  v-decorator="['data.userTime.my.thursdayTime', {}]"
                  placeholder="请选择北京时区"
                  :options="timeOptions"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="每周五">
                <a-select
                  mode="multiple"
                  v-decorator="['data.userTime.my.fridayTime', {}]"
                  placeholder="请选择北京时区"
                  :options="timeOptions"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="每周六">
                <a-select
                  mode="multiple"
                  v-decorator="['data.userTime.my.saturdayTime', {}]"
                  placeholder="请选择北京时区"
                  :options="timeOptions"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="每周天">
                <a-select
                  mode="multiple"
                  v-decorator="['data.userTime.my.sundayTime', {}]"
                  placeholder="请选择北京时区"
                  :options="timeOptions"
                >
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <p>学生时区：</p>
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-item label="每周一">
                <a-select
                  mode="multiple"
                  v-decorator="['data.userTime.student.mondayTimeLocal', {}]"
                  placeholder="请选择学生时区"
                  :options="timeOptions"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="每周二">
                <a-select
                  mode="multiple"
                  v-decorator="['data.userTime.student.tuesdayTimeLocal', {}]"
                  placeholder="请选择学生时区"
                  :options="timeOptions"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="每周三">
                <a-select
                  mode="multiple"
                  v-decorator="['data.userTime.student.wednesdayTimeLocal', {}]"
                  placeholder="请选择学生时区"
                  :options="timeOptions"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="每周四">
                <a-select
                  mode="multiple"
                  v-decorator="['data.userTime.student.thursdayTimeLocal', {}]"
                  placeholder="请选择学生时区"
                  :options="timeOptions"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="每周五">
                <a-select
                  mode="multiple"
                  v-decorator="['data.userTime.student.fridayTimeLocal', {}]"
                  placeholder="请选择学生时区"
                  :options="timeOptions"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="每周六">
                <a-select
                  mode="multiple"
                  v-decorator="['data.userTime.student.saturdayTimeLocal', {}]"
                  placeholder="请选择学生时区"
                  :options="timeOptions"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="每周天">
                <a-select
                  mode="multiple"
                  v-decorator="['data.userTime.student.sundayTimeLocal', {}]"
                  placeholder="请选择学生时区"
                  :options="timeOptions"
                >
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <InfoTitle title="邮寄地址" />
          <a-row :gutter="24" style="margin-bottom: 48px">
            <a-col :span="8">
              <a-form-item label="收件人姓名">
                <a-input placeholder="收件人姓名" v-decorator="['data.recipientName', {}]" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="收件人电话">
                <a-input placeholder="收件人电话" v-decorator="['data.recipientPhone', {}]" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="收货国家/地区">
                <a-select
                  allowClear
                  show-search
                  placeholder="收货国家/地区"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  @change="handleSendCountryChange"
                  v-decorator="['data.address.country']"
                >
                  <a-select-option :value="i.value" v-for="(i, index) of sendCountryOptions" :key="index">
                    {{ i.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="州/省">
                <a-select
                  show-search
                  placeholder="请选择"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  @change="handleSendProvinceChange"
                  v-decorator="['data.address.state']"
                  :options="sendProvinceOptions"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="城市">
                <a-select
                  show-search
                  placeholder="请选择"
                  option-filter-prop="children"
                  v-decorator="['data.address.city']"
                  @search="handleSendCitySearch"
                  @change="handleSendCityChange"
                  :filter-option="cityFilterOption"
                  :loading="sendCityLoading"
                >
                  <a-select-option :value="i.key" v-for="(i, index) of sendCityOptions" :item="i">
                    {{ i.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="街道">
                <a-input placeholder="街道" v-decorator="['data.address.street', {}]" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="邮政编码">
                <a-input placeholder="邮政编码" v-decorator="['data.address.postalCode', {}]" />
              </a-form-item>
            </a-col>
          </a-row>
          <!-- <InfoTitle title="其他" /> -->
          <a-row :gutter="24" v-if="false">
            <a-col :span="8">
              <a-form-item label="创建人">
                <a-input placeholder="创建人" disabled v-decorator="['data.createdById.Name', {}]" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="创建时间">
                <a-date-picker @change="onChange" disabled v-decorator="['data.createdDate', {}]" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="学生所有人">
                <a-input placeholder="学生所有人" disabled v-decorator="['owner', {}]" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <div class="edit-btn">
          <a-button @click="cancelData"> 取消 </a-button>
          <a-button type="primary" @click="onSaveData"> 保存编辑 </a-button>
        </div>
      </div>
      <a-modal
        title="修改手机号"
        :visible="visibleTel"
        @ok="saveTel"
        @cancel="cancelTel"
        okText="保存"
        cancelText="取消"
      >
        <a-form :form="formMobile" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }">
          <a-form-item label="原手机号" @copy.native.capture.prevent="handCopy">
            <a-input
              disabled
              v-decorator="[
                'mobile',
                {
                  rules: [{}],
                },
              ]"
              style="width: 100%"
            >
              <a-select
                disabled
                slot="addonBefore"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                v-decorator="['areaCode', { initialValue: '+86' }]"
                style="width: 120px"
              >
                <a-select-option :value="i" v-for="i of areaCodeArr" :key="i">
                  {{ i }}
                </a-select-option>
              </a-select>
            </a-input>
          </a-form-item>
          <a-form-item label="新手机号">
            <a-input
              v-decorator="[
                'newMobile',
                {
                  rules: [{ required: true, message: '请输入手机号码!' }],
                },
              ]"
              style="width: 100%"
            >
              <a-select
                slot="addonBefore"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                v-decorator="['newAreaCode', { initialValue: '+86' }]"
                style="width: 120px"
              >
                <a-select-option :value="i" v-for="i of areaCodeArr" :key="i">
                  {{ i }}
                </a-select-option>
              </a-select>
            </a-input>
          </a-form-item>
        </a-form>
      </a-modal>
      <a-modal
        title="修改邮箱"
        :visible="visibleEmail"
        @ok="saveEamil"
        @cancel="cancelEamil"
        okText="保存"
        cancelText="取消"
      >
        <a-form :form="formEmail" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }">
          <a-form-item label="原邮箱" @copy.native.capture.prevent="handCopy">
            <a-input
              disabled
              v-decorator="[
                'oldEmail',
                {
                  rules: [{}],
                },
              ]"
              style="width: 100%"
            >
            </a-input>
          </a-form-item>
          <a-form-item label="新邮箱">
            <a-input
              v-decorator="[
                'newEmail',
                {
                  rules: [{ required: true, message: '请输入邮箱!' }],
                },
              ]"
              style="width: 100%"
            >
            </a-input>
          </a-form-item>
        </a-form>
      </a-modal>
      <a-modal
        title="修改classin账号"
        :visible="visibleClassin"
        @ok="saveClassin"
        @cancel="cancelClassin"
        okText="保存"
        cancelText="取消"
      >
        <a-form :form="formClassin" :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }">
          <div style="padding: 0 0 5px 6px">
            将原classin<span style="font-weight: bold"> {{ this.studentData.data.class_in_account }} </span>账号修改为
          </div>
          <a-form-item label="新classin账号">
            <a-input
              placeholder="请输入"
              v-decorator="[
                'newClass',
                {
                  rules: [{ required: true, message: '请输入classin账号!' }],
                },
              ]"
              style="width: 100%"
            >
              <a-select
                placeholder="区号"
                slot="addonBefore"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                v-decorator="['newClassCode']"
                style="width: 120px"
              >
                <a-select-option :value="i" v-for="i in areaCodeArrLabel" :key="i">
                  {{ i }}
                </a-select-option>
              </a-select>
            </a-input>
          </a-form-item>
        </a-form>
      </a-modal>
      <a-modal
        title="修改昵称"
        :visible="visibleChineseName"
        @ok="saveChineseName"
        @cancel="cancelChineseName"
        okText="保存"
        cancelText="取消"
      >
        <a-form :form="formName" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }">
          <a-form-item label="中文昵称">
            <a-input
              disabled
              v-decorator="[
                'oldChineseName',
                {
                  rules: [{}],
                },
              ]"
              style="width: 100%"
            >
            </a-input>
          </a-form-item>
          <a-form-item label="新中文昵称">
            <a-input v-decorator="['chineseName']" style="width: 100%" placeholder="请输入"> </a-input>
          </a-form-item>
          <a-form-item label="英文昵称">
            <a-input
              disabled
              v-decorator="[
                'oldEnglishName',
                {
                  rules: [{}],
                },
              ]"
              style="width: 100%"
            >
            </a-input>
          </a-form-item>
          <a-form-item label="新英文昵称">
            <a-input placeholder="请输入" v-decorator="['englishName']" style="width: 100%"> </a-input>
          </a-form-item>
        </a-form>

        <!-- <div  v-if="newNameFlag==='chinese'">注意：只可输入文字，不能包含特殊字符，不能超过6个字</div>
        <div  v-else>注意：只可输入英文字母，不能包含特殊字符，不能超过16个字符</div> -->
      </a-modal>
      <a-modal :title="familyTitle" :visible="visibleFamilyTel" :footer="null" @cancel="visibleFamilyTel = false">
        <div style="margin-bottom: 15px" v-if="familyType === 'tel'">
          家庭手机号
          <a-button style="margin: 0 15px 0 5px" type="primary" size="small" @click="addTelFun">新增手机号</a-button
          ><span>每个学员只可设置一个默认手机号</span>
        </div>
        <div style="margin-bottom: 15px" v-if="familyType === 'email'">
          家庭邮箱
          <a-button style="margin: 0 15px 0 5px" type="primary" size="small" @click="addTelFun">新增邮箱</a-button
          ><span>每个学员只可设置一个默认邮箱</span>
        </div>
        <a-table
          size="small"
          :scroll="{ y: 600 }"
          :columns="familyColumns"
          :data-source="familyData"
          :pagination="false"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template slot="mobile" slot-scope="mobile, record, index">
            {{ mobile }}
            <a-icon
              :type="mobile === record.realMobile ? 'eye-invisible' : 'eye'"
              @click="lookFamilyContactLogMobile('LOOK_STUDENT_MOBILE', record, index)"
            />
          </template>
          <template slot="email" slot-scope="email, record, index">
            {{ email }}
            <a-icon
              :type="email === record.realEmail ? 'eye-invisible' : 'eye'"
              @click="lookFamilyContactLogEmail('LOOK_STUDENT_EMAIL', record, index)"
            />
          </template>
          <template slot="operation" slot-scope="operation, record">
            <a style="margin-right: 10px" @click="updateTelFun(record)">修改</a>
            <a style="margin-right: 10px" @click="deleteItem(record)">删除</a>
            <a style="margin-right: 10px" v-if="!record.default" @click="setFamilyContact(record)">设为该学员默认</a>
            <span style="margin-right: 10px" v-if="record.default">该学员默认</span>
          </template>
        </a-table>
      </a-modal>
      <a-modal
        :title="telTitle"
        :visible="visibleAddTel"
        @ok="addFamilyMobile"
        @cancel="cancelFamilyMobile"
        okText="保存"
        cancelText="取消"
      >
        <a-form :form="familyMobile" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }">
          <a-form-item label="家庭手机号" v-if="familyType === 'tel'">
            <a-input
              v-decorator="[
                'newMobile',
                {
                  rules: [{ required: true, message: '请输入手机号码!' }],
                },
              ]"
              style="width: 100%"
            >
              <a-select
                slot="addonBefore"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                v-decorator="['newAreaCode', { initialValue: '+86' }]"
                style="width: 120px"
              >
                <a-select-option :value="i" v-for="i of areaCodeArr" :key="i"> {{ i }} </a-select-option>
              </a-select>
            </a-input>
          </a-form-item>
          <a-form-item label="家庭邮箱" v-if="familyType === 'email'">
            <a-input
              v-decorator="[
                'newEmail',
                {
                  rules: [{ required: true, message: '请输入邮箱!' }],
                },
              ]"
              style="width: 100%"
            >
            </a-input>
          </a-form-item>
          <div style="padding-left: 14px" v-if="showDefault">
            设为该学员默认<span v-if="familyType === 'tel'">手机号</span><span v-if="familyType === 'email'">邮箱</span
            ><a-switch size="small" v-model="switchTelFlag" style="margin-left: 5px" />
          </div>
        </a-form>
      </a-modal>
      <a-modal
        title="学员家庭详情"
        :visible="visibleFamilyStudent"
        :footer="null"
        @cancel="visibleFamilyStudent = false"
        width="980px"
      >
        <div style="margin-bottom: 15px" v-if="familyType === 'email'">
          该学员所在家庭共{{ studentData.familyStudentCount }}名学员，家庭学员为：
        </div>
        <a-table
          size="small"
          :scroll="{ y: 600 }"
          :columns="familyStudentColumns"
          :data-source="familyStudentData"
          :pagination="false"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template slot="fullName" slot-scope="fullName, record">
            {{ `${fullName}(${record.code})` }}
          </template>
          <template slot="operation" slot-scope="operation, record">
            <a @click="hrefStudentDetail(record)">详情</a>
          </template>
        </a-table>
      </a-modal>
    </div>
    <change-pay-status :payObj="payObj" v-if="showPayStatus" @cancelPayStatus="cancelPayStatus"></change-pay-status>
  </div>
</template>

<script>
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import InfoTitle from '@/components/WkInfoTitle/InfoTitle';
import changePayStatus from './changePayStatus';
import { postReq, sendVerifyEmail } from '@/api/schedule';
import { ServiceChannelEnumText } from '@/enum/index';
import {
  getTimezoneOptions,
  getProvinceOptions,
  getCityOptions,
  getCountryOptions,
  getLabelFromOptions,
  getTimezoneName,
  getLocationNames,
  isUnknownOption,
} from '@/utils/geoLocation';

import {
  queryTelEmail,
  updateSelfTel,
  queryStudentDetailData,
  currency,
  countries,
  editStudent,
  classAdmin,
  consultant,
  modifyName,
  addFamilyContact,
  updateFamilyContact,
  deleteFamilyContact,
  setFamilyContact,
  lookFamilyContactLog,
  querySubscribeInfoApi,
} from '@/api/headTeacher';
import debounce from 'lodash.debounce';

const session = storage.createStore(sessionStorage);
export default {
  name: 'studentData',
  components: { InfoTitle, changePayStatus },
  data() {
    return {
      ServiceChannelEnumText,
      // 订阅信息
      subscribeInfo: [],
      subscribeOptions: [
        { label: '已订阅', value: 'SUBSCRIBED' },
        { label: '未订阅', value: 'UN_SUBSCRIBED' },
        { label: '已取消', value: 'CANCELLED' },
      ],
      timeOptions: [
        {
          value: '06:00-07:00',
          label: '06:00-07:00',
        },
        {
          value: '07:00-08:00',
          label: '07:00-08:00',
        },
        {
          value: '08:00-09:00',
          label: '08:00-09:00',
        },
        {
          value: '09:00-10:00',
          label: '09:00-10:00',
        },
        {
          value: '10:00-11:00',
          label: '10:00-11:00',
        },
        {
          value: '11:00-12:00',
          label: '11:00-12:00',
        },
        {
          value: '12:00-13:00',
          label: '12:00-13:00',
        },
        {
          value: '13:00-14:00',
          label: '13:00-14:00',
        },
        {
          value: '14:00-15:00',
          label: '14:00-15:00',
        },
        {
          value: '15:00-16:00',
          label: '15:00-16:00',
        },
        {
          value: '16:00-17:00',
          label: '16:00-17:00',
        },
        {
          value: '17:00-18:00',
          label: '17:00-18:00',
        },
        {
          value: '18:00-19:00',
          label: '18:00-19:00',
        },
        {
          value: '19:00-20:00',
          label: '19:00-20:00',
        },
        {
          value: '20:00-21:00',
          label: '20:00-21:00',
        },
        {
          value: '21:00-22:00',
          label: '21:00-22:00',
        },
        {
          value: '22:00-23:00',
          label: '22:00-23:00',
        },
        {
          value: '23:00-24:00',
          label: '23:00-24:00',
        },
      ],

      familyStudentData: [],
      familyStudentColumns: [
        {
          title: '家庭学员',
          dataIndex: 'fullName',
          key: 'fullName',
          scopedSlots: { customRender: 'fullName' },
          width: 200,
        },
        {
          title: '中文状态',
          dataIndex: 'status',
          key: 'status',
        },
        {
          title: '中文班主任',
          dataIndex: 'classAdminName',
          key: 'classAdminName',
        },
        {
          title: '数学状态',
          dataIndex: 'mathStatus',
          key: 'mathStatus',
        },
        {
          title: '数学辅导老师',
          dataIndex: 'mathClassAdminName',
          key: 'mathClassAdminName',
        },
        {
          title: '英文状态',
          dataIndex: 'englishStatus',
          key: 'englishStatus',
        },
        {
          title: '英文辅导老师',
          dataIndex: 'englishClassAdminName',
          key: 'englishClassAdminName',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          width: 80,
          scopedSlots: { customRender: 'operation' },
        },
      ],
      visibleFamilyStudent: false,
      languageArr: [
        { label: '中文', value: '中文' },
        { label: '中文(粤语)', value: '中文(粤语)' },
        { label: '英语', value: '英语' },
        { label: '日语', value: '日语' },
        { label: '韩语', value: '韩语' },
        { label: '法语', value: '法语' },
        { label: '德语', value: '德语' },
        { label: '印尼语', value: '印尼语' },
        { label: '其他', value: '其他' },
      ],
      showDefault: false,
      familyTitle: '家庭手机号',
      familyType: 'tel',
      contactId: '',
      telTitle: '新增家庭手机号',
      switchTelFlag: false,
      visibleAddTel: false,
      familyColumns: [],
      familyTelColumns: [
        {
          title: '家庭手机号',
          dataIndex: 'mobile',
          key: 'mobile',
          scopedSlots: { customRender: 'mobile' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',

          scopedSlots: { customRender: 'operation' },
        },
      ],
      familyEmailColumns: [
        {
          title: '家庭邮箱',
          dataIndex: 'email',
          key: 'email',
          scopedSlots: { customRender: 'email' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',

          scopedSlots: { customRender: 'operation' },
        },
      ],
      familyTelData: [],
      familyData: [],
      familyEmailData: [],
      visibleFamilyTel: false,
      studentStatus: '',
      studentMathStatus: '',
      studentEnglishStatus: '',
      activationArr: ['待确认', '高', '中', '低', '无'],
      parentsCareArr: [
        '待确认',
        '价格活动',
        '学员兴趣',
        '学员时间',
        '规则-分享赠课',
        '服务-家长感受',
        '服务-学员学习',
        '学习效果-书写',
        '学习效果-认字',
        '学习效果-听说',
        '学习效果-作文',
        '学习效果-作业',
        '学习效果-阅读',
      ],
      disabledFlag: true,
      visibleChineseName: false,
      areaCodeArrLabel: [],
      visibleClassin: false,
      payObj: {},
      showPayStatus: false,
      showLoading: false,
      studentProfile: session.get('studentProfile'),
      upStudentStatusFlag: true,
      statusArray: [
        // '无',
        '已付费',
        // 'PlusOne学员（未付费）',
        '冻结课程',
        '延期开课',
        '课时转移',
        '锁定待激活',
        '不续费',
        '退费',
        '特殊通道',
        // '未付费',
      ],
      userInfo: {},
      form: this.$form.createForm(this),
      formClassin: this.$form.createForm(this),
      timezones: null,
      currency: null,
      countries: null,
      areaCodeArr: [],
      classAdmin: null,
      consultant: null,
      studentData: {
        data: {},
      }, // 资料

      studentId: '',
      showEditData: false,
      visibleTel: false,
      formMobile: this.$form.createForm(this),
      familyMobile: this.$form.createForm(this),
      formEmail: this.$form.createForm(this),
      formName: this.$form.createForm(this),
      visibleEmail: false,
      countryOptions: [],
      provinceOptions: [],
      timezoneOptions: [],
      cityOptions: [],
      sendCountryOptions: [],
      sendProvinceOptions: [],
      sendTimezoneOptions: [],
      sendCityOptions: [],
      sendAddress: {
        street: null,
        city: null,
        state: null,
        country: null,
      },
      sendEmailLoading: false,
      cityLoading: false,
      sendCityLoading: false,
    };
  },
  computed: {
    countryLabel() {
      return getLabelFromOptions(this.countryOptions, this.studentData.country);
    },
    stateLabel() {
      return getLabelFromOptions(this.provinceOptions, this.studentData?.data?.state);
    },
    cityLabel() {
      return getLabelFromOptions(this.cityOptions, this.studentData?.data.city);
    },
    timezoneName() {
      return getTimezoneName(this.studentData.timezoneStd, this.studentData.timezone);
    },
  },
  methods: {
    async sendEmail(accountId) {
      try {
        this.sendEmailLoading = true;
        await sendVerifyEmail({
          accountId: contactId,
          studentCode: this.studentData.code,
          studentId: this.studentData.uuid,
        });
        this.$message.success('发送邮件成功');
      } finally {
        this.sendEmailLoading = false;
      }
    },
    async handleCountryChange(v) {
      getTimezoneOptions(v).then((res) => (this.timezoneOptions = res));
      getProvinceOptions(v).then((res) => (this.provinceOptions = res));
      const data = this.form.getFieldValue('data');
      data.state = undefined;
      data.city = undefined;
      this.cityOptions = [];
      this.form.setFieldsValue({
        timezoneStd: undefined,
        country: v,
        data,
      });
    },
    async handleProvinceChange(v) {
      getCityOptions({ countryId: this.form.getFieldValue('country'), provinceId: v, showDoubleLanguage: true }).then(
        (res) => (this.cityOptions = res),
      );
      const data = this.form.getFieldValue('data');
      data.city = undefined;
      this.form.setFieldsValue({
        data,
      });
    },
    async handleSendCountryChange(v) {
      getTimezoneOptions(v).then((res) => (this.sendTimezoneOptions = res));
      getProvinceOptions(v).then((res) => (this.sendProvinceOptions = res));
      const data = this.form.getFieldValue('data');
      this.sendCityOptions = [];
      data.address.state = undefined;
      data.address.city = undefined;
      this.form.setFieldsValue({
        data,
      });
    },
    async handleSendProvinceChange(v) {
      getCityOptions({
        countryId: this.form.getFieldValue('data.address.country'),
        provinceId: v,
        showDoubleLanguage: true,
      }).then((res) => (this.sendCityOptions = res));
      const data = this.form.getFieldValue('data');
      data.address.city = undefined;
      this.form.setFieldsValue({
        data,
      });
    },

    cityFilterOption(value, o) {
      const input = value ? value?.trim() : value;
      if (!input) return true;
      return o?.data?.attrs?.item?.label.toLowerCase().includes(input.toLowerCase());
    },
    handleCitySearch: debounce(function (v) {
      this.cityOptions = [];
      this.cityLoading = true;
      getCityOptions({
        countryId: this.form.getFieldValue('country'),
        provinceId: this.form.getFieldValue('data.state'),
        city: v,
        showDoubleLanguage: true,
      })
        .then((res) => {
          this.cityLoading = false;
          this.cityOptions = res;
        })
        .catch(() => {
          this.cityLoading = false;
        });
    }, 500),
    handleCityChange(v, o) {
      const country = this.form.getFieldValue('country');
      const state = this.form.getFieldValue('data.state');
      const option = o?.data?.attrs?.item;
      console.log('isUnknownOption(o)', isUnknownOption(option));
      if (!option || isUnknownOption(option)) return;
      if (country !== option.countryId) {
        this.handleCountryChange(option.countryId);
      }
      this.form.setFieldsValue({
        country: option.countryId,
        timezoneStd: option.timezone,
      });
      if (state !== option.provinceId) {
        this.handleProvinceChange(option.provinceId);
        const data = this.form.getFieldValue('data');
        data.state = option.provinceId;
        this.form.setFieldsValue({
          data,
        });
      }
    },
    handleSendCitySearch: debounce(function (v) {
      this.sendCityOptions = [];
      this.sendCityLoading = true;
      getCityOptions({
        countryId: this.form.getFieldValue('data.address.country'),
        provinceId: this.form.getFieldValue('data.address.state'),
        city: v,
        showDoubleLanguage: true,
      })
        .then((res) => {
          this.sendCityOptions = res;
          this.sendCityLoading = false;
        })
        .catch(() => {
          this.sendCityLoading = false;
        });
    }, 500),
    handleSendCityChange(v, o) {
      const country = this.form.getFieldValue('data.address.country');
      const state = this.form.getFieldValue('data.address.state');

      const option = o?.data?.attrs?.item;
      if (isUnknownOption(option)) return;
      if (country !== option.countryId) {
        this.handleSendCountryChange(option.countryId);
        const data = this.form.getFieldValue('data');
        data.address.country = option.countryId;
        this.form.setFieldsValue({
          data,
        });
      }
      if (state !== option.provinceId) {
        this.handleSendProvinceChange(option.provinceId);
        const data1 = this.form.getFieldValue('data');
        data1.address.state = option.provinceId;
        this.form.setFieldsValue({
          data: data1,
        });
      }
    },
    handleGetSubscribeStatus(subject) {
      const status = this.subscribeInfo.find((item) => item.subject === subject)?.subscriptionStatus;
      if (!status) return '-';
      return this.subscribeOptions.find((item) => item.value === status)?.label;
    },

    hrefStudentDetail(record) {
      // 教务，教务主管可查看全部，只有当前登录的班主任是该学员的班主任才能查看
      // 判断按钮角色
      const roleFLag = this.userInfo.roleArr.find(
        (role) => role === 'TEACHING_STAFF' || role === 'TEACHING_STAFF_ADMIN' || role === 'HEAD_TEACHER_SUPER',
      );

      if (
        record.classAdminId === this.userInfo.userID ||
        record.mathClassAdminId === this.userInfo.userID ||
        record.englishClassAdminId === this.userInfo.userID ||
        roleFLag
      ) {
        const routeUrl = this.$router.resolve({
          path: '/studentDetail',
          query: { uuid: record.studentId },
        });

        window.open(routeUrl.href, '_blank');
      } else {
        this.$message.warning('暂无权限查看该学员');
      }
    },
    showFamilyStudent() {
      this.visibleFamilyStudent = true;
    },
    lookFamilyContactLogMobile(type, record, index) {
      // 是否查看过脱敏的手机号码
      if (record.realMobile) {
        // 眼睛查看展示或隐藏
        this.familyData[index].mobile = record.mobile === record.realMobile ? record.originMobile : record.realMobile;
      } else {
        const params = {
          familyId: this.studentData.familyId,
          operationType: type,
          contactId: record.contactId,
        };
        lookFamilyContactLog(params).then((res) => {
          // 脱敏的号码
          this.familyData[index].originMobile = record.mobile;
          this.familyData[index].mobile = res.data.content;
          this.familyData[index].realMobile = res.data.content;
        });
      }
    },
    lookFamilyContactLogEmail(type, record, index) {
      if (record.realEmail) {
        this.familyData[index].email = record.email === record.realEmail ? record.originEmail : record.realEmail;
      } else {
        const params = {
          familyId: this.studentData.familyId,
          operationType: type,
          contactId: record.contactId,
        };
        lookFamilyContactLog(params).then((res) => {
          // 脱敏的号码
          this.familyData[index].originEmail = record.email;
          this.familyData[index].email = res.data.content;
          this.familyData[index].realEmail = res.data.content;
        });
      }
    },
    addTelFun() {
      this.showDefault = true;
      if (this.familyType === 'tel') {
        this.telTitle = '新增家庭手机号';
      } else if (this.familyType === 'email') {
        this.telTitle = '新增家庭邮箱';
      }

      this.switchTelFlag = false;
      this.visibleAddTel = true;
    },
    setFamilyContact(record) {
      const params = {
        studentId: this.studentData.uuid,
        contactId: record.contactId,
      };
      setFamilyContact(params).then((res) => {
        this.$message.success('设置成功');
        this.queryStudentDetailData();
        this.$emit('refresh');
      });
    },
    deleteItem(record, name) {
      const params = {
        familyId: this.studentData.familyId,
        contactId: record.contactId,
      };
      const _this = this;
      this.$confirmAnt({
        content: `确认删除吗?`,
        okText: '确认',
        cancelText: '取消',
        onOk() {
          deleteFamilyContact(params)
            .then((res) => {
              _this.$message.success('删除成功');
              _this.queryStudentDetailData();
              this.$emit('refresh');
            })
            .finally(() => {});
        },
        onCancel() {},
      });
    },
    updateTelFun(record) {
      if (this.familyType === 'tel') {
        this.telTitle = '修改家庭手机号';
        if (!record.canUpdate) {
          this.$message.warning('该手机号，已经被其他学员设置为默认');
          return false;
        }
      } else if (this.familyType === 'email') {
        this.telTitle = '修改家庭邮箱';
        if (!record.canUpdate) {
          this.$message.warning('该邮箱，已经被其他学员设置为默认');
          return false;
        }
      }
      this.showDefault = false;
      this.contactId = record.contactId;
      this.switchTelFlag = record.default;
      this.visibleAddTel = true;
      if (this.familyType === 'tel') {
        const initMobile = record.mobile;
        const mobile = initMobile.split(' ')[1];
        const areaCode = initMobile.split(' ')[0];

        this.$nextTick(() => {
          this.familyMobile.setFieldsValue({ newAreaCode: areaCode, newMobile: mobile, newEmail: record.email });
        });
      } else if (this.familyType === 'email') {
        this.$nextTick(() => {
          this.familyMobile.setFieldsValue({ newEmail: record.email });
        });
      }
    },
    showFamilyTelTc(type) {
      this.familyType = type;
      if (this.familyType === 'tel') {
        this.familyTitle = '家庭手机号';
        this.familyColumns = this.familyTelColumns;
        this.familyData = JSON.parse(JSON.stringify(this.familyTelData)); // 防止修改原数组数据
        // this.lookFamilyContactLog('LOOK_STUDENT_MOBILE');
      } else if (this.familyType === 'email') {
        this.familyTitle = '家庭邮箱';
        this.familyColumns = this.familyEmailColumns;
        this.familyData = JSON.parse(JSON.stringify(this.familyEmailData));
        // this.lookFamilyContactLog('LOOK_STUDENT_EMAIL');
      }
      this.visibleFamilyTel = true;
    },
    changMathStatus(val) {
      this.studentMathStatus = val;
    },
    changEnglishStatus(val) {
      this.studentEnglishStatus = val;
    },
    changChineseStatus(val) {
      this.studentStatus = val;
    },
    saveChineseName() {
      this.formName.validateFields((err, values) => {
        if (!err) {
          const params = {
            studentId: this.studentId,
            chineseName: values.chineseName || this.studentData.fullName,
            englishName: values.englishName || this.studentData.data.EnglishName,
          };

          modifyName(params).then((res) => {
            // 获取学生资料模块
            this.queryStudentDetailData();
            this.$emit('refresh');
            this.$message.success('修改成功');
            this.cancelChineseName();
          });
        }
      });
    },
    updateChineseName(flag) {
      this.visibleChineseName = true;

      this.formName.getFieldDecorator('oldChineseName', { initialValue: this.studentData.fullName });
      this.formName.getFieldDecorator('oldEnglishName', { initialValue: this.studentData.data.EnglishName });
    },
    cancelChineseName() {
      this.visibleChineseName = false;
      this.formName.resetFields();
    },
    updateClassin() {
      countries().then((i) => {
        const data = i.data.content;
        const defaultCode = data.filter((item) => {
          return item.name === this.studentData.country;
        });

        this.areaCodeArrLabel = this.unique(i.data.content);
        this.formClassin.setFieldsValue({ newClassCode: defaultCode[0].areaCode });
      });

      this.visibleClassin = true;
    },
    saveClassin() {
      this.formClassin.validateFields((err, values) => {
        if (!err) {
          const datas = values;
          const classin = `${datas.newClassCode}-${datas.newClass}`;
          postReq(`/api/admin/student/change_classin_id/${this.studentId}/${classin}`).then((res) => {
            this.$message.success('修改classin账号成功');

            this.cancelClassin();
          });
        }
      });
    },
    cancelClassin() {
      this.visibleClassin = false;
      // 获取学生资料模块
      this.queryStudentDetailData();
      this.$emit('refresh');
      this.formClassin.resetFields();
    },
    // 变更付费状态
    editPayStatus() {
      this.showPayStatus = true;
    },
    cancelPayStatus() {
      this.showPayStatus = false;
    },
    getAge(birthday) {
      const birthDayTime = this.$moment(birthday).year();
      const nowTime = this.$moment().year();
      return nowTime - birthDayTime;
    },
    handCopy() {
      this.$message.warning('禁止复制');
    },
    onChangeBirth(value, dateString) {
      if (dateString) {
        this.form.setFieldsValue({
          currentAge: this.getAge(dateString),
        });
      } else {
        this.form.setFieldsValue({
          currentAge: 0,
        });
      }
    },
    async queryStudentDetailData() {
      this.showLoading = true;
      const params = {
        studentId: this.studentId,
      };
      // 获取学生订阅信息
      const [res, subscribeDetail] = await Promise.all([
        queryStudentDetailData(params),
        querySubscribeInfoApi(this.studentId),
      ]);

      // 兼容数据问题
      if (!res.data.content?.data?.address?.state && res.data.content?.data?.address?.province) {
        res.data.content.data.address.state = res.data.content.data.address.province;
      }

      this.subscribeInfo = subscribeDetail.data.content || [];

      this.showLoading = false;
      this.studentData = res.data.content;
      const country = res.data.content?.country;
      const sendCountry = res.data.content.data?.address?.country;
      getLocationNames({
        country: sendCountry,
        province: res.data.content.data?.address?.state,
        city: res.data.content.data?.address?.city,
      }).then((values) => {
        this.sendAddress = {
          country: values.countryName || sendCountry,
          state: values.provinceName || res.data.content.data?.address?.state,
          city: values.cityName || res.data.content.data?.address?.city,
          street: res.data.content.data?.address?.street,
        };
      });

      getCountryOptions().then((data) => {
        this.countryOptions = data;
        this.sendCountryOptions = data;
      });
      getProvinceOptions(country).then((data) => (this.provinceOptions = data));
      getCityOptions({ countryId: country, provinceId: res.data.content?.data.state, showDoubleLanguage: true }).then(
        (data) => {
          console.log('re', data);
          this.cityOptions = data;
        },
      );
      getTimezoneOptions(country).then((data) => (this.timezoneOptions = data));

      getProvinceOptions(sendCountry).then((data) => (this.sendProvinceOptions = data));
      getCityOptions({
        countryId: sendCountry,
        provinceId: res.data.content?.data?.address?.state,
        showDoubleLanguage: true,
      }).then((data) => (this.sendCityOptions = data));

      this.familyStudentData = this.studentData?.familyStudents || [];
      this.familyTelData = this.studentData?.familyContact?.mobiles || [];
      this.familyEmailData = this.studentData?.familyContact?.emails || [];
      if (this.familyType === 'tel') {
        this.familyData = JSON.parse(JSON.stringify(this.familyTelData));
      } else if (this.familyType === 'email') {
        this.familyData = JSON.parse(JSON.stringify(this.familyEmailData));
      }
      this.payObj = {
        status: this.studentData.data.status,
        mathStatus: this.studentData.data.mathStatus,
        englishStatus: this.studentData.data.englishStatus,
      };
    },

    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },

    unique(arr) {
      const arr1 = []; // 新建一个数组来存放arr中的值
      for (let i = 0, len = arr.length; i < len; i++) {
        if (arr1.indexOf(arr[i].areaCode) === -1) {
          arr1.push(arr[i].areaCode);
        }
      }
      return arr1;
    },
    uniqueLabel(arr) {
      const arr1 = []; // 新建一个数组来存放arr中的值
      for (let i = 0, len = arr.length; i < len; i++) {
        if (arr1.indexOf(arr[i].areaCode) === -1) {
          arr1.push(arr[i]);
        }
      }
      return arr1;
    },
    updateMobile() {
      this.visibleTel = true;
      this.queryTelEmail('LOOK_STUDENT_MOBILE');
    },
    updateEmail() {
      this.visibleEmail = true;
      this.queryTelEmail('LOOK_STUDENT_EMAIL');
    },
    cancelTel() {
      this.visibleTel = false;
      this.formMobile.resetFields();
    },
    cancelFamilyMobile() {
      this.visibleAddTel = false;
      this.familyMobile.resetFields();
    },
    cancelEamil() {
      this.visibleEmail = false;
      this.formEmail.resetFields();
    },
    // 查看手机号码，邮箱通知
    queryTelEmail(type) {
      const params = {
        studentId: this.studentData.uuid,
        operationType: type,
        studentName: this.studentData.fullName,
      };
      queryTelEmail(params).then((res) => {
        const data = res.data.content;
        if (type === 'LOOK_STUDENT_MOBILE') {
          const initMobile = data;
          const mobile = initMobile.split(' ')[1];
          const areaCode = initMobile.split(' ')[0];
          this.$nextTick(() => {
            this.formMobile.setFieldsValue({ areaCode });
            this.formMobile.setFieldsValue({ newAreaCode: areaCode });
            this.formMobile.setFieldsValue({ mobile });
          });
        } else if (type === 'LOOK_STUDENT_EMAIL') {
          this.$nextTick(() => {
            this.formEmail.setFieldsValue({ oldEmail: data });
          });
        }
      });
    },
    saveEamil() {
      this.formEmail.validateFields((err, values) => {
        if (!err) {
          const datas = values;

          const email = datas.newEmail;
          const params = {
            studentId: this.studentData.uuid,
            fullName: this.studentData.fullName,
            email,
            oldEmail: datas.oldEmail,
          };
          updateSelfTel(params).then((res) => {
            this.$message.success('修改邮箱成功');
            this.studentData.email = res.data.content;
            this.cancelEamil();
          });
        }
      });
    },
    // 新增手机号码或者邮箱
    addFamilyMobile() {
      this.familyMobile.validateFields((err, values) => {
        if (!err) {
          const datas = values;

          const params = {
            familyId: this.studentData.familyId,
          };
          if (this.familyType === 'tel') {
            params.contactType = 'MOBILE';
            const mobile = `${datas.newAreaCode} ${datas.newMobile}`;
            params.contactValue = mobile;
          } else if (this.familyType === 'email') {
            params.contactType = 'EMAIL';
            params.contactValue = datas.newEmail;
          }
          if (this.telTitle === '修改家庭手机号' || this.telTitle === '修改家庭邮箱') {
            params.contactId = this.contactId;
            params.studentId = this.studentId;
            updateFamilyContact(params).then((res) => {
              this.$message.success('修改成功');
              this.queryStudentDetailData();
              this.$emit('refresh');
              this.cancelFamilyMobile();
            });
          } else {
            if (this.switchTelFlag) {
              params.studentIds = [this.studentId];
            } else {
              params.studentIds = [];
            }
            addFamilyContact(params).then((res) => {
              this.$message.success('添加成功');
              this.queryStudentDetailData();
              this.$emit('refresh');
              this.cancelFamilyMobile();
            });
          }
        }
      });
    },
    // 更改手机号码或者邮箱
    saveTel() {
      this.formMobile.validateFields((err, values) => {
        if (!err) {
          const datas = values;
          const mobile = `${datas.newAreaCode} ${datas.newMobile}`;
          const oldMobile = `${datas.areaCode} ${datas.mobile}`;
          const params = {
            studentId: this.studentData.uuid,
            fullName: this.studentData.fullName,
            mobile,
            oldMobile,
          };
          updateSelfTel(params).then((res) => {
            this.$message.success('修改手机号码成功');
            this.studentData.mobile = res.data.content;
            this.cancelTel();
          });
        }
      });
    },
    editDataFun() {
      this.studentStatus = this.studentData.data.status;
      this.studentMathStatus = this.studentData.data.mathStatus;
      this.studentEnglishStatus = this.studentData.data.englishStatus;
      this.showEditData = true;

      // 判断按钮角色
      const roleFLag = this.userInfo.roleArr.find(
        (role) => role === 'TEACHING_STAFF' || role === 'TEACHING_STAFF_ADMIN',
      );
      // 只有教务跟教务主管才有权限
      if (roleFLag === 'TEACHING_STAFF' || roleFLag === 'TEACHING_STAFF_ADMIN') {
        this.upStudentStatusFlag = false;
      }

      // 国家编辑权限。只有教务、教务主管、
      const roleFlag1 = this.userInfo.roleArr.find(
        (role) => role === 'TEACHING_STAFF' || role === 'TEACHING_STAFF_ADMIN' || role === 'HEAD_TEACHER_SUPER',
      );
      if (roleFlag1) {
        this.disabledFlag = false;
      }
      // 回填表单信息
      const allValues = this.form.getFieldsValue();

      Object.keys(allValues).forEach((item) => {
        allValues[item] = this.studentData[item];
      });
      for (const i in allValues?.data?.userTime?.my) {
        allValues.data.userTime.my[i] = allValues.data.userTime.my[i]
          ? allValues.data.userTime.my[i].split(';')
          : undefined;
      }
      for (const i in allValues?.data?.userTime?.student) {
        allValues.data.userTime.student[i] = allValues.data.userTime.student[i]
          ? allValues.data.userTime.student[i].split(';')
          : undefined;
      }

      // 城市处理
      if (this.studentData?.data?.city && this.studentData?.country) {
        allValues.data.city = `${this.studentData?.country}-${this.studentData?.data?.state}-${this.studentData?.data?.city}`;
      }
      if (
        this.studentData?.data?.address?.city &&
        this.studentData?.data?.address?.coutnry &&
        this.studentData?.data?.address?.state
      ) {
        allValues.data.address.city = `${this.studentData?.data?.address?.coutnry}-${this.studentData?.data?.address?.state}-${this.studentData?.data?.address?.city}`;
      }
      setTimeout(() => {
        this.form.setFieldsValue(allValues);
      }, 16.7);

      currency().then((i) => {
        this.currency = i.data.content;
      });
      countries().then((i) => {
        this.countries = i.data.content;
        this.areaCodeArr = this.unique(i.data.content);
      });
      // 班主任
      classAdmin().then((i) => {
        this.classAdmin = i.data.content;
      });
      // 顾问
      consultant().then((i) => {
        this.consultant = i.data.content;
      });
    },
    cancelData() {
      this.showEditData = false;
    },
    onSaveData(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const datas = values;
          // 修改生日日期
          if (datas.dateOfBirth) {
            datas.dateOfBirth = this.$moment(new Date(datas.dateOfBirth)).format('YYYY-MM-DD');
          }

          this.classAdmin.forEach((item) => {
            if (values?.classAdmin?.CHINESE?.accountId === item.accountId) {
              datas.classAdmin.CHINESE = item;
            }
            if (values?.classAdmin?.MATH?.accountId === item.accountId) {
              datas.classAdmin.MATH = item;
            }
          });
          this.consultant.forEach((item) => {
            if (values?.data?.courseConsultant?.CHINESE?.accountId === item.accountId) {
              datas.data.courseConsultant.CHINESE = item;
            }
            if (values?.data?.courseConsultant?.MATH?.accountId === item.accountId) {
              datas.data.courseConsultant.MATH = item;
            }
          });
          // 时区处理
          datas.timezone = getTimezoneName(datas.timezoneStd);
          // console.log(datas.timezoneStd);
          // this.timezones.forEach((item) => {
          //   if (item.value === datas.timezoneStd) {
          //     // console.log(item)
          //     datas.timezone = item.label;
          //   }
          // });

          if (datas?.data?.userTime?.my) {
            for (const i in datas?.data?.userTime?.my) {
              datas.data.userTime.my[i] = datas?.data?.userTime?.my[i]?.join(';');
            }
            for (const i in datas?.data?.userTime?.student) {
              datas.data.userTime.student[i] = datas?.data?.userTime?.student[i]?.join(';');
            }
          }

          // 城市处理
          const cityKeys = datas?.data?.city?.split('-') ?? [];
          datas.data.city = cityKeys?.[cityKeys?.length - 1];
          if (datas?.data?.address?.city) {
            const sendCityKeys = datas.data.address.city?.split('-') ?? [];
            datas.data.address.city = sendCityKeys?.[sendCityKeys?.length - 1];
          }

          editStudent({ uuid: this.studentId, data: datas }).then(() => {
            this.$message.success('保存成功');
            this.$emit('editTimezoneStd');
            // 获取学生资料模块
            this.queryStudentDetailData();
            this.$emit('refresh');
            this.showEditData = false;
          });
        }
      });
    },
    callback(key) {
      console.log(key);
    },
  },

  mounted() {
    this.studentId = this?.$route?.query?.uuid || this?.studentProfile?.uuid;
    const obj = storage.get('userInfo');
    this.userInfo = obj;

    // 获取学生资料模块
    this.queryStudentDetailData();

    this.$emit('refresh');
    // 获取手机区号
    countries().then((i) => {
      // 修复bug，区号有重复
      this.areaCodeArr = [...new Set(this.unique(i.data.content))];
    });
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-descriptions-row > td {
  vertical-align: text-top;
}
.loading-wrap {
  padding-top: 200px;
  text-align: center;
}
/deep/ .ant-descriptions-item-label {
  color: rgba(31, 45, 61, 0.4);
}
/deep/ .ant-descriptions-item-content {
  color: #1f2d3d;
}
/deep/.ant-descriptions-item > span {
  display: inline-flex;
}
.edit-info {
  background-color: #fff;
  border-radius: 10px;
  p {
    margin-bottom: 0;
  }
  .personal-title {
    background: #f7f7f7;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    border-radius: 8px;
    padding: 10px 20px;
    margin-bottom: 20px;
  }
  .info-main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .time-zone {
      width: 100%;
      margin-bottom: 20px;
    }
    .info-item {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      font-size: 16px;
      margin-bottom: 10px;
      span {
        display: inline-block;
        width: 150px;
        line-height: 22px;
        color: #999;
      }
      p {
        color: #333;
        margin-bottom: 0;
      }
    }
    .info-time {
      width: 100%;
      span {
        color: #333;
      }
    }
  }
  .edit-btn {
    width: calc(100% - 156px);
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    background-color: #fff;
    position: fixed;
    right: 20px;
    bottom: 0px;
    border-top: 1px solid #f0f0f0;
    // box-shadow: 0 -6px 16px -8px rgb(0 0 0 / 8%), 0 -9px 28px 0 rgb(0 0 0 / 5%), 0 -12px 48px 16px rgb(0 0 0 / 3%);
    .ant-btn {
      margin: 0 10px;
    }
    // .ant-btn-primary {
    //   background: #009cff;
    // }
  }
}
.editDataBtn {
  position: absolute;
  right: 0px;
  top: 0px;
}
.info-time {
  margin-top: 5px;
}
//修改编辑表单label颜色
/deep/ .ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.6);
}
/deep/ .ant-input,
.ant-select {
  color: #1f2d3d;
}
</style>
