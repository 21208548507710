<template>
  <div>
    <a-alert type="warning" v-if="showMath || showEnglish">
      <template #message>
        <span v-if="showMath">
          此学员的年龄不在数学课参考年龄6-18岁范围内，国家年级匹配请参考：
          <a href="https://wukongedu.feishu.cn/wiki/wikcnzreEgtuAxPV7xU8B5pGKSe" target="_blank">
            Matching Table悟空数学课程各国年级对照表
          </a>
          请告知适合范围，必发话术：XX家长，悟空数学课程适合6-18岁，是您所在地区X年级的孩子。咱们宝贝是X岁/年级，不在适合范围内，课程会存在简单/较难的情况哦。
        </span>
        <span v-if="showEnglish">
          此学员的年龄不在英文课参考年龄6-11岁范围内，国家年级匹配请参考：
          <a href="https://wukongedu.feishu.cn/wiki/VhL2wwP7Di8Si6kI6u6csIWznhb" target="_blank"
            >【ELA课程】级别对照表</a
          >
          请告知适合范围，必发话术：XX家长，悟空英文（ELA）课程适合6-11岁，是您所在地区X年级的孩子。咱们宝贝是X岁/年级，不在适合范围内，课程会存在简单/较难的情况哦。
        </span>
      </template>
    </a-alert>
  </div>
</template>

<script>
import { SubjectEnum } from '@/enum/index.js';

export default {
  props: {
    subject: {
      type: String,
    },
    age: {
      type: Number,
    },
  },
  data() {
    return {
      SubjectEnum,
    };
  },
  computed: {
    standardSubject() {
      return this.subject && this.subject.toUpperCase();
    },
    showMath() {
      return this.standardSubject === SubjectEnum.MATH && (this.age < 6 || this.age > 15);
    },
    showEnglish() {
      return this.standardSubject === SubjectEnum.ENGLISH && (this.age < 6 || this.age > 11);
    },
  },
};
</script>

<style></style>
