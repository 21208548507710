<template>
 <div>
   <div class="item-con">
     <div class="course-wrap">
       <div><span class="course-titletc">{{reportInfoData.scheduleName}}</span><span class="course-type">{{reportInfoData.classType}}</span></div>
     </div>
     <div class="course-infowrap">
       <span class="course-progress">{{reportInfoData.structure}}</span>
       <span class="course-time course-linepd">|</span>
       <span class="course-time">{{reportInfoData.timeValue}}</span>
     </div>
   </div>
   <div class="item-con">
      <div class="title">{{subject==="MATH"?'Course Evaluation':'本课评价'}}</div>
      <div class="report-wrap">
        <div class="report-label" :class="subject==='MATH' ? 'bigwidth' : ''">{{subject==="MATH"?'Course grade:':'本课评分：'}}</div>
        <div class="report-con">
                  <img
                      v-for="index of reportInfoData.ratingComment.ratingScore"
                      :key="index"
                      src="@/assets/Star2.png"
                      class="student-start"
                    />
                    <img
                      v-for="index of 5 - reportInfoData.ratingComment.ratingScore"
                      src="@/assets/Star6.png"
                      class="student-start"
                      :key="index"
                    />
        </div>
      </div>
       <div class="report-wrap">
        <div class="report-label" :class="subject==='MATH' ? 'bigwidth' : ''">{{subject==="MATH"?'Course teacher:':'授课老师：'}}</div>
        <div class="report-con">
              {{reportInfoData.teacherName}}
        </div>
      </div>
       <div class="report-wrap">
        <div class="report-label" :class="subject==='MATH' ? 'bigwidth' : ''">{{subject==="MATH"?'Teacher evaluation:':'老师评价：'}}</div>
        <div class="report-con">
                {{reportInfoData.ratingComment.ratingComment}}
        </div>
      </div>
   </div>
   <div class="item-con">
      <div class="title">{{subject==="MATH"?'Class Evaluation':'课堂评价'}}</div>
      <div class="report-wrap">
        <div class="report-label" :class="subject==='MATH' ? 'bigwidth' : ''">{{subject==="MATH"?'Win trophies:':'获得奖杯：'}}</div>
        <div class="report-con">
                {{reportInfoData.classPerformance.award?reportInfoData.classPerformance.award:0}}个
        </div>
      </div>
     <div class="report-wrap">
        <div class="report-label" :class="subject==='MATH' ? 'bigwidth' : ''"></div>
        <div class="report-con">
                {{reportInfoData.classPerformance.performance}}
        </div>
      </div>
       <div class="report-wrap">
        <div class="report-label" :class="subject==='MATH' ? 'bigwidth' : ''">{{subject==="MATH"?'Classroom data:':'课堂数据：'}}</div>
        <div class="report-con" v-if="subject==='MATH'">
          The class lasts {{reportInfoData.activeData.classDuration}}  minutes, {{reportInfoData.activeData.interactiveCount}}  times of interaction and {{Math.round(reportInfoData.activeData.stageDuration/60)}} minutes of speech;

        </div>
         <div v-else class="report-con">
          上课 {{reportInfoData.activeData.classDuration}}分钟、互动 {{reportInfoData.activeData.interactiveCount}}次、发言 {{Math.round(reportInfoData.activeData.stageDuration/60)}}分钟；

        </div>
      </div>
     
   </div>
     <div class="item-con" v-if="reportInfoData.classPerformance.stuClassAnswers">
      <div class="title">{{subject==="MATH"?'Classroom Answer':'课堂答题'}}</div>
      <div class="report-wrap">
        <div class="report-label" :class="subject==='MATH' ? 'bigwidth' : ''">{{subject==="MATH"?'Correct answer rate:':'答题正确率：'}}</div>
        <div class="report-con">
          {{reportInfoData.classPerformance.accuracy>0?`${Math.round(reportInfoData.classPerformance.accuracy*100)}%`:'-'}}
        </div>
      </div>
       <div class="report-wrap">
        <div class="report-label" :class="subject==='MATH' ? 'bigwidth' : ''">{{subject==="MATH"?'Answer details:':'答题详情：'}}</div>
        <div class="report-con">
           <a-table
            :rowKey="
            (record, index) => {
              return index;
            }
          "
            :maskClosable="false"
            :pagination="false"
            size="small"
            :scroll="{ y: 500 }"
            :columns="columns"
            :data-source="reportInfoData.classPerformance.stuClassAnswers"
          >
          <span slot="action"  slot-scope="text, record">
            <img v-if="text" src="@/assets/face_correct.png" class="face-icon">
             <img v-else src="@/assets/face_error.png" class="face-icon">
          </span>
   </a-table>
        </div>
      </div>
   </div>
 </div>
</template>

<script>
export default {
  name:'StudyReport',
   props: {
    reportInfoData: {
      type: Object,
      default: () => {},
    },
    subject: {
      type:String,
      default:'',
    },

  },
  watch: {
    subject(val) {
      if(val==='MATH'){
         this.columns= this.mathColumns
      }else{
       this.columns= this.chineseColumns
    }
  }},
  data(){
    return{
      reportlList:[],
      columns:[],
      mathColumns:[

 {
          title: 'Title number',
          key: 'index',
          width:100,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${index+1}`,
        
  },
  {
    title: 'Correct answer',
    dataIndex: 'correct',
    width: 120,
  },
  {
    title: 'Student answer',
    dataIndex: 'answer',
    width: 120,
  },
   {
    title: 'Determine answer',
    dataIndex: 'result',
    width: 140,
    scopedSlots: { customRender: 'action' },
  },
  
],
chineseColumns: [

 {
          title: '题号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${index+1}`,
        
  },
  {
    title: '正确答案',
    dataIndex: 'correct',
    width: 180,
  },
  {
    title: '学员答案',
    dataIndex: 'answer',
    width: 100,
  },
   {
    title: '最终答案',
    dataIndex: 'result',
    width: 100,
     scopedSlots: { customRender: 'action' },
  },
  
]
    }
  },
  mounted(){
     if(this.subject==='MATH'){
         this.columns = this.mathColumns
      }else{
        this.columns = this.chineseColumns
      }
     
    
  }
}

</script>

<style lang="less" scoped>
.item-con{
  padding: 0 0 20px;
  border-bottom: 1px solid #EEEEEE;
}
.course-titletc{
  font-size: 20px;
  color: #333;
  font-weight: bold;
  padding-right:12px;
}
.course-type{
  color: #FFBF00;
  padding:0 2px;
  border-radius: 4px;
  border:1px solid #FFBF00;
  font-size: 14px;
}
.course-infowrap{
  margin-top:10px;
   font-size: 16px;
}
.course-progress{
  color:#333333;

}
.course-time{
  color: #999;
}
.course-linepd{
  padding:0 10px;
}
.title {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-top:20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 5px 8px 0 0;
  width: 4px;
  height: 18px;
  background-color: #43D186;
  border-radius: 0px 4px 4px 0px;

}
.student-start{
  width:14px;
  height:14px;
  margin-right:2px;
  margin-top:-3px;
}
.report-wrap{
  display: flex;
  margin-top:13px;

}
.report-label{
  width: 120px;
  font-size: 16px;
  color: #999;
}
.report-con{
  font-size: 15px;
  color: #333;
  flex: 1;
}
.bigwidth{
  width:170px;
  text-align: right;
  padding-right: 20px;
}
.face-icon{
  width: 20px;
  height: 20px;
}
.item-con:last-child{
  border-bottom:none;
}
</style>