<template>
  <div class="order-main">
    <div class="operate-wrap">
      <a-button type="primary" ghost v-if="userInfo.roleName === 'CLASS_ADMIN'" @click="toOrder"> 创建订单 </a-button>
    </div>
    <div class="order-table">
      <a-spin :spinning="spinning">
        <a-table
          :columns="columns"
          :data-source="tableData"
          @change="handleTableChange"
          :pagination="initPagination"
          :scroll="{ y: 600 }"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <a slot="level" slot-scope="text">{{ text }}</a>
          <template slot="userName" slot-scope="userName, record">
            <span style="font-weight: 600; font-size: 16px">{{ userName }}</span>
            <p>{{ record.role }} ({{ record.department }})</p>
          </template>
          <template slot="productTags" slot-scope="productTags, record">
            <span v-for="i in record.productTags" :key="i">
              {{
                initTabsList(i, tabsList).length !== 0 && ['CHINESE', 'MATH', 'ENGLISH'].find((item) => i == item)
                  ? initTabsList(i, tabsList)[0].label
                  : ' '
              }}
            </span>
          </template>
          <template slot="orderStatus" slot-scope="orderStatus">
            <span> {{ initTabsList(orderStatus, orderStatusArray)[0].label }} </span>
          </template>
          <template slot="expired" slot-scope="expired">
            <span> {{ initTabsList(expired, expiredArray)[0].label }} </span>
          </template>
          <template slot="refundStatus" slot-scope="refundStatus">
            <span> {{ initTabsList(refundStatus, refundStatusArray)[0].label }} </span>
          </template>
          <template slot="approveStatus" slot-scope="approveStatus">
            <span> {{ initTabsList(approveStatus, approvalStatus)[0].label }} </span>
          </template>
          <template slot="logisticsStatus" slot-scope="logisticsStatus">
            <span> {{ initTabsList(logisticsStatus, deliveryStatus)[0].label }} </span>
          </template>
          <template slot="money" slot-scope="money">
            <a-popover>
              <template v-slot:title>
                实时汇率<span style="font-size: 12px; color: rgba(0, 0, 0, 0.65)">（仅供银行转账参考）</span>
              </template>
              <template slot="content">
                <a-table
                  :pagination="false"
                  size="small"
                  :columns="currencyColumns"
                  :data-source="money.exChangeList"
                  :rowKey="
                    (record, index) => {
                      return index;
                    }
                  "
                >
                </a-table>
              </template>
              <span
                >{{ money.currency }} {{ money.value.toFixed(2)
                }}<a-icon type="ordered-list" style="margin-left: 3px; color: #04cb94"
              /></span>
            </a-popover>
          </template>
          <template slot="operation" slot-scope="operation, record">
            <span class="operation" @click="lookDetail(record)">详情</span>
          </template>
        </a-table>
      </a-spin>
    </div>
  </div>
</template>
<script>
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import { orderSearch, exchangeRateNew } from '@/api/headTeacher';
import defaultImg from '@/assets/service_pic_head.png';
import { ACCESS_TOKEN, REMEMBERME } from '@/store/mutation-types';

const session = storage.createStore(sessionStorage);
const token = storage.get(ACCESS_TOKEN);
export default {
  data() {
    return {
      exChangeList: {},
      currencyList: ['EUR', 'GBP', 'SGD', 'HKD'],
      currencyColumns: [
        {
          title: '订单金额',
          dataIndex: 'targetValue',
          key: 'targetValue',
        },
        {
          title: '币种',
          dataIndex: 'currency',
          key: 'currency',
        },
      ],
      spinning: false,
      orderType: [
        { label: '学生续费', value: '续费' },
        { label: '学生补升', value: '补升' },
        { label: '新生报班', value: '新生报班' },
        { label: '国际运费', value: '国际运费' },
      ],
      approvalStatus: [
        { label: '审核中', value: 'PENDING' },
        { label: '已通过', value: 'PASSED' },
        { label: '已拒绝', value: 'REJECTED' },
        { label: '已撤销', value: 'CANCEL' },
      ],
      orderStatusArray: [
        { label: '待支付', value: 'PENDING_PAYMENT' },
        { label: '已支付', value: 'PAYMENT_RECEIVED' },
        { label: '已支付待确认', value: 'CONFIRMED' },
        { label: '已取消', value: 'CANCELLED' },

        // { label: '已退款', value: '已退款' },
      ],
      expiredArray: [
        { label: '已过期', value: true },
        { label: '未过期', value: false },
      ],
      refundStatusArray: [
        { label: '待退款', value: 'PENDING' },
        { label: '退款处理中', value: 'PROCESSING' },
        { label: '已退款', value: 'OK' },
        { label: '退款失败', value: 'FAILED' },
      ],
      deliveryStatus: [
        { label: '无需发货', value: 'NO_NEED_TO_SHIP' },
        { label: '待确认', value: 'PENDING_CONFIRM' },
        { label: '待发货', value: 'PENDING' },
        { label: '已发货', value: 'SENT' },
        { label: '已取消', value: 'CANCEL' },
        { label: '打包中', value: 'WAITING' },
        { label: '已签收', value: 'FINISH' },
      ],
      logisticsCompany: [
        { label: 'FEDEX联邦', value: 'FEDEX' },
        { label: 'DHL', value: 'DHL' },
        { label: 'EMS', value: 'EMS' },
        { label: '顺丰', value: 'SF' },
        { label: '专线', value: 'SL' },
      ],
      tabsList: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
        { label: '新生报班', value: 'NEW_STU_REPORT_CLASS' },
        { label: '续费', value: 'RENEW' },
        { label: '补升', value: 'MAKE_UP' },
        { label: '赠课时', value: 'HANDSEL_COURSE' },
      ],
      confirmStatus: [
        { label: '待支付', value: 'PENDING' },
        { label: '已支付待确认', value: 'PENDING_CONFIRMATION' },
        { label: '已支付', value: 'SUCCESSFUL' },
        { label: '支付失败', value: 'FAILED' },
      ],
      payStyle: [
        { label: '无', value: 'empty' },
        { label: 'PayPal', value: 'PAYPAL' },
        { label: '微信', value: 'WECHAT' },
        { label: '支付宝', value: 'ALIPAY' },
        { label: '新西兰转账', value: 'NEWZEALAND' },
        { label: '跨国转账', value: 'CROSS' },
        { label: '国内转账', value: 'INLAND' },
        { label: '现金', value: 'CASH' },
        { label: '银行卡', value: 'CARD' },
        { label: '其他', value: 'OTHER' },
        { label: 'Latipay', value: 'LATIPAY' },
        { label: 'Stripe', value: 'STRIPE' },
        { label: '转账', value: 'REMITTANCE' },
        { label: '银联', value: 'UPOP' },
        { label: 'klarna', value: 'KLARNA' },
      ],
      statusMapper: {
        PENDING: '待退款',
        OK: '已退款',
      },
      paymentStatus: {
        PENDING_PAYMENT: '请求中',
        CONFIRMED: '已确认付款凭证',
        PAYMENT_RECEIVED: '已确认到账',
        CANCELLED: '已取消',
      },
      defaultImg,
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,

          dataIndex: 'num',
          customRender: (text, record, index) => `${(this.current - 1) * this.pageSize + (index + 1)}`,
        },
        {
          title: '订单号',
          dataIndex: 'orderNumber',
          key: 'orderNumber',
        },
        {
          title: '订单类型',
          dataIndex: 'orderTags',
          key: 'orderTags',
          filters: [
            { text: '学生续费', value: '续费' },
            { text: '学生补升', value: '补升' },
            { text: '定金', value: '定金' },
            { text: '新生报班', value: '新生报班' },
            { text: '国际运费', value: '国际运费' },
            { text: '学员升级', value: '学员升级' },
            { text: '活动寄送', value: '活动寄送' },
            { text: '订阅订单', value: '订阅订单' },
          ],
          customRender: (text, record) => {
            return (record.orderTags || []).filter((item) => item !== '试学期退费').join('、');
          },
        },
        {
          title: '商品标签',
          dataIndex: 'productTags',
          key: 'productTags',
          scopedSlots: { customRender: 'productTags' },
        },
        {
          title: '订单金额',
          dataIndex: 'money',
          key: 'money',
          scopedSlots: { customRender: 'money' },
        },
        {
          title: '购买课时/额度',
          dataIndex: 'purchaseCuQuota',
          key: 'purchaseCuQuota',
        },
        {
          title: '审核状态',
          dataIndex: 'approveStatus',
          key: 'approveStatus',
          scopedSlots: { customRender: 'approveStatus' },
        },
        {
          title: '订单状态',
          dataIndex: 'orderStatus',
          filters: [
            { text: '待支付', value: 'PENDING_PAYMENT' },
            { text: '已支付', value: 'PAYMENT_RECEIVED' },
            { text: '已支付待确认', value: 'CONFIRMED' },
            { text: '已取消', value: 'CANCELLED' },
          ],
          width: 96,
          scopedSlots: { customRender: 'orderStatus' },
        },
        {
          title: '寄送状态',
          dataIndex: 'logisticsStatus',
          scopedSlots: { customRender: 'logisticsStatus' },
        },
        {
          title: '支付过期状态',
          dataIndex: 'expired',
          filters: [
            { text: '已过期', value: true },
            { text: '未过期', value: false },
          ],
          scopedSlots: { customRender: 'expired' },
          filterMultiple: false,
        },
        {
          title: '退款状态',
          dataIndex: 'refundStatus',
          filters: [
            { text: '待退款', value: 'PENDING' },
            { text: '退款处理中', value: 'PROCESSING' },
            { text: '已退款', value: 'OK' },
            { text: '退款失败', value: 'FAILED' },
          ],
          scopedSlots: { customRender: 'refundStatus' },
          filterMultiple: false,
        },
        {
          title: '创建时间',
          dataIndex: 'whenCreated',
          key: 'whenCreated',
        },
        {
          title: '操作',
          width: 100,
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      studentProfile: session.get('studentProfile'),
      userInfo: storage.get('userInfo'),
      pageNum: 1,
      search: {
        tags: null,
        orderStatus: undefined,
        orderNumber: undefined,
      },
      searchData: null,
      tableData: null,
      selectedData: null,
      info: null,
      current: 0,
      pageSize: 0,
      allPaice: 0,
      initPagination: {
        total: 0,
        current: 1,
        pageSize: 20,
      },
      tags: undefined,
      loading: false,
      expand: {},
    };
  },
  props: {
    studentId: {
      type: String,
      default: '',
    },
  },
  created() {},
  mounted() {
    this.initSearchData();
  },

  methods: {
    currencyData(originItem, sourceCurrency, money, index) {
      const data = [];
      const params = [{ method: 'REMITTANCE', currencies: ['EUR', 'GBP', 'SGD', 'HKD'] }];
      exchangeRateNew(params, originItem.uuid).then((res) => {
        (res?.data?.content?.[0]?.rates || []).map((item) =>
          data.push({
            targetValue: item.target.value,
            currency: item.target.currency,
          }),
        );
        originItem.money.exChangeList = data;
        this.tableData.splice(index, 1, originItem);
      });
    },
    lookDetail(data) {
      this.$router.push({
        path: '/orderDetail',
        query: {
          orderId: data.uuid,
          approveStatus: data.approveStatus,
          orderStatus: data.orderStatus,
          logisticsStatus: data.logisticsStatus,
          studentId:this.studentId
        },
      });
    },

    initSearchData(searchData) {
      this.tableData = [];
      const parame = {
        num: this.pageNum,
        data: {
          studentCode: this.studentProfile.code,
          ...searchData,
        },
      };
      this.spinning = true;
      orderSearch(parame)
        .then((res) => {
          this.spinning = false;

          const data = res.data.content;
          this.tableData = res.data.content;
          data.map((item, index) => {
            this.currencyData(item, item.money.currency, item.money.value, index);
          });
          this.current = res.data.currentPage;
          this.pageSize = res.data.pageSize;
          this.initPagination.total = res.data.totalCount;
        })
        .finally(() => {
          this.spinning = false;
        });
    },

    handleTableChange(pagination, filters, sorter) {
      this.pageNum = pagination.current;
      this.initPagination.current = pagination.current;
      const search = {
        orderStatusSet: filters.orderStatus && filters.orderStatus.length > 0 ? filters.orderStatus : null,
        tags: filters.orderTags && filters.orderTags.length > 0 ? filters.orderTags : null,
        expired: filters?.expired?.length ? filters.expired[0] : null,
        refundStatus: filters?.refundStatus?.length ? filters?.refundStatus[0] : null,
      };
      this.initSearchData(search);
    },
    initTabsList(value, data) {
      const arr = data.filter((i) => i.value === value);
      return arr.length ? arr : [{}];
    },
    toOrder() {
      this.$router.push({ path: '/createOrder' });
      // 跳转到筋斗云
      //   if (!this.userInfo.fullName) {
      //     this.$message.warning('未携带用户名参数');
      //     return false;
      //   }
      //   if (!this.userInfo.email) {
      //     this.$message.warning('未携带参数邮箱');
      //     return false;
      //   }
      //   const queryString = Object.entries({
      //     origin: 'ff',
      //     studentCode: this.studentProfile.code,
      //     businessId: '57526073-055c-4f58-adb0-ba555ea9c8b2',
      //     merchantId: '2d6e71ae-c0d6-40b2-9633-48e7dd9d1cea',
      //     token: token,
      //     username: this.userInfo.fullName,
      //     email: this.userInfo.email,
      //     targetUrl: window.location.href
      //   })
      //   .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)//带有特殊字符的需要编码
      //   .join('&')

      //   // const queryString = `origin=ff&studentCode=${this.studentProfile.code}&businessId=57526073-055c-4f58-adb0-ba555ea9c8b2&merchantId=2d6e71ae-c0d6-40b2-9633-48e7dd9d1cea&token=${encodeURIComponent(token)}&username=${encodeURIComponent(this.userInfo.fullName)}&email=${encodeURIComponent(this.userInfo.email)}&targetUrl=${encodeURIComponent(window.location.href)}`
      //  window.open(
      //     `${process.env.VUE_APP_MAKEUP_URL}?${queryString}`
      //   );
    },
  },
};
</script>

<style lang="less" scoped>
.operate-wrap {
  text-align: right;
  margin-bottom: 12px;
}

.operation {
  color: #3372fe;
  &:hover {
    cursor: pointer;
  }
}
</style>
