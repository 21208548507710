<template>
  <div class="mathReport-main">
    <template v-if="JSON.stringify(dataInfo) !== '{}'">
      <div class="report-header">
        <p class="report-name">
          {{ dataInfo.linkName }} report on {{ moment(dataInfo.whenCreated).locale('en').format('MMMM Do YYYY') }}
        </p>
        <p>
          Total answer time: <span>{{ classOutInTime(dataInfo.totalAnswerTime) || 'No data' }}</span>
        </p>
        <p>
          Submission time: <span>{{ dataInfo.whenCreated || 'No data' }}</span>
        </p>
        <p>
          Sum of incorrect answers:<span class="fraction">{{ dataInfo.totalErrorNumber || 0 }}</span>
        </p>
      </div>
      <div class="report-expand">
        <p class="expand-btn" @click="onExpand">
          {{ is_expand ? 'Expand report' : 'Collapse report' }}
          <a-icon :type="is_expand ? 'down' : 'up'" />
        </p>
      </div>
      <div v-if="!is_expand" class="report-list">
        <div v-for="(item, index) in data" :key="item.id">
          <div class="report-item">
            <div class="item-title">
              <p v-if="item.questionType !== 'EXPLAN_CHOICE' && item.questionType !== 'SINGLE_CHOICE'">{{ index + 1 }} 、{{ item.title }}</p>
              <div v-else style="display: flex; justify-content: flex-start">
                <p>{{ index + 1 }}、</p>
                <div>
                  <QuillRender :delta="transformStringToDelta(item.title)" />
                </div>
              </div>
              <span>Question difficulty: {{ item.questionDifficulty }}</span>
            </div>
            <div class="answer">
              <p
                v-if="item.errorOptions"
                :class="item.correctOptions !== item.errorOptions ? 'answer-error' : 'answer-correct'"
              >
                Student Answer: {{ item.errorOptions }}
              </p>
              <p class="answer-correct" v-if="item.correctOptions !== item.errorOptions">
                Correct Answer: {{ item.correctOptions }}
              </p>
            </div>
            <div class="item-ai" @click="explainResolution(item.questionId)">
              <span>Problem analysis</span>
              <p>Click to view</p>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="empty">
      <p>The report has not yet been submitted</p>
      <img src="@/assets/icons/pic_emptypage.svg" alt="" />
    </div>
    <a-modal width="700px" :visible="explainvisible" @cancel="onClose" :footer="null">
      <div class="explain-content">
        <div v-for="(item, index) in explainData" :key="index">
          <p v-if="typeof item.insert === 'string' && item.insert.replace(/\r\n/g, '')">
            {{ item.insert.replace(/\r\n/g, '') }}
          </p>
          <img v-if="item.insert.image" :src="item.insert.image" style="width: 100%" alt="" />
          <p v-if="item.insert.formula">{{ item.insert.formula }}</p>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment';
import { classOutInTime } from '@/utils/domUtil';
import { questionDetail } from '@/api/headTeacher';
import QuillRender from '@wk/iexercise/lib/components/QuillRender';
import { transformStringToDelta } from '@wk/iexercise/lib/utils/quill';

export default {
  components: { QuillRender },
  props: {
    dataInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isAudio: false,
      data: this.dataInfo?.reportData?.answerRecord || [],
      is_expand: true,
      explainvisible: false,
      explainData: null,
    };
  },
  mounted() {},
  methods: {
    moment,
    transformStringToDelta,
    classOutInTime,
    onClose() {
      this.explainvisible = false;
    },
    explainResolution(id) {
      questionDetail({uuid: id}).then((res) => {
        if (res.status === 200) {
          this.explainvisible = true;
          this.explainData = res.data.content.contentOption;
        }
      });
    },
    onExpand() {
      this.is_expand = !this.is_expand;
    },
  },
};
</script>
<style lang="less">
.mathReport-main {
  padding: 10px 0 0;
  border-radius: 10px;
  border: 1px solid #eee;
  margin-bottom: 20px;
  .report-header {
    padding: 0 20px;
  }
  .readRecording {
    &:hover {
      cursor: pointer;
    }
    img {
      width: 14px;
      height: 14px;
    }
  }
  p {
    font-size: 16px;
    color: #999;
    margin-bottom: 10px;
    span {
      color: #333;
      margin-right: 40px;
    }
  }
  .report-name {
    color: #333;
    font-size: 18px;
    font-weight: 600;
  }
  .fraction {
    font-size: 32px;
    font-weight: 600;
    color: #333333;
    line-height: 32px;
  }
  .report-expand {
    .expand-btn {
      width: 100%;
      height: 42px;
      padding-right: 10px;
      line-height: 42px;
      text-align: right;
      // border-radius: 10px;
      font-size: 16px;
      color: #333;
      background-color: #eee;
      margin-bottom: 0;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .report-list {
    padding: 10px 20px 0;
    & > div:last-child .report-item {
      border: none;
    }
    .report-item {
      border-bottom: 1px solid #eee;
      margin-bottom: 20px;
    }
    .item-title {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      p {
        font-size: 16px;
        line-height: 22px;
        color: #333;
        max-width: 80%;
        margin-bottom: 0;
      }
      span {
        font-size: 14px;
        font-weight: normal;
        padding: 2px 6px;
        border-radius: 10px;
        border: 1px solid #eee;
        margin-left: 5px;
      }
    }
    .answer {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      p {
        margin-bottom: 10px;
      }
    }
    .answer-error {
      color: #ff5353;
    }
    .answer-correct {
      color: #00cf64;
    }
    .explain-main {
      img {
        width: 100%;
      }
    }
    .item-ai {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 16px;
      color: #666;
      margin-bottom: 10px;
      p {
        color: #009cff;
        margin-bottom: 0;
        &:hover {
          cursor: pointer;
        }
      }
      span {
        padding: 2px 8px;
        border-radius: 10px;
        background: #f7f7f7;
        border-radius: 6px;
        margin-right: 10px;
      }
    }
  }
  .empty {
    font-size: 16px;
    color: #999;
    padding-top: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.explain-content {
  height: 600px;
  overflow-y: scroll;
}
</style>
