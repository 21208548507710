<template>
  <div class="order-main">
    <div style="margin-bottom: 14px">
      <a-radio-group :default-value="selfClue" @change="changeClue">
        <a-radio-button :value="true">当前学员线索</a-radio-button>
        <a-radio-button :value="false">学员推荐线索</a-radio-button>
      </a-radio-group>
    </div>
    <div class="order-table">
      <a-spin :spinning="spinning">
        <a-table
          :columns="columns"
          :data-source="tableData"
          @change="handleTableChange"
          :pagination="initPagination"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template slot="recommendStuName" slot-scope="recommendStuName, record">
            <span v-if="record.recommendStuName">{{ record.recommendStuName }} ({{ record.recommendStuId }})</span>
            <span v-else>-</span>
          </template>
          <template slot="resellerName" slot-scope="resellerName, record">
            <span v-if="record.resellerName">{{ record.resellerName }} ({{ record.resellerCode }})</span>
            <span v-else>-</span>
          </template>
          <template slot="operation" slot-scope="operation, record">
            <a v-if="moment().diff(moment('2023-09-01 00:00:00'),'days') > 30" style="margin-right: 10px" class="blue-txt" @click="createClueCopy('INSIDE_COPY', record)" >拓科</a>
            <a style="margin-right: 10px" class="blue-txt" @click="createClueCopy('INSIDE_MULTI', record)">多娃</a>
            <a style="margin-right: 10px" class="blue-txt" @click="getCRMAddress(`/lead/${record.uuid}`)" v-if="record.ownerId === userInfo.userID">跟进</a>
            <a-popover overlayClassName="classPopover" trigger="click" placement="bottom">
              <template slot="content">
                <ul class="clue-wrap">
                  <li @click="getAppointment(record.uuid)">去约课</li>
                  <li @click="getAppointment(record.uuid, 'copy')">复制约课链接</li>
                </ul>
              </template>
              <a v-if="record.showInviteCourse" class="blue-txt">约课</a>
            </a-popover>
          </template>
        </a-table>
      </a-spin>
    </div>
    <CreateClue
      v-if="clueFlag"
      :reseller="reseller"
      :type="clueType"
      :studentProfile="studentProfile"
      :recordObj="recordObj"
      :formOrigin="formOrigin"
      @cancel="cancelCreateClue"
    ></CreateClue>
  </div>
</template>
<script>
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import moment from 'moment';
import { searchMyClue, getAppointment} from '@/api/headTeacher';
import defaultImg from '@/assets/service_pic_head.png';
import { ACCESS_TOKEN, REMEMBERME } from '@/store/mutation-types';
import CreateClue from '@/views/mainHome/createClue';
import { getCRMAddress } from '@/utils/util';

const session = storage.createStore(sessionStorage);
const token = storage.get(ACCESS_TOKEN);
const userInfo = storage.get('userInfo');
export default {
  name: 'studentClue',

  props:{
    reseller:{
      type:Object,
   
    },
    studentCode:{
      type:String,
      default:''
    },
    reseller:{
      type:Object,
      default: () => {},
    }
  },
  data() {
    return {
      getCRMAddress,
      selfClue: true,
      spinning: false,
      clueTypeArr: [
        { label: '正常', value: 'NORMAL' },
        { label: '公海', value: 'OPEN' },
        { label: '回收站', value: 'DUSTBIN' },
      ],
    
      formOrigin: 'new', // 新建
      userInfo: {},
      word: '展开',
      recordObj: {},
      showAll: false,
      studentProfile:session.get('studentProfile'),
      whoCreatedName: '',
      stage: undefined,
      clueType: false,
      clueFlag: false,
      subject: undefined,
      name: '',
      createdType: undefined,
      mainSource: undefined,
      mobile: '',
      email: '',
      subjectArray: [
        { label: '中文', value: 'Chinese' },
        { label: '数学', value: 'Math' },
      ],
      clueArr: [
        { label: '普通线索', value: 'INSIDE_NORMAL' },
        { label: '学员拓科', value: 'INSIDE_COPY' },
        { label: '一家多娃', value: 'INSIDE_MULTI' },
        { label: '外部线索', value: 'OUTSIDE' },
      ],
      clueArr1: [
        { label: '普通线索', value: 'INSIDE_NORMAL' },
        { label: '学员拓科', value: 'INSIDE_COPY' },
        { label: '一家多娃', value: 'INSIDE_MULTI' },
      ],
 
      approvalStatus: [
        { label: '审核中', value: 'PENDING' },
        { label: '已通过', value: 'PASSED' },
        { label: '已拒绝', value: 'REJECTED' },
      ],
      orderStatusArray: [
        { label: '待支付', value: 'PENDING_PAYMENT' },
        { label: '已支付', value: 'PAYMENT_RECEIVED' },
        { label: '已支付待确认', value: 'CONFIRMED' },
        { label: '已取消', value: 'CANCELLED' },

        // { label: '已退款', value: '已退款' },
      ],
      deliveryStatus: [
        { label: '无需发货', value: 'NO_NEED_TO_SHIP' },
        { label: '待确认', value: 'PENDING_CONFIRM' },
        { label: '待发货', value: 'PENDING' },
        { label: '已发货', value: 'SENT' },
        { label: '已取消', value: 'CANCEL' },
        { label: '打包中', value: 'WAITING' },
        { label: '已签收', value: 'FINISH' },
      ],
      tabsList: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '新生报班', value: 'NEW_STU_REPORT_CLASS' },
        { label: '续费', value: 'RENEW' },
        { label: '补升', value: 'MAKE_UP' },
        { label: '赠课时', value: 'HANDSEL_COURSE' },
      ],
      confirmStatus: [
        { label: '待支付', value: 'PENDING' },
        { label: '已支付待确认', value: 'PENDING_CONFIRMATION' },
        { label: '已支付', value: 'SUCCESSFUL' },
      ],
      payStyle: [
        { label: '无', value: 'empty' },
        { label: 'PayPal', value: 'PAYPAL' },
        { label: '微信', value: 'WECHAT' },
        { label: '支付宝', value: 'ALIPAY' },
        { label: '新西兰转账', value: 'NEWZEALAND' },
        { label: '跨国转账', value: 'CROSS' },
        { label: '国内转账', value: 'INLAND' },
        { label: '现金', value: 'CASH' },
        { label: '银行卡', value: 'CARD' },
        { label: '其他', value: 'OTHER' },
        { label: 'Latipay', value: 'LATIPAY' },
        { label: 'Stripe', value: 'STRIPE' },
        { label: '转账', value: 'REMITTANCE' },
        { label: '银联', value: 'UPOP' },
      ],
      statusMapper: {
        PENDING: '待退款',
        OK: '已退款',
      },
      paymentStatus: {
        PENDING_PAYMENT: '请求中',
        CONFIRMED: '已确认付款凭证',
        PAYMENT_RECEIVED: '已确认到账',
        CANCELLED: '已取消',
      },
      defaultImg,
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${(this.current - 1) * this.pageSize + (index + 1)}`,
        },
        {
          title: '线索名称',
          dataIndex: 'name',
          key: 'name',
          customCell: (record) => {
            return {
              style: {
                // 行背景色
                color: '#3372FE',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => {
                  session.set('clueDetail',record)
                  this.$router.push({path:'/clueDetail'})
                },
              },
            };
          },
        },
        // {
        //   title: '进线类型',
        //   dataIndex: 'createType',
        //   key: 'createType',
        // },
        {
          title: '线索来源',
          dataIndex: 'mainSource',
          key: 'mainSource',
         
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          key: 'mobile',
        },
        {
          title: '学号',
          dataIndex: 'stuNumber',
          key: 'stuNumber',
          width:80,
        },
        {
          title: '邮箱',
          dataIndex: 'email',
          key: 'email',
        },

        {
          title: '线索类型',
          dataIndex: 'type',
          key: 'type',
        },
        {
          title: '所有人',
          dataIndex: 'ownerName',
          key: 'ownerName',
        },
        {
          title: '阶段',
          dataIndex: 'stage',
          key: 'stage',
        },
        // {
        //   title: '科目',
        //   dataIndex: 'subject',
        //   key: 'subject',
        //   width: 80,
        // },
        // {
        //   title: '推荐人',
        //   dataIndex: 'recommendStuName',
        //   key: 'recommendStuName',
        //   width: 180,
        //   scopedSlots: { customRender: 'recommendStuName' },
        // },
        // {
        //   title: '分销大使',
        //   dataIndex: 'resellerName',
        //   key: 'resellerName',
        //   width: 180,
        //   scopedSlots: { customRender: 'resellerName' },
        // },
        {
          title: '科目',
          dataIndex: 'subject',
          key: 'subject',
         width:80,
        },
         {
          title: '学生课节状态',
          dataIndex: 'courseResult',
          key: 'courseResult',
        
        },
          {
          title: '试听时间(北京)',
          dataIndex: 'courseStartTime',
          key: 'courseStartTime',
        
        },
         {
          title: '课节取消类别',
          dataIndex: 'category',
          key: 'category',
         
        },
        // {
        //   title: '约课负责人',
        //   dataIndex: 'icDutyOwnerName',
        //   key: 'icDutyOwnerName',
        //   width: 110,
        // },
        // {
        //   title: '未试听原因',
        //   dataIndex: 'notClassReason',
        //   key: 'notClassReason',
        //   width: 200,
        //   ellipsis: true,
        // },
        // {
        //   title: '所有人',
        //   dataIndex: 'ownerName',
        //   key: 'ownerName',
        //   width: 120,
        // },
        // {
        //   title: '创建人',
        //   dataIndex: 'whoCreatedName',
        //   key: 'whoCreatedName',
        //   width: 120,
        // },
        // {
        //   title: '创建时间',
        //   dataIndex: 'whenCreated',
        //   key: 'whoCreated',
        //   width: 180,
        // },
        {
          title: '创建时间',
          dataIndex: 'whenCreated',
          key: 'whenCreated',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          with: 120,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      pageNum: 1,
      search: {
        tags: null,
        orderStatus: undefined,
        orderNumber: undefined,
      },
      searchData: null,
      tableData: null,
      selectedData: null,
      info: null,
      current: 0,
      pageSize: 10,
      allPaice: 0,
      initPagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      tags: undefined,
      loading: false,
      expand: {},
    };
  },
  components: {
    CreateClue,
  },

  mounted() {
    const obj = storage.get('userInfo');
    this.reseller = this.$route.params.reseller;
   
    this.userInfo = obj;
    this.initSearchData();
  },

  methods: {
    moment,
    changeClue(e) {
      this.selfClue = e.target.value;
      this.initPagination.current = 1;
      this.initSearchData('search');
    },
    copyLink(url) {
      const input = document.createElement('input');
      input.value = url;
      document.body.appendChild(input);
      input.select();
      document.execCommand('Copy');
      document.body.removeChild(input);
      this.$message.success('已复制链接');
    },
    // 获取约课链接
    getAppointment(uuid, type) {
      const params = {
        trackId: uuid,
        type:this.selfClue ?'current' : 'recommend'
      };
      getAppointment(params).then((res) => {
        // 复制链接
        if (type === 'copy') {
          this.copyLink(res.data.content);
        } else {
          // 去约课
          window.open(res.data.content);
        }
      });
    },
    closeSearch() {
      this.showAll = !this.showAll;
      if (this.showAll) {
        this.word = '收起';
      } else {
        this.word = '展开';
      }
    },
    resetSearch() {
      this.name = '';
      this.createdType = undefined;
      this.mainSource = undefined;
      this.stage = undefined;
      this.mobile = '';
      this.email = '';
      this.subject = undefined;
      this.whoCreatedName = '';
    },
    cancelCreateClue(val) {
      this.clueFlag = false;
      if (val === 'submit') {
        this.initSearchData();
      }
    },
    
    searchClue() {},
    createClue(type) {
      this.clueType = type;
      this.clueFlag = true;
      this.formOrigin = 'new';
    },
    createClueCopy(type, record) {
       this.clueType = type;
        this.recordObj = record;
        this.formOrigin = 'table'; // 从表格里点击
      if (type === 'INSIDE_MULTI') {
        if(record.stuNumber === (this.studentCode || this.studentProfile.code)){
            this.$router.push({ path: '/studentposter', query: { type: 'TRACK_POSTER' } });
        }else{
        //学员推荐线索也要判断+86，不允许多娃
        //  if(record.mobile.indexOf('+86')>-1){
        //       this.$message.warning('原+86线索不允许多娃')
        //     }else{
        //        this.clueFlag = true;
        //     }
        this.clueFlag = true;
      }
      }else if(type==='INSIDE_COPY'){
        //学员推荐线索也要判断+86，不允许拓科
        //  if(record.mobile.indexOf('+86')>-1){
        //       this.$message.warning('原+86线索不允许拓科')
        //     }else{
        //        this.clueFlag = true;
        //     }
        this.clueFlag = true;
      }
    },
    handleTableChange(data) {
      this.pageNum = data.current;
      this.initPagination.current = data.current;
      this.initSearchData();
    },
    initSearchData(act) {
 
      if (act === 'search') {
        this.pageNum = 1;
      }
      const params= {
        self: this.selfClue,
        pageNum: this.pageNum,
        name: this.name,
        createdType: this.createdType,
        mainSource: this.mainSource,
        stage: this.stage,
        mobile: this.mobile,
        email: this.email,
      
        subject: this.subject,
        recommendCode: this.studentCode || this.studentProfile.code,
        // resellerEmail:this.studentProfile.email,
       // recommendMobile: this.studentProfile.mobile,
       // whoCreatedName: this.whoCreatedName ? this.whoCreatedName : this.userInfo.fullName,
        recommendMobile: this.studentProfile.mobile,
        pageSize: this.pageSize,
      };
      //只有学员推荐才传这个参数
    
      if(!this.selfClue){
        params.resellerId=this.reseller ? this.reseller.uuid : ''
      }
      this.spinning = true;
      searchMyClue(params)
        .then((res) => {
          this.tableData = res.data.content;
          this.current = res.data.currentPage;
          this.pageSize = res.data.pageSize;
          this.initPagination.total = res.data.totalCount;
          this.loading = false;
        })
        .finally(() => {
          this.spinning = false;
        });
    },

    onSearch() {
      // this.initLearningReport(this.pageNum);
    },
    handleChange() {
      this.selectedData = null;
      this.info = null;
      if (this.tags) {
        this.search.tags = [this.tags];
      } else {
        this.search.tags = null;
      }
      if (this.search.orderNumber === '') {
        this.search.orderNumber = undefined;
      }

      this.initSearchData(this.search);
    },
  },
};
</script>

<style lang="less" scoped>
.order-main {
  .order-info {
    width: 35%;
    height: calc(100vh - 100px);
    overflow: auto;
    padding: 20px;
    color: #333;
    border-left: 1px solid #eee;
    .order-code {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
    }
    .order-name {
      display: flex;
      justify-content: space-between;
      p {
        font-size: 18px;
        font-weight: 600;
        color: #333;
      }
    }
    .order-status {
      font-size: 16px;
      color: #999;
      span {
        color: #333;
      }
    }
    .order-title {
      width: 100%;
      display: flex;
      font-weight: 600;
      justify-content: space-between;
      font-size: 16px;
      color: #333;
      margin: 20px 0;
      p {
        margin-bottom: 0;
        padding-left: 6px;
        border-left: 3px solid #009cff;
      }
    }
    .order-customer {
      display: flex;
      justify-content: flex-start;
      font-size: 16px;
      align-items: center;
      color: #333;
      margin-bottom: 20px;
      img {
        width: 42px;
        height: 42px;
        margin-right: 20px;
      }
      p {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 3px;
        &:hover {
          cursor: pointer;
        }
      }
      .order-customerPhone {
        color: #999;
        font-size: 14px;
        margin: 0;
        span {
          color: #009cff;
        }
      }
    }
    .order-commodity {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .num {
        width: 42px;
        height: 42px;
        text-align: center;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .commodity-info {
        width: 90%;
        .title {
          font-size: 16px;
          font-weight: 600;
          margin-right: 10px;
          line-height: 22px;
        }
        p {
          margin-bottom: 0;
        }
        .commodity-suject {
          display: inline-block;
          font-size: 10px;
          padding: 2px 4px;
          border-radius: 6px;
          color: #ff5353;
          margin-right: 10px;
          background-color: rgba(255, 83, 83, 0.1);
          border: 1px solid #ff5353;
        }
        .commodity-new {
          display: inline-block;
          font-size: 10px;
          padding: 2px 4px;
          border-radius: 6px;
          margin-right: 10px;
          color: #43d186;
          border: 1px solid #43d186;
        }
        .price {
          font-size: 12px;
          color: #999;
          margin-top: 7px;
        }
        .actuallyPaid {
          color: #333;
        }
        .number {
          font-size: 14px;
          color: #666;
        }
        & > div {
          display: flex;
          justify-content: space-between;
        }
      }
    }
    .order-remark {
      border-top: 1px solid #eee;
      margin-top: 20px;
      padding-top: 20px;
      .total {
        font-size: 16px;
        color: #333;
        font-weight: 400;
        text-align: right;
        padding-right: 10px;
        span {
          font-weight: 600;
        }
      }
      .all-price {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        p {
          display: inline-block;
          width: 33%;
        }
      }
      p {
        color: #999;
        font-size: 14px;
      }
      span {
        color: #333;
      }
    }
    .order-items {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .skus {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
    .send-unit {
      width: 50%;
      font-size: 14px;
      color: #999;
      margin-bottom: 20px;
      img {
        width: 40px;
        height: 40px;
      }
    }
    .send-address {
      width: 1000%;
    }
    .send-text {
      color: #333;
      margin-left: 10px;
    }
  }
  .empty {
    font-size: 16px;
    color: #999;
    padding-top: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 60%;
  }
}
.reports-item {
  display: flex;
  justify-content: space-between;
}
.item-label {
  width: 80px;
  text-align: right;
  display: inline-block;
}
.clue-wrap {
  width:98px;
 padding: 0;
 margin-bottom: 0;
  li {
    padding: 6px;
    cursor: pointer;
    font-size: 14px;
  }
  li:hover {
    color: #43d186;
  }
  .active {
    background: #e5f5ff;
    color: #43d186;
  }
}
.blue-txt {
  color: #3372fe;
}
</style>
