<template>
  <a-modal
    title="学员状态变更"
    :visible="visible"
    width="600px"
    :okButtonProps="{
      props: {
        loading: iconLoading,
      },
    }"
    @ok="onSubmit"
    dialogClass="schedule-related"
    @cancel="onCancel(0)"
  >
    <a-spin :spinning="loading" size="large">
      <a-form ref="ruleForm" :form="form" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item label="变更学员">
          <a-input
            disabled
            v-decorator="['studentName', { rules: [{ required: true, message: '请填写变更学员!' }] }]"
          />
        </a-form-item>

        <a-form-item label="变更状态科目">
          <a-select
            @change="changeSubject"
            placeholder="请选择"
            v-decorator="['subject', { rules: [{ required: true, message: '请选择科目!' }] }]"
          >
            <a-select-option value="CHINESE">中文</a-select-option>
            <a-select-option value="MATH">数学</a-select-option>
            <a-select-option value="ENGLISH">英文</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="当前状态(变更前)">
          <a-input
            disabled
            style="width: 100%"
            placeholder="请输入"
            v-decorator="['fromStatus', { rules: [{ required: true, message: '请填写当前状态!' }] }]"
          />
        </a-form-item>
        <a-form-item label="变更状态为">
          <a-select
            placeholder="请选择"
            v-decorator="['toStatus', { rules: [{ required: true, message: '请选择变更状态!' }] }]"
          >
            <a-select-option value="冻结课程">冻结课程</a-select-option>
            <a-select-option value="延期开课">延期开课</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="变更原因I级">
          <a-select
            placeholder="请选择"
            v-decorator="['changeReasonOne', { rules: [{ required: true, message: '请选择变更原因I级!' }] }]"
            @change="handleClassification"
          >
            <a-select-option v-for="item in changeClassification" :key="item.value" :value="item.value">{{
              item.label
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="变更原因II级">
          <a-select
            placeholder="请选择"
            v-decorator="['changeReasonTwo', { rules: [{ required: true, message: '请选择变更原因II级!' }] }]"
          >
            <a-select-option v-for="item in changeClassificationTwo" :key="item.value" :value="item.value">{{
              item.label
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="延期/冻结开始日期">
          <a-date-picker
            disabled
            style="width: 100%"
            v-decorator="['freezeFromDate', { rules: [{ required: true, message: '请选择延期/冻结开始日期!' }] }]"
            format="YYYY-MM-DD"
          />
        </a-form-item>
        <a-form-item label="延期/冻结结束日期">
          <a-date-picker
            style="width: 100%"
            v-decorator="['freezeToDate', { rules: [{ required: true, message: '请选择延期/冻结结束日期!' }] }]"
            format="YYYY-MM-DD"
          />
        </a-form-item>
        <a-form-item label="上传图片">
          <a-upload
            name="file"
            :file-list="fileList"
            list-type="picture-card"
            @change="handleChange"
            v-decorator="['imageUrl', { rules: [{ required: true, message: '请上传图片!' }] }]"
          >
            <div v-if="fileList.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item label="备 注">
          <a-textarea
            placeholder="请输入备注"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            v-decorator="['remark', { rules: [{ required: true, message: '请填写备注!' }] }]"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import { getDdlLabel } from '@/utils/util';
import { imgpostUrl } from '@/api/register';
import {
  getUploadOssToken,
  queryTakeOverStudent,
  getCourseScheduleList,
  submitPayStatus,
  queryClassHourNum,
  queryChangeReason,
  queryStudentClassHourNew,
} from '@/api/headTeacher';
import { changeClassification } from '@/utils/const';

const session = storage.createStore(sessionStorage);
export default {
  props: {
    payObj: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dateFormat: 'YYYY-MM-DD',
      queryClassDateArr: [],
      changeClassification,
      changeClassificationTwo: null,
      queryStartTime: '',
      queryEndTime: '',
      iconLoading: false,
      fileList: [],
      subjectTxt: '转出科目',
      chineseClassData: {},
      mathClassData: {},
      transferObj: {},
      reasonArr: {},
      transferArr: [],
      studentProfile: session.get('studentProfile'),
      courseScheduleList: [],
      studentList: [],
      cuTransferList: [],
      form: this.$form.createForm(this),
      transferType: '',
      visible: true,
      getDdlLabel,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      previewVisible: false,
      previewImage: '',
      uploading: false,
      studentId: '',
      loading: false,
      roles: [],
      userInfo: {},

      apiUrl: '/api/admin/scheduling/course_unit',

      formData: null,
    };
  },
  created() {
    this.studentId = this.id;
    this.roles = storage.get('roles');
    this.userInfo = storage.get('userInfo');
  },
  mounted() {
    this.$nextTick(() => {
      this.form.setFieldsValue({
        studentName: `${this.studentProfile.fullName}(${this.studentProfile.code})`,
        freezeFromDate: this.$moment(new Date()).format('YYYY-MM-DD'),
      });
    });
  },
  methods: {
    handleClassification(value) {
      this.form.setFieldsValue({ toStatusTwo: undefined });
      this.changeClassificationTwo = this.changeClassification?.find((i) => i.value === value)?.children;
    },
    getCurrentData() {
      return new Date().toLocaleDateString();
    },
    onChangeTableDate(date) {
      if (date.length > 0) {
        this.queryStartTime = date[0].format('YYYY-MM-DD');
        this.queryEndTime = date[1].format('YYYY-MM-DD');
      } else {
        this.queryStartTime = null;
        this.queryEndTime = null;
      }
    },
    queryStudentClassHour(val) {
      const params = {
        studentId: this.studentProfile.uuid,
      };
      queryStudentClassHourNew(params).then((res) => {
        if (val === 'CHINESE') {
          this.transferObj = res.data.content.chineseCuTransferMap;
          this.chineseClassData = this.transferObj;
        } else {
          this.transferObj = res.data.content.mathCuTransferMap;
          this.mathClassData = this.transferObj;
        }

        // 回填转移数量
        const obj = {};
        const arr = Object.entries(this.transferObj);
        arr.forEach((item) => {
          obj[`TOTAL${item[0]}`] = item[1].remainingCu;
          if (item[0] === 'GIFT_SOCIAL_NETWORK_SHARE' && item[1].remainingQuota > 0) {
            // 社交平台分享赠课额度

            obj[`TOTAL${item[0]}Quota`] = item[1].remainingQuota;
          }
        });

        this.$nextTick(() => {
          this.form.setFieldsValue(obj);
        });
      });
    },
    queryChangeReason() {
      const params = {
        nodeTag: 'STUDENT_CU_TRANSFER',
      };
      queryChangeReason(params).then((res) => {
        this.reasonArr = res.data.content;
      });
    },
    changeSubject(val) {
      const m = {
        CHINESE: this.payObj.status,
        MATH: this.payObj.mathStatus,
        ENGLISH: this.payObj.englishStatus,
      };
      const payStatus = m[val];

      this.$nextTick(() => {
        this.form.setFieldsValue({
          studentName: `${this.studentProfile.fullName}(${this.studentProfile.code})`,
          fromStatus: payStatus,
        });
      });
    },
    changeCourseSchedule(val) {
      const params = {
        stuScheduleId: val,
      };
      queryClassHourNum(params).then((res) => {
        this.form.setFieldsValue({
          transactionAmount: res.data.content,
        });
      });
    },
    getCourseScheduleList(name, subject) {
      const params = {
        subject,
        scheduleName: name,
        studentId: this.studentProfile.uuid,
        hasTransaction: true,
        studentTimeZone: this.studentProfile.timezoneStd,
      };
      getCourseScheduleList(params).then((res) => {
        this.courseScheduleList = res.data.content;
      });
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    getStudentInfo(name) {
      const params = {
        name,
      };
      queryTakeOverStudent(params).then((res) => {
        this.studentList = res.data.content;
      });
    },
    changeTransferType(val) {
      this.form.setFieldsValue({
        transactionReason: '',
      });
      this.transferType = val;
      if (this.transferType === 'TRANSFER') {
        this.subjectTxt = '转出科目';
        // 变更类型变了，对应的转移也要变
        const subject = this.form.getFieldValue('subject');
        if (subject) {
          this.queryStudentClassHour(subject);
        }
      } else {
        this.subjectTxt = '科目';
      }
    },
    onCancel(val) {
      this.$emit('cancelPayStatus', val);
    },
    handleChange(info) {
      if (info.file.status === 'removed') {
        this.fileList = info.fileList;
      } else {
        this.imgInfo = info;
        this.uploading = true;
        getUploadOssToken().then((res) => {
          this.uploadToOss(info, res.data.content);
        });
      }
    },
    handleCancel() {
      this.previewVisible = false;
    },
    uploadToOss(info, fileData) {
      const formData = new FormData();
      const formArray = Object.entries(fileData.formFields);
      formArray.forEach((item) => {
        formData.append(item[0], item[1]);
      });
      formData.append('file', info.file.originFileObj);
      imgpostUrl(`https://${fileData.endpoint}`, formData)
        .then((res) => {
          this.fileList.push({
            uid: res.data.fileId,
            name: 'image.png',
            status: 'done',
            url: res.data.url,
          });
          console.log(this.fileList);
        })
        .finally(() => {
          this.uploading = false;
        });
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        // eslint-disable-next-line no-undef, no-param-reassign
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },

    onSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log(values);
        if (!err) {
          this.iconLoading = true;
          const params = {
            type: 'STUDENT_STATUS',
            subject: values.subject,
            studentId: this.studentProfile.uuid,
            remark: values.remark,
            data: {
              fromStatus: values.fromStatus,
              toStatus: values.toStatus,
              changeReasonOne: values.changeReasonOne,
              changeReasonTwo: values.changeReasonTwo,
              freezeFromDate: this.$moment(values.freezeFromDate).format('YYYY-MM-DD'), // 冻结开始时间
              freezeToDate: this.$moment(values.freezeToDate).format('YYYY-MM-DD'), // 冻结结束时间
            },
          };
          if (this.fileList.length > 0) {
            params.imageUrl = this.fileList[0].url;
          }
          submitPayStatus(params)
            .then(() => {
              this.$message.success('提交成功');
              this.iconLoading = false;
              this.onCancel('submit');
            })
            .catch(() => {
              this.iconLoading = false;
            });
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      // eslint-disable-next-line no-restricted-globals
      history.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.small-txt {
  padding-left: 66px;
  margin-bottom: 12px;
  color: #999;
}
.transfer-num {
  padding: 0 12px;
  color: #999;
}
/deep/ .ant-modal-body {
  max-height: 580px;
  overflow-y: auto;
}
</style>
