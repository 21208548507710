<template>
    <div>
      <img class="image-element" :src="imageUrl" preview="1" />
    </div>
   
</template>
<script>
import { Progress } from 'element-ui'
import { mapGetters } from 'vuex'
export default {
  name: 'ImageElemnt',
  props: {
    payload: {
      type: Object,
      required: true
    },
    message: {
      type: Object,
      required: true
    },
    isMine: {
      type: Boolean
    },
    noBg: {
      type: Boolean
    }
  },
  components: {
    ElProgress: Progress
  },
  computed: {
    ...mapGetters(['imgUrlList']),
    imageUrl() {
      const url = this.payload.imageInfoArray[0].url
      if (typeof url !== 'string') {
        return ''
      }
      return url.slice(0, 2) === '//' ? `https:${url}` : url
    },
    showProgressBar() {
      return this.$parent.message.status === 'unSend'
    },
    percentage() {
      return Math.floor((this.$parent.message.progress || 0) * 100)
    }
  },
  
}
</script>

<style lang="stylus" scoped>
.border-wrap
  border:1px solid #dddddd
  min-width 50px
  min-height 50px
  border-radius 6px
  overflow hidden
  display flex
  justify-content center
  align-items center
.image-element
  max-width 100px
  max-height 100px
  cursor zoom-in
</style>
