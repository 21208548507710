<template>
  <div class="tab-wrap">
    <div class="loading-wrap" v-show="showLoading">
      <a-spin size="large" />
    </div>
    <div v-show="!showLoading">
      <div v-show="!showEditData" style="position: relative">
        <div class="editDataBtn">
          <a-button type="primary" ghost @click="editServiceFun"> 编辑 </a-button>
        </div>
        <a-descriptions class="descriptions-wrap" title="服务信息">
          <a-descriptions-item label="中文班主任">
            {{ studentServiceData.chineseClassAdmin || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="数学辅导老师">
            {{ studentServiceData.mathClassAdmin || '-' }}
          </a-descriptions-item>

          <a-descriptions-item label="中文学习规划师">
            {{ studentServiceData.chineseCourseConsultant || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="数学学习规划师">
            {{ studentServiceData.mathCourseConsultant || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="英文辅导老师">
            {{ studentServiceData.englishClassAdmin || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="英文学习规划师">
            {{ studentServiceData.englishCourseConsultant || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="推荐人">
            {{ studentServiceData.recommend || '-' }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions class="descriptions-wrap" title="学生画像">
          <a-descriptions-item label="学习风格">
            {{ (studentServiceData.stuProfile && studentServiceData.stuProfile.style) || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="兴趣爱好课外班">
            {{ (studentServiceData.stuProfile && studentServiceData.stuProfile.hobby) || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="其他Online课程">
            {{ (studentServiceData.stuProfile && studentServiceData.stuProfile.otherOnlineCourse) || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="学生画像补充">
            {{ (studentServiceData.stuProfile && studentServiceData.stuProfile.hobbyDesc) || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="个人标签">
            <a-space
              :style="{ flexWrap: 'wrap', gap: '8px 0' }"
              v-if="studentServiceData.stuProfile.wechatTags && studentServiceData.stuProfile.wechatTags.length"
            >
              <a-tag
                v-for="item in studentServiceData.stuProfile.wechatTags.filter((item) => item.type === 2)"
                :key="item"
                color="blue"
                >{{ item.tagName }}</a-tag
              >
            </a-space>
            <span v-else>-</span>
          </a-descriptions-item>
          <a-descriptions-item label="企业标签">
            <a-space
              :style="{ flexWrap: 'wrap', gap: '8px 0' }"
              v-if="studentServiceData.stuProfile.wechatTags && studentServiceData.stuProfile.wechatTags.length"
            >
              <a-tag
                v-for="item in studentServiceData.stuProfile.wechatTags.filter((item) => item.type === 1)"
                :key="item"
                color="blue"
                >{{ item.tagName }}</a-tag
              >
            </a-space>
            <span v-else>-</span>
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions class="descriptions-wrap" title="家长画像">
          <a-descriptions-item label="家长微信昵称">
            {{ studentServiceData.wechatNickname || '-' }}
          </a-descriptions-item>
          <!-- <a-descriptions-item label="家长手机" >
             
             <span>
            手机
             <a-icon
              type='eye'
            
            /><br/>
            邮箱
             </span>
            <a-icon
              :type="code === studentDetail.classInId ? 'eye' : 'eye-invisible'"
              @click="onShowCode(studentDetail.classInId)"
            />
         
        </a-descriptions-item> -->
          <!-- <a-descriptions-item label="家长邮箱">
          {{ studentServiceData.wechatNickname || '-' }}
        </a-descriptions-item> -->
          <a-descriptions-item label="学习负责人">
            {{ (studentServiceData.stuProfile && studentServiceData.stuProfile.parentsName) || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="职业状态">
            {{ (studentServiceData.stuProfile && studentServiceData.stuProfile.workStatus) || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="互动活跃度">
            {{ (studentServiceData.stuProfile && studentServiceData.stuProfile.activity) || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="用户影响力">
            {{ (studentServiceData.stuProfile && studentServiceData.stuProfile.influence) || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="沟通方式偏好">
            {{ (studentServiceData.stuProfile && studentServiceData.stuProfile.preferCommunicateWay) || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="公众号提醒">
            {{
              studentServiceData.stuProfile && studentServiceData.stuProfile.officialAccountReminder
                ? '已开启'
                : '未开启' || '-'
            }}
          </a-descriptions-item>
          <a-descriptions-item label="沟通时间偏好(学生时间)">
            {{ (studentServiceData.stuProfile && studentServiceData.stuProfile.preferCommunicateTime) || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="家长画像补充">
            {{ (studentServiceData.stuProfile && studentServiceData.stuProfile.parentsDescribe) || '-' }}
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <div class="edit-personal" v-show="showEditData">
        <a-form
          class="ant-advanced-search-form"
          autocomplete="false"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 12 }"
          :form="form"
        >
          <InfoTitle title="学生画像" />
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-item label="学习风格">
                <a-select style="width: 200px" allowClear v-decorator="['stuProfile.style', {}]" placeholder="请选择">
                  <a-select-option value="视觉型"> 视觉型 </a-select-option>
                  <a-select-option value="听觉型">听觉型 </a-select-option>
                  <a-select-option value="体验型"> 体验型 </a-select-option>
                </a-select>
              </a-form-item>
              <a-popover>
                <template slot="content">
                  <ul class="task-ulWrap">
                    <li>
                      <span
                        >视觉型学习者通过“看”学得最好，他们喜欢通过看文字、图片、图表、视频等学习模式来记住信息或者掌握知识</span
                      >
                    </li>
                    <li><span>听觉型学习者通过“听”学得最好，他们更喜欢听课或者大声重复信息的学习模式</span></li>
                    <li>
                      <span
                        >体验型学习者通过“做”学得最好，他们喜欢学习的过程中能够移动、触碰一些东西或者亲自参与动手测试</span
                      >
                    </li>
                  </ul>
                </template>
                <i class="explain"></i>
              </a-popover>
            </a-col>
            <a-col :span="8">
              <a-form-item label="兴趣爱好课外班">
                <a-select mode="multiple" allowClear v-decorator="['stuProfile.hobby', {}]" placeholder="请选择">
                  <a-select-option value="绘画、舞蹈、声乐、表演等艺术类"
                    >绘画、舞蹈、声乐、表演等艺术类</a-select-option
                  >
                  <a-select-option value="钢琴、小提起等乐器类">钢琴、小提起等乐器类</a-select-option>
                  <a-select-option value="编程、乐高、Stem等科技编程类">编程、乐高、Stem等科技编程类</a-select-option>
                  <a-select-option value="数学、英文等基础学科类">数学、英文等基础学科类</a-select-option>
                  <a-select-option value="游泳、网球、篮球等运动类">游泳、网球、篮球等运动类</a-select-option>
                  <a-select-option value="其他">其他</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="其他Online课程">
                <a-select
                  mode="multiple"
                  allowClear
                  v-decorator="['stuProfile.otherOnlineCourse', {}]"
                  placeholder="请选择"
                >
                  <a-select-option value="绘画Online">绘画Online</a-select-option>
                  <a-select-option value="乐器陪练">乐器陪练</a-select-option>
                  <a-select-option value="数学Online">数学Online</a-select-option>
                  <a-select-option value="英文Online">英文Online</a-select-option>
                  <a-select-option value="编程Online">编程Online</a-select-option>
                  <a-select-option value="其他">其他</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <!-- <a-col :span="8">
            <a-form-item label="学生画像补充">
                <a-input allowClear placeholder="请填写" v-decorator="['stuProfile.hobbyDesc', {}]" />
            </a-form-item>
          </a-col>    -->
            <a-col :span="24">
              <a-form-item label="学生画像补充" :labelCol="{ span: 2, offset: 0 }">
                <a-input allowClear placeholder="请填写" :maxLength="200" v-decorator="['stuProfile.hobbyDesc', {}]" />
              </a-form-item>
            </a-col>
          </a-row>

          <InfoTitle title="家长画像" />
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-item label="学习负责人">
                <a-select allowClear v-decorator="['stuProfile.parentsName', {}]" placeholder="请选择">
                  <a-select-option value="妈妈">妈妈</a-select-option>
                  <a-select-option value="爸爸">爸爸</a-select-option>
                  <a-select-option value="爸爸妈妈都负责">爸爸妈妈都负责</a-select-option>
                  <a-select-option value="其他">其他</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="职业状态">
                <a-select allowClear v-decorator="['stuProfile.workStatus', {}]" placeholder="请选择">
                  <a-select-option value="家庭为主">家庭为主</a-select-option>
                  <a-select-option value="职场精英">职场精英</a-select-option>
                  <a-select-option value="不确定"> 不确定</a-select-option>
                  <a-select-option value="其他">其他</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="互动活跃度">
                <a-select allowClear v-decorator="['stuProfile.activity', {}]" placeholder="请选择">
                  <a-select-option value="热情互动"> 热情互动</a-select-option>
                  <a-select-option value="常规沟通">常规沟通</a-select-option>
                  <a-select-option value="偶尔回复">偶尔回复</a-select-option>
                  <a-select-option value="基本不回复">基本不回复</a-select-option>
                  <a-select-option value="其他">其他</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="家长影响力">
                <a-select allowClear v-decorator="['stuProfile.influence', {}]" placeholder="请选择">
                  <a-select-option value="明星级博主/自媒体，影响力卓越">明星级博主/自媒体，影响力卓越</a-select-option>
                  <a-select-option value="群主/KOL，影响力较大">群主/KOL，影响力较大</a-select-option>
                  <a-select-option value="推荐朋友较多，有一定影响力">推荐朋友较多，有一定影响力</a-select-option>
                  <a-select-option value="常规">常规</a-select-option>
                  <a-select-option value="其他">其他</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="沟通方式偏好">
                <a-select allowClear v-decorator="['stuProfile.preferCommunicateWay', {}]" placeholder="请选择">
                  <a-select-option value="无"> 无 </a-select-option>
                  <a-select-option value="电话"> 电话 </a-select-option>
                  <a-select-option value="个人微信"> 个人微信 </a-select-option>
                  <a-select-option value="微信群"> 微信群 </a-select-option>
                  <a-select-option value="短信"> 短信 </a-select-option>
                  <a-select-option value="邮件"> 邮件 </a-select-option>
                  <a-select-option value="花果IM"> 花果IM </a-select-option>
                  <a-select-option value="Whats app"> Whats app </a-select-option>
                  <a-select-option value="各种方式都联系不上"> 各种方式都联系不上 </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="公众号提醒">
                <a-checkbox
                  disabled
                  v-if="studentServiceData.stuProfile"
                  v-decorator="['stuProfile.officialAccountReminder', {}]"
                >
                </a-checkbox>
                <span style="padding-left: 5px">开启</span>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="沟通时间偏好" :labelCol="{ span: 2, offset: 0 }">
                <a-input
                  allowClear
                  placeholder="请填写"
                  :maxLength="200"
                  v-decorator="['stuProfile.preferCommunicateTime', {}]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="家长画像补充" :labelCol="{ span: 2, offset: 0 }">
                <a-input
                  allowClear
                  placeholder="请填写"
                  :maxLength="200"
                  v-decorator="['stuProfile.parentsDescribe', {}]"
                />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <div class="edit-btn">
          <a-button @click="cancelData"> 取消 </a-button>
          <a-button type="primary" @click="onSaveData"> 保存编辑 </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import { queryStudentService, editStudent } from '@/api/headTeacher';

import InfoTitle from '@/components/WkInfoTitle/InfoTitle';

const session = storage.createStore(sessionStorage);

export default {
  name: 'studentService',
  components: { InfoTitle },
  data() {
    return {
      showLoading: true,
      studentServiceData: {},
      studentId: '',
      showEditData: false,
      userInfo: {},
      form: this.$form.createForm(this),
      studentProfile: session.get('studentProfile'),
    };
  },
  methods: {
    onSaveData(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const datas = values;
          datas.fullName = this.studentProfile.fullName;
          datas.stuProfile.hobby = datas.stuProfile.hobby && datas.stuProfile.hobby.join(',');
          datas.stuProfile.otherOnlineCourse =
            datas.stuProfile.otherOnlineCourse && datas.stuProfile.otherOnlineCourse.join(',');

          editStudent({ uuid: this.$route.query.uuid, data: datas }).then(() => {
            this.$message.success('保存成功');
            // 获取服务模块
            this.queryStudentService();
            this.showEditData = false;
          });
        }
      });
    },
    cancelData() {
      this.showEditData = false;
    },
    editServiceFun() {
      this.showEditData = true;

      const allValues = this.form.getFieldsValue();
      console.log(allValues);
      Object.keys(allValues).forEach((item) => {
        allValues[item] = this.studentServiceData[item];
      });

      console.log(this.studentServiceData.stuProfile);
      allValues.stuProfile.hobby = this.studentServiceData.stuProfile.hobby
        ? this.studentServiceData.stuProfile.hobby.split(',')
        : [];
      allValues.stuProfile.otherOnlineCourse = this.studentServiceData.stuProfile.otherOnlineCourse
        ? this.studentServiceData.stuProfile.otherOnlineCourse.split(',')
        : [];
      this.$nextTick(() => {
        this.form.setFieldsValue(allValues);
      });
    },
    queryStudentService() {
      const params = {
        studentId: this.studentId,
      };
      queryStudentService(params).then((res) => {
        this.showLoading = false;
        this.studentServiceData = res.data.content;
      });
    },
  },
  mounted() {
    const obj = storage.get('userInfo');
    this.userInfo = obj;

    this.studentId = this.$route.query.uuid || this.studentProfile.uuid;
    this.queryStudentService();
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-descriptions-item > span {
  display: inline-flex;
}
//修改编辑表单label颜色
/deep/ .ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.6);
}
/deep/ .ant-input,
.ant-select {
  color: #1f2d3d;
}
/deep/ .ant-descriptions-item-label {
  color: rgba(31, 45, 61, 0.4);
}
/deep/ .ant-descriptions-item-content {
  color: #1f2d3d;
}
.editDataBtn {
  position: absolute;
  right: 0px;
  top: 0px;
}
.edit-personal {
  background-color: #fff;
  border-radius: 10px;
  p {
    margin-bottom: 0;
  }
  .personal-title {
    background: #f7f7f7;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    border-radius: 8px;
    padding: 10px 20px;
    margin-bottom: 20px;
  }
  .info-main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .time-zone {
      width: 100%;
      margin-bottom: 20px;
    }
    .info-item {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      font-size: 16px;
      margin-bottom: 10px;
      span {
        display: inline-block;
        width: 150px;
        line-height: 22px;
        color: #999;
      }
      p {
        color: #333;
        margin-bottom: 0;
      }
    }
    .info-time {
      width: 100%;
      span {
        color: #333;
      }
    }
  }
  .edit-btn {
    width: calc(100% - 156px);
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    background-color: #fff;
    position: fixed;
    right: 20px;
    bottom: 0px;
    border-top: 1px solid #f0f0f0;
    // box-shadow: 0 -6px 16px -8px rgb(0 0 0 / 8%), 0 -9px 28px 0 rgb(0 0 0 / 5%), 0 -12px 48px 16px rgb(0 0 0 / 3%);
    .ant-btn {
      margin: 0 10px;
    }
    // .ant-btn-primary {
    //   background: #009cff;
    // }
  }
}
.loading-wrap {
  padding-top: 200px;
  text-align: center;
}
</style>
