import { render, staticRenderFns } from "./studentReport.vue?vue&type=template&id=62fe7393&scoped=true&"
import script from "./studentReport.vue?vue&type=script&lang=js&"
export * from "./studentReport.vue?vue&type=script&lang=js&"
import style0 from "./studentReport.vue?vue&type=style&index=0&id=62fe7393&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62fe7393",
  null
  
)

export default component.exports