<template>
  <div class="custom-element-wrapper">
    <div class="survey"  v-if="this.payload.data === 'survey'">
      <div class="title">对IM DEMO的评分和建议</div>
      <el-rate
          v-model="rate"
          disabled
          show-score
          text-color="#ff9900"
          score-template="{value}">
      </el-rate>
      <div class="suggestion">{{this.payload.extension}}</div>
    </div>
    <div class="conversation-down"  v-else-if="this.payload.data === 'askFinishWuKongJunior'">
      <div >请问是否已解决你的问题：</div>
      <div class="conversation-down-text">
        <span>已解决</span>
        <span>未解决，我还有问题咨询</span>
      </div>
  
    </div>
    <div class="askIMRobot"  v-else-if="this.payload.data === 'askIMRobot'">
      <div >{{text}}</div>
      <div class="askIMRobot-btn" >
        <div>
           • 转接小悟空聊天
        </div>
        <div>
          为您提供专属服务，24小时在线
        </div>
      </div>
    </div>
    <span class="text" title="您可以自行解析自定义消息" v-else>
     
      <template>{{text}}</template>
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Rate } from 'element-ui'

export default {
  name: 'CustomElement',
  props: {
    payload: {
      type: Object,
      required: true
    },
    message: {
      type: Object,
      required: true
    },
    isMine: {
      type: Boolean
    }
  },
  mounted(){
  },
  components: {
    ElRate: Rate,
  },
  computed: {
    ...mapState({
      currentUserProfile: state => state.userIM.currentUserProfile
    }),
    text() {
      return this.translateCustomMessage(this.payload)
    },
    rate() {
      return parseInt(this.payload.description)
    }
  },
  methods: {
    translateCustomMessage(payload) {
      let videoPayload = {}
      try{
        videoPayload = JSON.parse(payload.data)
      } catch(e) {
        videoPayload = {}
      }
      if (payload.data === 'group_create') {
        return `${payload.extension}`
      }
      if(payload.data === 'askIMRobot' || payload.data === 'createGroup') {
          return payload.description
      }
      if (videoPayload.roomId) {
        videoPayload.roomId = videoPayload.roomId.toString()
        videoPayload.isFromGroupLive = 1
        return videoPayload
      }
      if(payload.text) {
        return payload.text
      }else{
        return '[自定义消息]'
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.text
  font-weight bold
.title
  font-size 16px
  font-weight 600
  padding-bottom 10px
.survey
  background-color white
  color black
  padding 20px
  display flex
  flex-direction column
.suggestion
  padding-top 10px
  font-size 14px
.conversation-down{
  .conversation-down-text{
    span{
      display inline-block
      padding 2px 6px
      // background: #009CFF;
      border 1px solid #fff;
      border-radius 4px
      &:nth-child(1){
        margin-right 6px
      }
    }


  }
}
.askIMRobot-btn
  margin-top 10px
</style>
