<template>
  <div>
    <a-drawer
      class="review-main"
      title="课后作业"
      placement="right"
      width="800"
      :closable="true"
      :get-popup-container="getPopupContainer"
      :visible="visible"
      @close="onClose"
    >
      <div class="loading" v-if="loadingBol">
        <a-spin size="large" />
      </div>
      <div style="padding:20px">
          <!-- 老师主观题 -->
            <!-- dataInfo报告数据源、reportHistory历史报告数据源 studentReview学生数据源 -->
            <CheckReviewOperation
             :explainData="explainData"
              :dataInfos="dataInfo"
              :reportHistory="reportHistory"
              :studentReview="studentReview"
              :studentId="studentId"
              :checkData="checkData"
              :subject="subject"
               @explainResolution="explainResolution"
            >
             
            </CheckReviewOperation>
          
            <div v-if="dataInfo && dataInfo.length === 0" class="empty">
              <p>学生还未提交作业</p>
              <img src="@/assets/icons/pic_emptypage.svg" alt="" />
            </div>
      </div>
    </a-drawer>
  
    <component
      :is="currentView"
      :reportHistory="reportHistory"
      :historyType="historyType"
      :studentReview="studentReview"
      :studentId="studentId"
    ></component>
  </div>
</template>
<script>
import InfoTitle from '@/components/WkInfoTitle/InfoTitle';
import ReadReport from './ReadReport';
import ExerciseReport from './ExerciseReport';
import DoItReport from './DoItReport';
import MathReport from './MathReport';
import ReportHistoryOperation from './ReportHistoryOperation';
import {submitHomeWork,historyHomeWork} from '@/api/headTeacher';
import { CheckReviewOperation } from '@wk/tch-sc';
import { questionDetail } from '@/api/headTeacher';

export default {
  components: { InfoTitle, ReadReport, ExerciseReport, DoItReport, MathReport, ReportHistoryOperation,CheckReviewOperation},
  props: {
    studentReview: {
      type: Object,
      default: () => {},
    },
    studentId: {
      type: String,
      default: '',
    },
    teacherId: {
      type: String,
      default: '',
    },
     checkData: {
      type: Array,
      default: () => [],
    },
    courseScheduleId:{
      type: String,
      default: '',
    },
    studentId:{
      type: String,
      default: '',
    },
    subject:{
      type:String,
      default:''
    }
  },
  data() {
    return {
       explainData: {},
      reportHistory: null,
      currentView: null,
      loadingBol: true,
      visible: true,
      dataInfo: null,
      historyType: '',
      audio: null,
      parentaudio: '',
      studentUuid: this.studentId,
      teacherUuid: this.teacherId,
    };
  },
  created() {
    this.initReport();
  },
  methods: {
    //获取问题解析
    
    explainResolution(id) {
      this.explainData = {};
       questionDetail({uuid: id}).then((res) => {
        if (res.status === 200) {
          this.explainvisible = true;
          this.explainData = res.data.content.contentOption;
        }
      });
    
    },
    openCheckOperation(sortNum){
      this.$emit("openCheckOperation",sortNum)
    },
    onReportHistory(values) {
      this.clearAudio();
      this.currentView = 'ReportHistoryOperation';
      this.historyType = values;
    },
    //学生已提交作业
  submitHomeWork(){
    let params={
       courseScheduleId:this.courseScheduleId,
       studentId:this.studentId,
       teacherId:this.teacherId
    }
    submitHomeWork(params).then(res=>{
          this.dataInfo = res.data.content;
          console.log( this.dataInfo )
          this.loadingBol = false;
    })
  },
  //获取学生作业历史详情
  historyHomeWork(){
    let params={
       courseScheduleId:this.courseScheduleId,
       studentId:this.studentId,
       teacherId:this.teacherId
    }
    historyHomeWork(params).then(res=>{
       this.reportHistory = res.data.content;

    })
  },
    initReport() {

      this.loadingBol = true;
      this.dataInfo = [];
      this.submitHomeWork()
      this.historyHomeWork()
      
    },
   
    audioPlay(value) {
      if (!value.audio) {
        this.$message.warning('暂无朗读录音');
        return;
      }
      if (this.audio && this.parentaudio !== value.audio) {
        this.audio.pause();
        this.audio = null;
      }
      if (!this.audio) {
        this.audio = new Audio();
        this.audio.loop = false;
        this.audio.addEventListener(
          'ended',
          () => {
            this.audio.pause();
            this.audio = null;
            this.parentaudio = '';
          },
          false,
        );
        this.audio.src = value.audio;
        this.parentaudio = value.audio;
        this.audio.play();
      }
    },
    clearAudio() {
      // this.parentaudio = value;
      if (this.audio) {
        this.audio.pause();
        this.audio = null;
        this.parentaudio = '';
      }
    },
    callback() {},
    cancal() {
      this.currentView = null;
    },
    onClose() {
      this.loadingBol = false;
      this.$parent.cancal();
    },
    classOutInTime(value) {
      let theTime = Number(value);
      let middle = 0;
      let hour = 0;
      if (theTime > 60) {
        middle = parseInt(theTime / 60, 10);
        theTime = parseInt(theTime % 60, 10);
        if (middle > 60) {
          hour = parseInt(middle / 60, 10);
          middle = parseInt(middle % 60, 10);
        }
      }
      let result = '';
      if (parseInt(theTime, 10) > 0) {
        result = `${parseInt(theTime, 10)}秒`;
      }
      if (middle > 0) {
        result = `${parseInt(middle, 10)}分${result}`;
      }
      if (hour > 0) {
        result = `${parseInt(hour, 10)}时${result}`;
      }
      return result;
    },
    getPopupContainer(trigger) {
      return trigger.parentElement.parentElement;
    },
  },
};
</script>
<style lang="less">
.review-main {
  .ant-drawer-body {
    padding: 0;
  }
  .ant-tabs-content {
    overflow-y: auto;
    height: calc(100vh - 48px);
  }
  .ant-tabs .ant-tabs-left-content {
    padding: 24px;
  }
  .ant-tabs-nav {
    .ant-tabs-tab-active {
      color: #000;
      background-color: #fff;
    }
    .ant-tabs-tab {
      width: 150px;
      text-align: center;
      padding: 16px 24px;
      text-align: left;
      margin: 0;
    }
    .ant-tabs-ink-bar {
      background-color: #fff;
    }
  }
  .ant-tabs-nav-wrap {
    background-color: #f7f7f7;
  }
  .ant-tabs-left-content {
    border-left: none;
  }
  .tabs-main {
    height: calc(100vh - 55px);
    overflow-y: auto;
  }
  .read-report {
    display: flex;
    justify-content: space-between;
    p:hover {
      cursor: pointer;
    }
  }
  .empty {
    height: 70vh;
    font-size: 16px;
    color: #999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .loading {
    width: 80%;
    height: 100%;
    background-color: rgba(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 20% 0 0 0;
    text-align: center;
    font-size: 60px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
  }
}
.detail-btnWrap{
  margin-right:10px;
  cursor: pointer;
  color:#009cff
}
</style>
