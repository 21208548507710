<template>
  <div class="tab-wrap">
    <div>
      <a-table
        :columns="columns"
        :data-source="tableData"
        @change="handleTableChange"
        :loading="showLoading"
        :key="selType"
        :pagination="initpagination"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
        <a slot="level" slot-scope="text">{{ text }}</a>
        <template slot="userName" slot-scope="userName, record">
          <span>{{ userName }}</span>
          <p>
            {{ record.role }} <span v-if="record.department">({{ record.department }})</span>
          </p>
        </template>
        <template slot="whenCreated" slot-scope="whenCreated">
          <span>{{ $moment(whenCreated).format('YYYY/MM/DD') }}</span>
          <p>{{ $moment(whenCreated).format('HH:mm:ss') }}</p>
        </template>
        <template slot="subject" slot-scope="subject">
          <span>{{ subject === 'CHINESE' ? '中文' : '数学' }}</span>
        </template>
        <template slot="reportType" slot-scope="reportType">
          <span>{{ reportType === 'STAGE_LEARNING_REPORT' ? '阶段学习报告' : '阶段结业证书' }}</span>
        </template>
        <template slot="operation" slot-scope="operation, record">
          <a style="margin-right: 10px; color: #3372fe" @click="download(record.imageUrl, '报告', record)">{{
            record.reportType === 'STAGE_LEARNING_REPORT' ? '生成报告' : '生成证书'
          }}</a>
          <a
            style="color: #3372fe"
            v-if="
              userInfo.roleName === 'CLASS_ADMIN' ||
              userInfo.roleName === 'HEAD_TEACHER_LEADER' ||
              userInfo.roleName === 'X_WUKONG'
            "
            @click="sendGroupRecord(record.imageUrl, record)"
            >发送至群</a
          >
          <!-- <a @click="download(record.imageUrl, record)">发送至群</a> -->
        </template>
      </a-table>
      <a-modal
        title="结业证书"
        :visible="visibleCertificate"
        @ok="handleCertificate"
        okText="复制"
        cancelText="关闭"
        :width="studentInfo.subject === 'CHINESE' ? '890px' : '640px'"
        @cancel="onCancelCertificate"
        v-if="studentInfo !== null"
      >
        <div
          :class="studentInfo.subject === 'CHINESE' ? 'certificateWrapperCNTC' : 'certificateWrapperTC'"
          ref="certificateWrapperTC"
        >
          <CertificateTemplete :studentInfo="studentInfo"></CertificateTemplete>
        </div>
      </a-modal>
      <div
        :class="studentInfo.subject === 'CHINESE' ? 'certificateWrapperCN' : 'certificateWrapper'"
        ref="certificateWrapper"
        v-if="studentInfo !== null"
      >
        <CertificateTemplete :studentInfo="studentInfo"></CertificateTemplete>
      </div>

      <!-- 生成海报模板 -->
      <a-modal
        title="报告详情"
        :visible="visibleReport"
        @ok="handleSubmit"
        okText="复制"
        cancelText="关闭"
        width="426px"
        @cancel="onCancelReport"
      >
        <div class="modal-height">
          <div class="studyReportTemplateTC" ref="studyReportTemplateOldTC" v-if="studentInfo && !showNewReport">
            <StudyReportTemplateOld :studyData="studentInfo"></StudyReportTemplateOld>
          </div>
          <div
            class="studyReportTemplate2TC"
            ref="studyReportTemplateTC"
            v-if="studentInfo && showNewReport && showClassSection"
          >
            <StudyReportTemplate :studyData="studentInfo"></StudyReportTemplate>
          </div>
        </div>
      </a-modal>
      <!--旧的学习报告-->
      <div class="studyReportTemplate" ref="studyReportTemplateOld" v-if="studentInfo && !showNewReport">
        <StudyReportTemplateOld :studyData="studentInfo"></StudyReportTemplateOld>
      </div>
      <div
        class="studyReportTemplate"
        ref="studyReportTemplate"
        v-if="studentInfo && showNewReport && showClassSection"
      >
        <StudyReportTemplate :studyData="studentInfo"></StudyReportTemplate>
      </div>
    </div>
  </div>
</template>
<script>
import { CertificateTemplete, StudyReportTemplate, StudyReportTemplateOld } from '@wk/tch-sc';
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import html2canvas from 'html2canvas';
import { imgpostUrl } from '@/api/register';
import {
  searchParams,
  learningReportSearch,
  sendPosterGroup,
  getUploadOssToken,
  queryKnowledgeSummery,
} from '@/api/headTeacher';

import { getUserUUID } from '@/utils/user';
import { reportEvent } from '@/utils/gatherer';

const session = storage.createStore(sessionStorage);

export default {
  name: 'studentReport',
  components: { CertificateTemplete, StudyReportTemplate, StudyReportTemplateOld },
  props: {
    selType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableSorters: null,
      filtersObj: null,
      courseSectionId: '',
      visibleReport: false,
      visibleCertificate: false,
      showLoading: false,
      reportSubject: '',
      showClassSection: false, //获取课节配置参数
      showNewReport: false,
      userInfo: {},
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          customRender: (text, record, index) =>
            `${(this.initpagination.current - 1) * this.initpagination.pageSize + (index + 1)}`,
        },
        {
          title: '阶段名称',
          dataIndex: 'level',
          key: 'level',
          scopedSlots: { customRender: 'level' },
        },
        {
          title: '分类',
          dataIndex: 'reportType',
          key: 'reportType',
          width: 150,
          // filters: [
          //     { text: '阶段学习报告', value: 'STAGE_LEARNING_REPORT' },
          //     { text: '阶段结业证书', value: 'CERTIFICATE_COMPLETION' },
          //   ],
          scopedSlots: { customRender: 'reportType' },
        },
        {
          title: '科目',
          width: 150,
          dataIndex: 'subject',
          key: 'subject',
          scopedSlots: { customRender: 'subject' },
          filters: [
            { text: '中文', value: 'CHINESE' },
            { text: '数学', value: 'MATH' },
          ],
        },
        {
          title: '所属班级',
          dataIndex: 'formClassName',
          key: 'formClassName',
          ellipsis: true,
          width: 200,
        },
        {
          title: '创建人',
          dataIndex: 'userName',
          key: 'userName',

          scopedSlots: { customRender: 'userName' },
        },
        {
          title: '创建时间',
          dataIndex: 'whenCreated',
          key: 'whenCreated',
          sorter: true,
        },
        {
          title: '操作',
          dataIndex: 'operation',

          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      pageNum: 1,
      total: 0,
      studentId: '',
      search: {
        subject: undefined,
        level: undefined,
        className: undefined,
        reportType: this.tableType,
      },

      tableData: null,
      initpagination: {
        total: 0,
        current: 1,
        pageSize: 20,
      },
      studentInfo: null,
      studyData: null,
      studentProfile: session.get('studentProfile'),
    };
  },

  mounted() {
    //重置筛选条件
    this.tableSorters = null;
    this.filtersObj = null;
    this.studentId = this.$route.query.uuid || this.studentProfile.uuid;
    this.userInfo = storage.get('userInfo');
    this.fetch();
  },
  watch: {
    selType() {
      this.tableSorters = null;
      this.filtersObj = null;
      this.fetch();
    },
  },
  methods: {
    handleSubmit(record) {
      if (this.showNewReport) {
        //  (async () => {
        //     await this.queryKnowledgeSummery(this.courseSectionId);
        this.$nextTick(() => {
          console.log(this.$refs.studyReportTemplate);
          html2canvas(this.$refs.studyReportTemplate).then((canvas) => {
            const dataURL = canvas.toDataURL('image/png');
            const fileName = `${this.studentInfo.name}的阶段学习报告${this.studentInfo.progress.substring(
              0,
              this.studentInfo.progress.indexOf('-'),
            )}-${this.$moment(new Date()).format('YYYYMMDDHHmmss')}.png`;

            this.downloadByBlob(dataURL, fileName);
          });
        });
        // })
        //();
      } else {
        this.$nextTick(() => {
          html2canvas(this.$refs.studyReportTemplateOldTC).then((canvas) => {
            const dataURL = canvas.toDataURL('image/png');
            const fileName = `${this.studentInfo.name}的阶段学习报告${this.studentInfo.progress.substring(
              0,
              this.studentInfo.progress.indexOf('-'),
            )}-${this.$moment(new Date()).format('YYYYMMDDHHmmss')}.png`;

            this.downloadByBlob(dataURL, fileName);
          });
        });
      }
    },
    onCancelReport() {
      this.visibleReport = false;
    },
    handleCertificate(record) {
      this.$nextTick(() => {
        html2canvas(this.$refs.certificateWrapperTC).then((canvas) => {
          const dataURL = canvas.toDataURL('image/png');
          const fileName = `${this.studentInfo.name}的阶段结业证书${this.studentInfo.level}-${this.$moment(
            new Date(),
          ).format('YYYY/MM/DD HH:mm:ss')}.png`;
          this.downloadByBlob(dataURL, fileName);
        });
      });
    },
    onCancelCertificate() {
      this.visibleCertificate = false;
    },
    queryKnowledgeSummery(id) {
      let params = {
        sectionId: id,
      };
      return queryKnowledgeSummery(params).then((res) => {
        this.$set(this.studentInfo, 'classSection', res.data.content.knowledge);

        this.showClassSection = true;
      });
    },
    handleTableChange(pagination, filters, sorter) {
      this.tableSorter = sorter;
      this.filtersObj = filters;
      let sort = '';
      if (this.tableSorter.order === 'descend') {
        sort = 'desc';
      } else if (this.tableSorter.order === 'ascend') {
        sort = 'asc';
      }
      this.pageNum = pagination.current;
      const pager = { ...this.initpagination };
      pager.current = pagination.current;
      this.initpagination = pager;
      let params = {
        subjects: this.filtersObj.subject,
      };
      if (this.tableSorter.order) {
        params.orderBy = `${this.tableSorter.columnKey} ${sort}`;
      }
      this.fetch(params);
    },
    fetch(obj = {}) {
      console.log(params);
      let reportTypes = [];
      if (this.selType === 'report') {
        reportTypes = ['STAGE_LEARNING_REPORT'];
      } else if (this.selType === 'certificate') {
        reportTypes = ['CERTIFICATE_COMPLETION'];
      }

      this.showLoading = true;
      let params = {
        data: {
          studentId: this.studentId,
          reportTypes: reportTypes,
          ...obj,
        },
        num: this.pageNum,
      };
      learningReportSearch({
        results: 10,
        ...params,
      }).then((res) => {
        this.showLoading = false;

        this.tableData = res.data.content.content;
        this.total = res.data.content.totalCount;
        this.initpagination.total = res.data.content.totalCount;
      });
    },
    sendGroupRecord(url, record) {
      reportEvent({
        data: {
          Code: this.studentProfile.code,
          subject: record.data.subject,
          CourseSchedule_id: record.data.courseScheduleId,
          report_id: record.uuid,
          _event: 'CAstudio_Learning_SendGroupClick',
          User_id: getUserUUID(),
        },
      });

      this.reportSubject = record.subject;
      console.log(record);
      this.studentInfo = null;
      this.showNewReport = record.data.newStudy;

      if (record.reportType === 'STAGE_LEARNING_REPORT') {
        this.studentInfo = {
          score: record?.data?.score || 0,
          name: this.studentProfile.fullName,
          progress: record.level,
          teacherName: record.userName,
          createTime: record.whenCreated.substring(0, 10),
          listeningLevel: record?.data?.listeningLevel,
          readingLevel: record?.data?.readingLevel,
          speakingLevel: record?.data?.speakingLevel,
          writingLevel: record?.data?.writingLevel,
          remake: record?.data?.remake,
          subject: record?.data?.subject,
        };

        if (this.showNewReport) {
          (async () => {
            await this.queryKnowledgeSummery(record.data.courseSectionId);
            this.$nextTick(() => {
              html2canvas(this.$refs.studyReportTemplate).then((canvas) => {
                const dataURL = canvas.toDataURL('image/png');
                const fileName = `${this.studentInfo.name}的阶段学习报告${this.studentInfo.progress.substring(
                  0,
                  this.studentInfo.progress.indexOf('-'),
                )}-${this.$moment(new Date()).format('YYYYMMDDHHmmss')}.png`;

                this.sendStudyReport({ imgData: dataURL, fileName });
              });
            });
          })();
        } else {
          this.$nextTick(() => {
            html2canvas(this.$refs.studyReportTemplateOld).then((canvas) => {
              const dataURL = canvas.toDataURL('image/png');
              const fileName = `${this.studentInfo.name}的阶段学习报告${this.studentInfo.progress.substring(
                0,
                this.studentInfo.progress.indexOf('-'),
              )}-${this.$moment(new Date()).format('YYYYMMDDHHmmss')}.png`;

              this.sendStudyReport({ imgData: dataURL, fileName });
            });
          });
        }
      } else {
        this.studentInfo = {
          sectionType: record.data.levelData.level1 + record.data.levelData.level2,
          name: record.data.studentName,
          rate: record.data.attendanceRate,
          teacher: record.userName,
          tutor: record.data.assistantName,
          subject: record.subject,
          subjectName: record.subject === 'MATH' ? '数学' : '中文',
          chineseType: record.data.levelData.sectionType, // 区分中文主修和专题的背景图
        };
        this.$nextTick(() => {
          html2canvas(this.$refs.certificateWrapper).then((canvas) => {
            const dataURL = canvas.toDataURL('image/png');
            const fileName = `${this.studentProfile.fullName}的阶段结业证书${record.level}-${this.$moment(
              new Date(),
            ).format('YYYY/MM/DD HH:mm:ss')}.png`;
            // this.downloadByBlob(dataURL, fileName);
            this.sendStudyReport({ imgData: dataURL, fileName });
          });
        });
      }
    },
    // 将base64的图片转换为file文件
    convertBase64UrlToBlob(urlData) {
      const bytes = window.atob(urlData.split(',')[1]); // 去掉url的头，并转换为byte
      // 处理异常,将ascii码小于0的转换为大于0
      const ab = new ArrayBuffer(bytes.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < bytes.length; i += 1) {
        ia[i] = bytes.charCodeAt(i);
      }
      return new Blob([ab], { type: 'image/jpeg' });
    },
    // 将blob转换为file
    blobToFile(theBlob, fileName) {
      return new File([theBlob], fileName, { type: 'image/jpeg', lastModified: Date.now() });
    },

    sendStudyReport(formData) {
      const blob = this.convertBase64UrlToBlob(formData.imgData);
      const file = this.blobToFile(blob, 'studyReport');
      getUploadOssToken().then((res) => {
        this.uploadToOss(file, res.data.content, formData);
      });
    },
    uploadToOss(info, fileData, oldFormData) {
      const formData = new FormData();
      const formArray = Object.entries(fileData.formFields);
      formArray.forEach((item) => {
        formData.append(item[0], item[1]);
      });
      formData.append('file', info);
      imgpostUrl(`https://${fileData.endpoint}`, formData).then((res) => {
        const newFormData = oldFormData;
        newFormData.imgData = res.data.url;
        this.sendStudyReportGroup(newFormData);
      });
    },

    // 发送学习报告到群里
    sendStudyReportGroup(data) {
      const params = {
        studentId: this.studentId,
        imgData: data.imgData,
        subject: this.reportSubject,
      };
      sendPosterGroup(params).then(() => {
        this.$message.success('发送成功');
      });
    },
    download(url, name, record) {
      this.studentInfo = null;
      this.showNewReport = record.data.newStudy;
      this.courseSectionId = record.data.courseSectionId;
      if (record.reportType === 'STAGE_LEARNING_REPORT') {
        reportEvent({
          data: {
            Code: this.studentProfile.code,
            subject: record.data.subject,
            CourseSchedule_id: record.data.courseScheduleId,
            report_id: record.uuid,
            _event: 'CAstudio_Learning_CreateReportClick',
            User_id: getUserUUID(),
          },
        });

        this.studentInfo = {
          score: record?.data?.score || 0,
          name: this.studentProfile.fullName,
          progress: record.level,
          teacherName: record.userName,
          createTime: record.whenCreated.substring(0, 10),
          listeningLevel: record?.data?.listeningLevel,
          readingLevel: record?.data?.readingLevel,
          speakingLevel: record?.data?.speakingLevel,
          writingLevel: record?.data?.writingLevel,
          remake: record?.data?.remake,
          subject: record?.data?.subject,
        };
        if (this.showNewReport) {
          this.queryKnowledgeSummery(this.courseSectionId);
        }

        this.visibleReport = true;
      } else {
        this.studentInfo = {
          sectionType: record.data.levelData.level1 + record.data.levelData.level2,
          name: record.data.studentName,
          rate: record.data.attendanceRate,
          teacher: record.userName,
          tutor: record.data.assistantName,
          subject: record.subject,
          subjectName: record.subject === 'MATH' ? '数学' : '中文',
          chineseType: record.data.levelData.sectionType, // 区分中文主修和专题的背景图
          level: record.level, //用于下载名称
        };
        this.visibleCertificate = true;

        // this.$nextTick(() => {
        //   html2canvas(this.$refs.certificateWrapper).then((canvas) => {
        //     const dataURL = canvas.toDataURL('image/png');
        //     const fileName = `${this.studentInfo.name}的阶段结业证书${record.level}-${this.$moment(new Date()).format(
        //       'YYYY/MM/DD HH:mm:ss',
        //     )}.png`;
        //     this.downloadByBlob(dataURL, fileName);
        //   });
        // });
      }
    },
    downloadImage(url, name) {
      const link = document.createElement('a');
      link.href = url;
      link.download = name;
      link.click();
    },
    downloadByBlob(url, name) {
      const image = new Image();
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = url;
      image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, image.width, image.height);
        canvas.toBlob(async (blob) => {
          const data = [
            new ClipboardItem({
              [blob.type]: blob,
            }),
          ];
          await navigator.clipboard.write(data);
          this.$message.success('复制成功');
        });
        // canvas.toBlob((blob) => {
        //   const imgUrl = URL.createObjectURL(blob);
        //   this.downloadImage(imgUrl, name);
        //   URL.revokeObjectURL(imgUrl);
        //   setTimeout(() => {
        //     this.$message.success('下载成功');
        //   }, 1000);
        // });
      };
    },

    initLearningReport(pageNum) {
      this.tableData = null;
      let params = {
        data: { studentId: this.studentId },
        num: pageNum,
      };
      learningReportSearch(params).then((res) => {
        this.tableData = res.data.content.content;
        this.total = res.data.content.totalCount;
      });
    },

    handleChange() {
      this.initLearningReport(this.pageNum);
    },
  },
};
</script>
<style lang="less" scoped>
.reports-search {
  width: 400px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.certificateWrapper {
  position: relative;
  width: 595px;
  height: 842px;
  z-index: 23;

  right: -100000px;
  overflow: hidden;
}
.certificateWrapperCN {
  position: relative;
  width: 842px;
  height: 595px;

  right: -1000000px;
  overflow: hidden;
}
.certificateWrapperTC {
  position: relative;
  width: 595px;
  height: 842px;
  right: 0px;
}
.certificateWrapperCNTC {
  position: relative;
  width: 842px;
  height: 595px;
  right: 0px;
}

.studyReportTemplateTC {
  position: relative;
  z-index: 23;
  right: 0px;
  width: 375px;
  background: transparent;
}
.studyReportTemplate2TC {
  position: relative;
  z-index: 23;
  right: 0px;
  width: 375px;
  background: transparent;
}
.studyReportTemplate {
  position: fixed;
  z-index: 23;
  right: -100000px;
  width: 375px;
  background: transparent;
}
.studyReportTemplate2 {
  position: fixed;
  z-index: 23;
  right: -100000px;
  width: 375px;
  background: transparent;
}
.loading-wrap {
  padding-top: 200px;
  text-align: center;
}
.modal-height {
  height: 508px;
  overflow-y: auto;
}
</style>
