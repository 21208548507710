<template>
  <div class="record" v-if="is_showVoice">
    <a-icon class="close" type="close" @click="onClose" />
    <div class="recorder">
      <img class="voice_pic" src="@/assets/service_voice_pic.png" alt="" />
      <div v-if="isStart" @click="onStartVoice" class="start-voice">
        <img src="@/assets/service_voice_start.png" alt="" />
      </div>
      <div v-else>
        <img v-if="loading" @click="onEndVoice" class="stop-voice" src="@/assets/service_voice_stop.png" alt="" />
        <a-spin v-else />
      </div>
      <p v-if="isStart">{{ isStart ? '点击开始录音' : '点击结束录音' }}</p>
      <p v-else>{{ !loading ? '录音上传中' : '点击结束录音' }}</p>
    </div>
  </div>
</template>
<script>
import { imgpostUrl } from '@/api/register';
import Record from './record-sdk';

export default {
  data() {
    return {
      isStart: true,
      isVoice: false,
      isFinished: false,
      loading: true,
      audio: '',
      is_showVoice: true,
      recorder: new Record(),
    };
  },
  methods: {
    onClose() {
      this.onStopAudio();
      // this.is_showVoice = false;
      this.$parent.cancal();
      // this.$emit('getVoice', null);
    },
    // 开始录音
    onStartVoice() {
      this.onStopAudio();
      this.isFinished = false;
      this.isStart = false;
      this.recorder.startRecord({
        success: () => {
          this.isVoice = true;
        },
        error: (e) => {
          this.isVoice = false;
          this.$message.error(e);
        },
      });
    },

    // 结束录音
    onEndVoice() {
      this.isFinished = false;
      this.loading = false;
      setTimeout(() => {
        this.recorder.stopRecord({
          success: (res) => {
            this.isVoice = false;
            // console.log('音频源文件', res);
            this.upAudio(res);
          },
          error: () => {
            this.isVoice = false;
          },
        });
      }, 1000);
    },
    // 播放录音
    onPlayAudio() {
      this.isVoice = false;
      this.isFinished = true;
      this.audio = document.getElementById('audioVoice');
      this.recorder.play(this.audio);
    },
    // 停止播放录音
    onStopAudio() {
      this.recorder.clear(this.audio);
    },
    upAudio(data) {
      const file = this.blobToFile(data, 'recorder.mp3');
      this.$fetch('/api/admin/file/sts/public').then((res) => {
        this.uploadToOss(file, res.data.content);
      });
    },
    // 将blob转换为file
    blobToFile(theBlob, fileName) {
      return new File([theBlob], fileName, { type: theBlob.type, lastModified: Date.now() });
    },
    uploadToOss(info, fileData) {
      const formData = new FormData();
      const formArray = Object.entries(fileData.formFields);
      formArray.forEach((item) => {
        formData.append(item[0], item[1]);
      });
      formData.append('file', info);
      imgpostUrl(`https://${fileData.endpoint}`, formData)
        .then((res) => {
          this.loading = true;
          this.$emit('getVoice', res.data.url);
          this.onClose();
        })
        .finally(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.record {
  width: 460px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #eee;
  position: absolute;
  bottom: 15%;
  right: 12%;
  .recorder {
    width: 460px;
    padding: 30px 0;
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;
    .voice_pic {
      width: 300px;
      height: 70px;
      margin-bottom: 30px;
    }
    P {
      font-size: 12px;
      line-height: 12px;
      color: #333;
      margin: 10px 0;
    }
  }
  .close {
    float: right;
    margin: 20px 20px 0 0;
  }
  .start-voice {
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    border-radius: 50%;
    background-color: #009cff;
    margin: auto;
    img {
      vertical-align: middle;
    }
  }
  .stop-voice {
    width: 64px;
    height: 64px;
  }
}
</style>
