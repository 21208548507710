<template>
  <div>
    <div v-for="(item, index) in data" :key="index">
      <div class="report-main" v-if="JSON.stringify(dataInfo) !== '{}'">
        <div class="report-title">
          <div class="info-title" :class="{ 'title-ellipsis': is_expand }" :title="item.title">
            {{ item.title }}
          </div>
          <div class="tags" :title="item.tag" v-if="item.tag">
            {{ item.tag }}
          </div>
        </div>
        <p>
          提交时间: <span>{{ dataInfo.whenCreated }}</span>
        </p>
        <p>{{ item.questionName }}</p>
        <p class="expand-title" @click="onExpand(index)">{{ is_expand ? '展开题目详情' : '收起题目详情' }}</p>
        <div v-if="!is_expand">
          <div class="img-type" v-if="item.questionPicture">
            <img :src="item.questionPicture" alt="主观题图片" @click="onPictures([item.questionPicture])" />
          </div>
        </div>
        <div class="student-sumbit">
          <p>学生提交内容</p>
          <div class="img-type" v-if="item.answerPictures">
            <div
              v-for="(i, index) in editImageArray"
              :key="index"
              class="universal-img"
              @mouseover="selectImg(i.newImage)"
              @mouseout="selectEle = ''"
            >
              <img alt="学生主观题图片" :src="i.newImage" @click="onPictures(item.answerPictures)" />
              <img
                v-show="selectEle === i.newImage && dataInfo.homeworkReviews && dataInfo.homeworkReviews.length === 0"
                @click="editImage(i)"
                class="close"
                src="@/assets/edit.png"
                alt=""
              />
            </div>
          </div>
          <div class="teacher-audio" v-if="item.answerAudios">
            <div v-for="i in item.answerAudios" :key="i">
              <audio :src="i" controls="controls" preload></audio>
            </div>
          </div>
          <div class="teacher-video" v-if="item.answerVideos">
            <div v-for="i in item.answerVideos" :key="i">
              <video :src="i" controls="controls"></video>
            </div>
          </div>
          <div class="teacher-file" v-if="item.answerfiles">
            <div v-for="i in item.answerfiles" :key="i" @click="downloadFile(i)">
              <a-icon type="link" />
              <span controls="controls">
                {{ decodeURIComponent(i).substring(decodeURIComponent(i).lastIndexOf('/') + 1) }}
              </span>
            </div>
          </div>
          <div class="teacher-text" v-if="item.text">
            <p><span>文本内容：</span>{{ item.text }}</p>
          </div>
        </div>
        <div class="teacher-comment" v-if="dataInfo.homeworkReviews && dataInfo.homeworkReviews.length !== 0">
          <div v-for="(i, index) in dataInfo.homeworkReviews" :key="index">
            <template v-if="i.questionId === item.questionId">
              <div>
                <label>老师评分：</label>
                <a-rate v-model="i.start" disabled />
              </div>
              <div class="teacher-evaluation">
                <label>老师评语：</label>
                <div>{{ i.evaluation || '暂无评语' }}</div>
              </div>
              <div class="teacher-audio" v-if="i.audioUrl">
                <audio :src="i.audioUrl" controls="controls" preload></audio>
              </div>
              <div class="teacher-image" v-if="i.imageUrl.length !== 0">
                <img v-for="item in i.imageUrl" :key="item" :src="item" alt="" />
              </div>
              <div class="teacher-image" v-if="i.videoUrl.length !== 0">
                <video v-for="item in i.videoUrl" :key="item" :src="item" alt="" controls />
              </div>
              <div v-if="i.fileUrl.length !== 0">
                <span class="teafileName" v-for="item in i.fileUrl" :key="item" alt="" @click="downloadFile(item)">
                  <a-icon type="link" />
                  <span controls="controls">
                    {{ decodeURIComponent(item).substring(decodeURIComponent(item).lastIndexOf('/') + 1) }}
                  </span>
                </span>
              </div>
            </template>
          </div>
        </div>
        <div v-else class="teacher-comment">
          <template v-if="uuid === teacherId">
            <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
              <a-form-item label="老师评分">
                <a-rate
                  v-decorator="[
                    'startValues',
                    { rules: [{ required: true, message: '评分至少一星' }, { validator: comparerate }] },
                  ]"
                />
              </a-form-item>
              <a-form-item label="" :wrapper-col="{ span: 24 }">
                <a-textarea
                  :auto-size="{ minRows: 4 }"
                  v-decorator="[
                    'evaluation',
                    {
                      rules: [
                        { required: isBol, message: '请输入老师评语!' },
                        { max: 300, message: '老师评语至多300字', trigger: 'change' },
                        { validator: compareEvalution },
                      ],
                    },
                  ]"
                  placeholder="点击输入老师评语"
                />
              </a-form-item>
              <div class="filemain">
                <div class="image-main" v-if="imageArray.length !== 0">
                  <div
                    class="universal-img"
                    @mouseover="selectImg(item)"
                    @mouseout="selectEle = ''"
                    v-for="item in imageArray"
                    :key="item"
                  >
                    <img v-if="typeof item === 'string'" :src="item" alt="" />
                    <a-spin v-else />
                    <img
                      v-show="selectEle === item"
                      @click="delFile('imageArray', item)"
                      class="close"
                      src="@/assets/close.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="video-main" v-if="videoArray.length !== 0">
                  <div
                    class="universal-file universal-img"
                    @mouseover="selectImg(item)"
                    @mouseout="selectEle = ''"
                    v-for="item in videoArray"
                    :key="item"
                  >
                    <video v-if="typeof item === 'string'" :src="item" controls="controls"></video>
                    <a-spin v-else />
                    <img
                      v-show="selectEle === item"
                      @click="delFile('videoArray', item)"
                      class="close"
                      src="@/assets/close.png"
                      alt=""
                    />
                    <!-- <a-icon class="del" type="delete" @click="delFile('videoArray', item)" /> -->
                  </div>
                </div>
                <div class="file-main" v-if="fileArray.length !== 0">
                  <div
                    class="universal-file"
                    @mouseover="selectImg(item)"
                    @mouseout="selectEle = ''"
                    v-for="item in fileArray"
                    :key="item"
                  >
                    <p v-if="typeof item === 'string'" class="flieName">
                      <a-icon type="link" @click="downloadFile(item)" /><span @click="downloadFile(item)">{{
                        item.substring(item.lastIndexOf('/') + 1)
                      }}</span>
                      <a-icon
                        v-show="selectEle === item"
                        class="del"
                        type="delete"
                        @click="delFile('fileArray', item)"
                      />
                    </p>
                    <a-spin v-else />
                  </div>
                </div>
              </div>
              <a-form-item :wrapper-col="{ span: 24 }">
                <div class="audio-main">
                  <div class="audiocls">
                    <a-icon type="audio" @click="is_showVoice = !is_showVoice" />
                  </div>
                  <div class="audiocls" v-if="data[0].questionType === 'GENERAL_SUBJECTIVE'">
                    <a-upload
                      name="imgaes"
                      list-type="picture"
                      :show-upload-list="false"
                      @change="previewImage"
                      :before-upload="beforeUploadImages"
                    >
                      <div>
                        <a-icon type="file-image" />
                      </div>
                    </a-upload>
                  </div>
                  <div class="audiocls" v-if="data[0].questionType === 'GENERAL_SUBJECTIVE'">
                    <a-upload
                      action="https://tch-studio-dev.wukongacademy.com/api/admin/file/sts/public"
                      list-type="picture"
                      :showUploadList="true"
                      :preview-file="previewVideo"
                      :before-upload="beforeUploadVideo"
                    >
                      <div>
                        <a-icon type="video-camera" />
                      </div>
                    </a-upload>
                  </div>
                  <div class="audiocls" v-if="data[0].questionType === 'GENERAL_SUBJECTIVE'">
                    <a-upload
                      action="https://tch-studio-dev.wukongacademy.com/api/admin/file/sts/public"
                      list-type="picture"
                      :showUploadList="true"
                      :preview-file="previewFile"
                      :before-upload="beforeUploadFile"
                    >
                      <div>
                        <a-icon type="folder-open" />
                      </div>
                    </a-upload>
                  </div>
                  <audio v-if="voiceUrl" :src="voiceUrl" controls autoplay></audio>
                  <img v-if="voiceUrl" @click="onDelVoice" src="@/assets/service_msg_icon_close.png" alt="" />
                </div>
                <a-button class="submitCls" type="primary" html-type="submit" :disabled="is_submit">
                  完成批阅
                </a-button>
              </a-form-item>
            </a-form>
            <Voice v-if="is_showVoice" @getVoice="getVoice" />
          </template>
        </div>
        <a-modal :dialog-style="{ top: '20%' }" width="600px" :visible="isCarousel" :footer="null" @cancel="onClose">
          <div class="carousel-main">
            <a-carousel arrows>
              <div slot="prevArrow" class="custom-slick-arrow" style="left: 10px; z-index: 1">
                <a-icon type="left" />
              </div>
              <div slot="nextArrow" class="custom-slick-arrow" style="right: 10px">
                <a-icon type="right" />
              </div>
              <div class="imgcls" v-for="(item, index) in editImageArray" :key="index">
                <img :src="item.newImage" alt="" />
              </div>
            </a-carousel>
          </div>
        </a-modal>
        <div v-if="!data" class="empty">
          <p>学生还未提交作业</p>
          <img src="@/assets/icons/pic_emptypage.svg" alt="" />
        </div>
        <component
          :is="currentView"
          :imgUrl="imgUrl"
          @onClose="cancal"
          @onSaveEditImage="onSaveEditImage"
          :loading="loading"
        ></component>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import storage from 'store';
import Voice from '@/components/WKrecorder/voice.vue';
import imagesKonva from '@/components/EditImage/index.vue';
import { UUID } from '@/store/mutation-types';
import { imgpostUrl } from '@/api/register';

export default {
  components: { Voice, imagesKonva },
  props: {
    dataInfo: {
      type: Object,
      default: () => {},
    },
    teacherId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      uuid: storage.get(UUID),
      moment,
      isBol: true,
      data: this.dataInfo?.reportData?.answerRecord || [],
      form: this.$form.createForm(this, { name: 'coordinated' }),
      startValues: 0,
      voiceUrl: null,
      is_submit: false,
      is_expand: true,
      is_showVoice: false,
      reportIndex: 0,
      isCarousel: false,
      pictures: [],
      isUp: false,
      imageUrl: null,
      imageArray: [],
      videoUrl: null,
      videoArray: [],
      fileUrl: null,
      fileArray: [],
      fileName: '',
      isUpLoad: false,
      isSize: false,
      currentView: null,
      imgUrl: '',
      selectEle: '',
      editImageArray: [],
      loading: false,
      newPics: [],
    };
  },
  mounted() {
    let editImg = [];
    if (this.dataInfo.homeworkReviews && this.dataInfo.homeworkReviews.length !== 0) {
      editImg = this.dataInfo.homeworkReviews[0].data.editImage;
    } else {
      editImg = this.dataInfo?.reportData?.answerRecord[0].answerPictures;
    }
    editImg.forEach((item) => {
      this.editImageArray.push({
        oldImage: item,
        newImage: item,
      });
    });
  },
  methods: {
    onSaveEditImage(data) {
      const blob = this.convertBase64UrlToBlob(data.newImage);
      const file = this.blobToFile(blob, 'file.jpeg');
      this.loading = true;
      this.$fetch('/api/admin/file/sts/public').then((res) => {
        this.uploadToOss(file, res.data.content, data);
      });
    },
    uploadToOss(info, fileData, data) {
      const formData = new FormData();
      const formArray = Object.entries(fileData.formFields);
      formArray.forEach((item) => {
        formData.append(item[0], item[1]);
      });
      formData.append('file', info);
      imgpostUrl(`https://${fileData.endpoint}`, formData)
        .then((res) => {
          this.loading = false;
          this.currentView = null;
          this.editImageArray = this.editImageArray.map((item) => {
            let newImage = '';
            if (item.oldImage === data.oldImage) {
              this.newPics.push(res.data.url);
              newImage = res.data.url;
            } else {
              newImage = item.newImage;
            }
            return {
              ...item,
              newImage,
            };
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 将base64的图片转换为file文件
    convertBase64UrlToBlob(urlData) {
      const bytes = window.atob(urlData.split(',')[1]); // 去掉url的头，并转换为byte
      // 处理异常,将ascii码小于0的转换为大于0
      const ab = new ArrayBuffer(bytes.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < bytes.length; i += 1) {
        ia[i] = bytes.charCodeAt(i);
      }
      return new Blob([ab], { type: 'image/jpeg' });
    },
    // 将blob转换为file
    blobToFile(theBlob, fileName) {
      return new File([theBlob], fileName, { type: 'image/jpeg', lastModified: Date.now() });
    },
    editImage(data) {
      this.currentView = 'imagesKonva';
      this.imgUrl = data;
    },
    selectImg(item) {
      this.selectEle = item;
    },
    downloadFile(fileUrl) {
      const alink = document.createElement('a');
      alink.download = this.fileName;
      alink.href = fileUrl;
      alink.click();
      // document.body.removeChild(alink);
    },
    delFile(type, data) {
      this[type] = this[type].filter((item) => item !== data);
    },
    beforeUploadImages(file) {
      this.isUpLoad = false;
      this.isSize = false;
      const isapplication = file.type.includes('image');
      this.isUpLoad = isapplication;
      if (this.imageArray.length > 4) {
        this.$message.error('图片数量最多五张');
      }
      if (!isapplication) {
        this.$message.error('目前支持jpg、png、jpeg格式文件');
      }
      const isLt50M = file.size / 1024 / 1024 < 10;
      this.isSize = isLt50M;
      if (!isLt50M) {
        this.$message.error('文件大小最大10M!');
      }
      return isapplication && isLt50M && this.imageArray.length <= 4;
    },
    beforeUploadVideo(file) {
      this.isUpLoad = false;
      this.isSize = false;
      const isapplication = file.type.includes('video');
      this.isUpLoad = isapplication;
      if (this.videoArray.length > 1) {
        this.$message.error('文件数量最多两个');
      }
      // if (!isapplication) {
      //   this.$message.error('目前支持PDF格式文件');
      // }
      const isLt50M = file.size / 1024 / 1024 < 50;
      this.isSize = isLt50M;
      if (!isLt50M) {
        this.$message.error('文件大小最大50M!');
      }
      return isapplication && isLt50M && this.videoArray.length <= 1;
    },
    beforeUploadFile(file) {
      this.isUpLoad = false;
      this.isSize = false;
      const isapplication = file.type.includes('application/pdf');
      this.isUpLoad = isapplication;
      if (this.fileArray.length > 1) {
        this.$message.error('文件数量最多两个');
      }
      if (!isapplication) {
        this.$message.error('目前支持PDF格式文件');
      }
      const isLt50M = file.size / 1024 / 1024 < 50;
      this.isSize = isLt50M;
      if (!isLt50M) {
        this.$message.error('文件大小最大50M!');
      }
      return isapplication && isLt50M && this.fileArray.length <= 1;
    },
    previewImage(info) {
      if (this.isUp) return;
      this.isUp = true;
      this.$fetch('/api/admin/file/sts/public').then((res) => {
        if (this.isUpLoad && this.isSize && this.imageArray.length <= 4) {
          const len = JSON.parse(JSON.stringify(this.imageArray.length));
          this.imageArray.push(this.imageArray.length);
          this.uploadToOssOne(info, res.data.content, 'img', len);
        }
      });
    },
    previewVideo(file) {
      const o = {
        file: {
          originFileObj: file,
        },
      };
      return this.$fetch('/api/admin/file/sts/public').then((res) => {
        if (this.isUpLoad && this.isSize && this.videoArray.length <= 1) {
          const len = JSON.parse(JSON.stringify(this.videoArray.length));
          this.videoArray.push(this.videoArray.length);
          this.uploadToOssOne(o, res.data.content, 'video', len);
        }
      });
    },
    previewFile(file) {
      const o = {
        file: {
          originFileObj: file,
        },
      };
      return this.$fetch('/api/admin/file/sts/public').then((res) => {
        if (this.isUpLoad && this.isSize && this.fileArray.length <= 1) {
          this.fileName = '';
          this.fileName = file.name;
          const len = JSON.parse(JSON.stringify(this.fileArray.length));
          this.fileArray.push(this.fileArray.length);
          this.uploadToOssOne(o, res.data.content, 'file', len);
        }
      });
    },
    uploadToOssOne(info, fileData, type, index) {
      const formData = new FormData();
      const formArray = Object.entries(fileData.formFields);
      formArray.forEach((item) => {
        formData.append(item[0], item[1]);
      });
      formData.append('file', info.file.originFileObj);
      imgpostUrl(`https://${fileData.endpoint}`, formData)
        .then((res) => {
          this.isUp = false;
          if (type === 'file') {
            this.fileUrl = res.data.url;
            // this.fileArray.push(res.data.url);
            this.fileArray.splice(index, 1, res.data.url);
          } else if (type === 'img') {
            this.imageUrl = res.data.url;
            this.imageArray.splice(index, 1, res.data.url);
          } else if (type === 'video') {
            this.videoUrl = res.data.url;
            this.videoArray.splice(index, 1, res.data.url);
            // this.videoArray.push(res.data.url);
          }
        })
        .catch(() => {
          this.isUp = false;
          if (type === 'file') {
            this.fileArray.splice(index, 1);
          } else if (type === 'img') {
            this.imageArray.splice(index, 1);
          } else if (type === 'video') {
            this.videoArray.splice(index, 1);
          }
        });
    },
    onPictures(datas) {
      this.pictures = [];
      this.isCarousel = true;
      this.pictures = datas;
    },
    onClose() {
      this.isCarousel = false;
    },
    onDelVoice() {
      this.isBol = true;
      this.$nextTick(() => {
        this.form.validateFields(['evaluation'], { force: true });
      });
      this.voiceUrl = null;
    },
    comparerate(rule, value, callback) {
      if (value < 1) {
        callback('评分至少一星');
      } else {
        callback();
      }
    },
    compareEvalution(rule, value, callback) {
      if (!value) {
        callback();
      }
      const program = value.replace(/(^\s*)|(\s*$)/g, '');
      if (!program) {
        callback('老师评语不能全为空格');
      } else {
        callback();
      }
    },
    cancal() {
      this.is_showVoice = false;
      this.currentView = null;
    },
    getVoice(values) {
      this.isBol = false;
      this.$nextTick(() => {
        this.form.validateFields(['evaluation'], { force: true });
      });
      this.voiceUrl = values;
    },
    onExpand(index) {
      this.reportIndex = index;
      this.is_expand = !this.is_expand;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.is_submit = true;
      const num = this.reportIndex;
      const editimg = [];
      this.editImageArray.forEach((item) => {
        editimg.push(item.newImage);
      });
      this.form.validateFields((err, values) => {
        if (!err) {
          const dataObj = {
            reportId: this.dataInfo.uuid,
            questionNumber: this.data[num].questionNumber,
            questionId: this.data[num].questionId,
            audioUrl: this.voiceUrl,
            start: values.startValues,
            evaluation: values.evaluation,
            fileUrl: this.fileArray,
            imageUrl: this.imageArray.concat(this.newPics),
            videoUrl: this.videoArray,
            data: {
              editImage: editimg,
            },
          };
          this.$post(`/api/homework/practice/review_report`, dataObj)
            .then((res) => {
              if (res.status === 200) {
                this.is_submit = false;
                this.$emit('initReport');
              }
            })
            .finally(() => {
              this.is_submit = false;
            });
        } else {
          this.is_submit = false;
        }
      });
    },
  },
};
</script>
<style lang="less">
.report-main {
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #eee;
  margin-bottom: 20px;
  p {
    font-size: 16px;
    color: #999;
    margin-bottom: 10px;
    span {
      color: #333;
      margin-right: 40px;
    }
  }
  .report-name {
    color: #333;
    font-size: 18px;
    font-weight: 600;
  }
  .report-title {
    display: flex;
    justify-content: space-between;
    color: #333;
    font-size: 18px;
    font-weight: 600;
    .tags {
      width: 20%;
      height: 26px;
      font-weight: normal;
      font-size: 14px;
      text-align: center;
      border: 1px solid rgba(151, 151, 151, 0.3);
      border-radius: 6px;
      padding: 2px 5px;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .info-title {
      display: inline-block;
      width: 80%;
    }
    .title-ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .expand-title {
    font-size: 14px;
    color: #009cff;
    &:hover {
      cursor: pointer;
    }
  }
  .img-type {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0 0 20px 0;
    img {
      width: 160px;
      height: 90px;
      margin: 5px 10px 0 0;
    }
    .close {
      width: 14px;
      height: 14px;
    }
  }
  .student-sumbit {
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
    p {
      font-size: 18px;
      font-weight: 600;
      color: #333;
      line-height: 24px;
      margin: 15px 0;
    }
  }
  .teacher-comment {
    padding-top: 20px;
    label {
      font-size: 18px;
      font-weight: 600;
      color: #333;
      line-height: 40px;
    }
    textarea {
      border-radius: 6px;
    }
    .ant-form-item-children {
      display: flex;
      justify-content: space-between;
    }
  }
  .teacher-evaluation {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    margin: 20px 0;
    label {
      line-height: 20px;
    }
    div {
      width: 80%;
      font-size: 16px;
      color: #333;
    }
  }
  .teacher-audio {
    audio {
      height: 22px;
    }
  }
  .teafileName {
    color: #009cff;
    &:hover {
      cursor: pointer;
    }
  }
  .teacher-video {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 20px;
    video {
      width: 160px;
      height: 90px;
      margin: 5px;
    }
  }
  .teacher-file {
    margin-top: 30px;
  }
  .teacher-text {
    p {
      span {
        color: #666;
        margin-right: 0;
      }
      margin: 0;
      font-size: 16px;
      line-height: 22px;
      font-weight: normal;
    }
  }
  .audio-main {
    // height: 22px;
    display: flex;
    justify-content: flex-start;
    // align-content: center;
    align-items: center;
    i {
      font-size: 22px;
    }
    audio {
      height: 22px;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
  .ant-btn-primary {
    color: #fff;
    background-color: #009cff;
  }
  .empty {
    font-size: 16px;
    color: #999;
    padding-top: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .audiocls {
    width: 40px;
    height: 40px;
    border: 1px solid #eee;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
  }
  .submitCls {
    border-radius: 10px;
  }
  .ant-upload-list-picture {
    display: none;
  }
  .filemain {
    .universal-file {
      display: flex;
      align-items: center;
      p {
        margin: 5px 0;
      }
    }
    .image-main {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    .img-main {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    .flieName {
      font-size: 16px;
      color: #333;
      border: 1px solid #eee;
      border-radius: 6px;
      padding: 5px 10px;
      i {
        color: #009cff;
        font-size: 16px;
      }
      span {
        margin-right: 0;
      }
    }
    img,
    video {
      width: 160px;
      height: 90px;
      margin: 5px;
    }
  }
  .universal-img {
    position: relative;
    width: 175px;
    height: 105px;
    img {
      width: 160px;
      height: 90px;
      margin: 5px;
    }
    .close {
      width: 14px;
      height: 14px;
      position: absolute;
      top: -4px;
      right: 0px;
    }
  }
  .teacher-image {
    img,
    video {
      width: 160px;
      height: 90px;
      margin: 5px;
    }
  }
}
.carousel-main {
  // display: none;
  width: 550px;
  height: 340px;
  background: #fff;
  // box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  // position: absolute;
  // z-index: 200;
  // top: 50%;
  // right: 10%;
  // transform: translate(-50%, -50%);
  .imgcls {
    width: 550px;
    height: 340px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }
  }
  .ant-carousel {
    .slick-slide {
      text-align: center;
      height: 340px;
      // line-height: 160px;
      // background: #364d79;
      // overflow: hidden;
    }
    .custom-slick-arrow {
      width: 25px;
      height: 25px;
      font-size: 25px;
      color: #000;
      background-color: rgba(31, 45, 61, 0.11);
      opacity: 0.3;
    }
    .custom-slick-arrow:before {
      display: none;
    }
    .custom-slick-arrow:hover {
      opacity: 0.5;
    }
    // .slick-slide h3 {
    //   color: #fff;
    // }
  }
}
</style>
