<template>
  <div>
    <!-- 课程 -->
    <a-radio-group :value="currentCourse" @change="handleCourseChange">
      <a-radio-button v-for="item in courseOptions" :key="item.value" :value="item.value">{{
        item.label
      }}</a-radio-button>
    </a-radio-group>
    <!-- 详细字段 -->
    <div class="mt-16">
      <a-descriptions v-for="item in info" :key="item.title" :title="item.title">
        <a-descriptions-item v-for="desc in item.descriptions" :key="desc.label" :label="desc.label">{{
          desc.value
        }}</a-descriptions-item>
      </a-descriptions>
    </div>
  </div>
</template>
<script>
import { getStudentTags } from '@/api/students';
import { formatValue } from '@/utils/util';

const courseOptions = [
  {
    label: '中文',
    value: 'CHINESE',
  },
  {
    label: '数学',
    value: 'MATH',
  },
  {
    label: '英文',
    value: 'ENGLISH',
  },
];
const defaultCurrentCourse = 'CHINESE';

export default {
  props: {
    uuid: {
      type: String,
    },
  },
  data: () => ({
    courseOptions,
    currentCourse: defaultCurrentCourse,
    studentDataSource: [],
    formatValue,
  }),
  computed: {
    info() {
      return [
        {
          title: '核心指数',
          descriptions: [
            {
              label: '学习稳定得分',
              value: formatValue(this.studentDataSource?.studyStabilityScore),
            },
            {
              label: '学员黏性得分',
              value: formatValue(this.studentDataSource?.studentRetentionScore),
            },
            {
              label: '续费机会得分',
              value: formatValue(this.studentDataSource?.renewalOpportunityScore),
            },
            {
              label: '推荐机会得分',
              value: formatValue(this.studentDataSource?.recommendationOpportunityScore),
            },
            {
              label: '流失风险得分',
              value: formatValue(this.studentDataSource?.churnRiskScore),
            },
          ],
        },
        {
          title: '学习表现',
          descriptions: [
            {
              label: '课堂出勤率[近4周]',
              value: formatValue(this.studentDataSource?.classAttendanceRateLast4Weeks, '%'),
            },
            {
              label: '作业提交率[近4周]',
              value: formatValue(this.studentDataSource?.homeworkSubmissionRateLast4Weeks, '%'),
            },
            {
              label: '作业正确率[近4周]',
              value: formatValue(this.studentDataSource?.homeworkCorrectnessRateLast4Weeks, '%'),
            },
            {
              label: '学龄[M]',
              value: formatValue(this.studentDataSource?.studentAge),
            },
          ],
        },
        {
          title: '课消表现',
          descriptions: [
            {
              label: '平均课消[近12周]',
              value: formatValue(this.studentDataSource?.avgCourseConsumptionLast12Weeks),
            },
            {
              label: '平均课消[近8周]',
              value: formatValue(this.studentDataSource?.avgCourseConsumptionLast8Weeks),
            },
            {
              label: '平均课消[近4周]',
              value: formatValue(this.studentDataSource?.avgCourseConsumptionLast4Weeks),
            },
            {
              label: '上上周完课',
              value: formatValue(this.studentDataSource?.completedCourses2WeeksAgo),
            },
            {
              label: '上周完课',
              value: formatValue(this.studentDataSource?.completedCoursesLastWeek),
            },
            {
              label: '本周已上已约',
              value: formatValue(this.studentDataSource?.scheduledCoursesThisWeek),
            },
            {
              label: '下周已约',
              value: formatValue(this.studentDataSource?.scheduledCoursesNextWeek),
            },
            {
              label: '剩余课时可学时长[W]',
              value: formatValue(this.studentDataSource?.remainingCourseHours),
            },
            {
              label: '是否上辅修[近4周]',
              value: formatValue(this.studentDataSource?.tookAuxiliaryCourseLast4Weeks),
            },
            {
              label: '是否沉睡[近12周]',
              value: formatValue(this.studentDataSource?.inSleepModeLast12Weeks),
            },
            {
              label: '上次完课距今天数',
              value: formatValue(this.studentDataSource?.daysSinceLastCompletedCourse),
            },
            {
              label: '课消特征池',
              value: formatValue(this.studentDataSource?.courseConsumptionFeaturePool),
            },
          ],
        },
        {
          title: '分享表现',
          descriptions: [
            {
              label: '分享特征池',
              value: formatValue(this.studentDataSource?.shareFeaturePool),
            },
            {
              label: '进线次数[近4周]',
              value: formatValue(this.studentDataSource?.leadsLast4Weeks),
            },
            {
              label: '分享次数[近4周]',
              value: formatValue(this.studentDataSource?.sharesLast4Weeks),
            },
          ],
        },
        {
          title: '忠诚度',
          descriptions: [
            {
              label: '复购次数',
              value: formatValue(this.studentDataSource?.repurchaseCount),
            },
            {
              label: '最近一次复购时间',
              value: formatValue(this.studentDataSource?.lastRepurchaseTime),
            },
            {
              label: '续费特征池',
              value: formatValue(this.studentDataSource?.renewalFeaturePool),
            },
            {
              label: '是否多娃',
              value: formatValue(this.studentDataSource?.hasMultipleChildren),
            },
            {
              label: '是否拓科',
              value: formatValue(this.studentDataSource?.hasTakenOtherSubjects),
            },
            {
              label: '当前老师搭配时长[M]',
              value: formatValue(this.studentDataSource?.currentTeacherDurationMonths),
            },
            {
              label: '当前班主任搭配时长[M]',
              value: formatValue(this.studentDataSource?.currentHeadTeacherDurationMonths),
            },
            {
              label: 'APP访问次数[近4周]',
              value: formatValue(this.studentDataSource?.appVisitsLast4Weeks),
            },
            {
              label: '学生来源[一级渠道]',
              value: formatValue(this.studentDataSource?.primaryChannelSource),
            },
            {
              label: '学生来源[二级渠道]',
              value: formatValue(this.studentDataSource?.secondaryChannelSource),
            },
            {
              label: '付款方式[新签]',
              value: formatValue(this.studentDataSource?.paymentMethod),
            },
          ],
        },
      ];
    },
  },
  mounted() {
    this.fetchStudentlayerData();
  },
  methods: {
    // 课程切换
    handleCourseChange(e) {
      this.currentCourse = e.target.value;
      this.fetchStudentlayerData();
    },
    // 获取分层数据
    async fetchStudentlayerData() {
      const { data } = await getStudentTags({ uuid: this.uuid, subject: this.currentCourse });
      this.studentDataSource = data?.content;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-descriptions-item-label {
  color: rgba(31, 45, 61, 0.4);
}
/deep/ .ant-descriptions-item-content {
  color: #1f2d3d;
}
/deep/ .ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.6);
}
</style>
